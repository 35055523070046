import React from 'react'
import HeaderBase from '../../organisms/HeaderBase'
import HeaderSecondary from '../../organisms/HeaderSecondary'
import { footerLinks, socialLinks, contactUs, USER_TYPES } from './../../constant';
import Footer from './../../molecules/Footer';
import { useVerifyDocumentsModalContext } from '../context/VerifiyDocumentsModalContext';
import VerifiedMarquee from 'molecules/VerifiedMarquee'
import Link from 'next/link';
import { fetchPendingPaymentReminder } from 'services/medicine.service';
import { useState, useEffect, useRef } from 'react';
import IconClose from 'atoms/SvgIcons/IconClose.svg';
import { getAuth } from './../../services/identity.service';
import NonLoggedinMessage from 'atoms/NonLoggedinMessage';
import { useRouter } from 'next/router';
import { redirectionAfterLogin } from 'core/common/redirectionAfterLogin';
import { useAddressContext } from '@/components/context/addressContext';
import useWindowSize from 'customHook/useWindowSize';
import SearchContactLoginMob from '../HomePage/SearchContactLoginMob';
import HeaderRibbon from '../HomePage/HeaderRibbon';


const LayoutEcomm = ({ children, categoryList, isMobileInnerPage, contentClassName, isWithSticky = true, appointmentBenefitsList , showPincodeModal, isShowSearchAndContact=false, showHeaderRibbon = false}) => {
    const fetchCategory =categoryList && categoryList?.categories? categoryList?.categories.find(category => category.id === 1):[];
    const classicalCategoryList = categoryList && fetchCategory?.children?fetchCategory?.children.find(classicalCategory => classicalCategory.id === 2):[];
    const patentedCategoryList = categoryList && fetchCategory?.children?fetchCategory?.children.find(patentedCategory => patentedCategory.id === 3):[];
  
    const { massege } = useVerifyDocumentsModalContext();
    const [paymentReminder, setPaymentReminder] = useState(null);
    const auth = getAuth();
    const router = useRouter();
    const isMedicineDetail = router?.pathname == '/medicine/[...slug]' || router?.pathname == '/feedback-form/thankyou'
    const { pincodeValue, setUserPatientId } = useAddressContext()
    const [width] = useWindowSize();
    const isPractitonerHomepage = router?.pathname == '/medicines' || router?.pathname == '/practitioner' || ((auth?.user?.userType == 2 || auth?.user?.userType == 4 || auth?.user?.userType == 6) && router?.pathname == '/');

    useEffect(()=>{
        setUserPatientId("")
    },[])

    useEffect(() => {
        if (auth?.user?.id) {
            fetchPendingPaymentReminder(auth.user.id)
                .then((response) => {
                    if (response.status) {
                        const isReminderClosed = localStorage.getItem('paymentReminderClosed');
                        const currentTime = new Date().getTime();
                        const thirtyMinutes = 30 * 60 * 1000;
                        if (!isReminderClosed || currentTime - isReminderClosed > thirtyMinutes) {
                            setPaymentReminder(response.entity.message);
                        }
                    }
                })
                .catch((error) => {
                    console.error('Error fetching payment reminder:', error);
                });
        }
    }, []);

    const handleAlertClose = () => {
        localStorage.setItem('paymentReminderClosed', new Date().getTime());
        setPaymentReminder(null);
    };

    const handleVaidyaClick = (type) => {
        if (!auth) {
            router.push('/login-practitioner')
        }
        else if (updateIsVerified == 1) {
            if (type == 'vaidya_tool') {
                window.location.href = process.env.NEXT_PUBLIC_VEDIC_TOOL_LINK
            }
        } else {
            redirectPopup()
        }
    }

    const categoryFilters = [
        ...(!auth ? [
            {
                label: 'Buy Medicines',
                dataAutomation: 'header-buy-medicines',
                redirection: '/medicines',
                onClick: event => {
                    redirectionAfterLogin({ event, auth, router, redirectUrlLoggedIn: '/medicines', redirectUrlLoggedOut: '/login-practitioner' })
                }
            }
        ] : []),
        {
            label: "Brands",
            subMenuList: categoryList?.brand || [],
            multipleSubMenu: true,
            dataAutomation: 'header-brand',
            moEngageEventName: 'click_brand_dropdown'
        },
        {
            label: "Classical Medicines",
            subMenuList: classicalCategoryList?.children || [],
            multipleSubMenu: true,
            dataAutomation: 'header-classical-medicine',
            moEngageEventName: 'click_classical_dropdown'
        },
        {
            label: "Patented Medicine",
            subMenuList: patentedCategoryList?.children || [],
            multipleSubMenu: true,
            dataAutomation: 'header-patented-medicine',
            moEngageEventName: 'click_patented_dropdown',
        },
        {
            label: 'All Medicines',
            dataAutomation: 'header-all-medicines',
            redirection: '/medicines/allMedicines'
        },
    ]

    return (
        <>
            <div className=''>
                <HeaderBase type='ecomm' className={`${isMobileInnerPage ? 'hidden ' : ''} md:flex sticky top-0 left-0 w-full`} isMobileInnerPage={showPincodeModal} />
                {(auth && auth?.user?.userType !== 1) && width < 768 && isShowSearchAndContact && <SearchContactLoginMob />}
                <HeaderSecondary className={`${isMobileInnerPage ? 'hidden  ' : ''} ${isWithSticky ? 'lg:sticky  w-full lg:top-19 lg:left-0 !z-1 md:!z-30' : ''}`} categoryFilters={categoryFilters} appointmentBenefitsList={appointmentBenefitsList} />
                {paymentReminder && (
                    <Link href={{ pathname: '/myorders', query: { orderStatusToDisplayList: 'Payment Pending' } }}>
                        <div className='bg-semantic-error_dark  cursor-pointer font-semibold flex w-full pl-2.5 pr-2 z-15 justify-between  top-[70px] lg:left-0  max-h-16 text-gray-800' >

                            {paymentReminder}
                            <IconClose
                                className="w-5 fill-none stroke-gray-900"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleAlertClose();
                                }}
                            />
                        </div>
                    </Link>
                )}

                { [USER_TYPES?.STUDENTS, USER_TYPES?.DOCTORS, USER_TYPES?.DOCTOR_DISTRIBUTOR].includes(auth?.user?.userType) &&  showHeaderRibbon && <HeaderRibbon/>}

                {massege && <VerifiedMarquee massege={massege} />}
                <NonLoggedinMessage className={`hidden md:block`} />
                <div className={`${contentClassName ? contentClassName : ""} ${isMedicineDetail ? "pb-0" : "pb-2"}`}>
                {children}
                </div>
                <Footer footerLinks={footerLinks} socialLinks={socialLinks} isMobileInnerPage={isMobileInnerPage}
                    address={contactUs.address} mobileNo={contactUs.mobileNo} email={contactUs.email}
                />
            </div>
        </>
    )
}

export default LayoutEcomm