import React from 'react'
import Heading from 'atoms/Heading'
import Text from 'atoms/Text'
import Container from 'atoms/Container'
import LinkAnchor from 'atoms/LinkAnchor'
import Button from 'atoms/Button'

const PractitionerGetStarted = (props) => {
    const { className = '', heading, link, dataAutomation, description, ...rest } = props

    return (
        <div className={`${className} bg-gradient-to-r from-other-purple-600 to-other-purple-700 w-full py-6 md:py-7`} {...rest}>
            <Container type='static' className={`px-4 w-full max-w-[1120px] mx-auto`}>
                <Heading className="font-Montserrat text-lg md:text-lg.5 text-center font-bold" textColor='text-basic-white'>{heading}</Heading>
                <Text className='md:w-78per mx-auto text-14-20 mt-2 mb-5 text-basic-white text-center font-sans'>
                    {description}
                </Text>
                <LinkAnchor href={link} dataAutomation='get-started-homepage' className='block'>
                    <Button size='extraSmall_v4' variant="Ghost" dataAutomation="get-started-button" className={`w-[168px] border-[1px] h-9 mx-auto bg-basic-white text-primary1-500 text-sm font-Inter font-semibold rounded-full`}>Get Started</Button>
                </LinkAnchor>
            </Container>
        </div>
    )
}
export default PractitionerGetStarted
