import React from 'react'
import IconChevronDown from 'atoms/SvgIcons/IconChevronDown.svg';
import IconChevronDownThin from 'atoms/SvgIcons/IconChevronDownThin.svg';
import IconLocation from 'atoms/SvgIcons/IconLocation.svg';
import Text from 'atoms/Text'
import { getAuth } from './../services/identity.service';
import IconLocationRoundFilled from 'atoms/SvgIcons/IconLocationRoundFilled.svg';

const PincodeChange = (props) => {
    const { uiFor, onOpen, currentPincode, isVerifiedUser } = props
    const auth = getAuth();
    return (
        uiFor == 'mobile'
            ? (
                <div className={`${isVerifiedUser ? 'flex lg:hidden' : 'hidden'} items-center md:px-5 py-3 bg-basic-white`}>
                    <Text className='pr-1 text-xs' type='bodySmall' fontWeight={400} color='text-gray-500'>Delivery Location</Text>
                    <Text type='bodySmall' className='text-xs md:text-sm' fontWeight={700} color='text-gray-500'>{currentPincode?.toString()?.split(" ")?.pop()}</Text>
                    <button className='flex items-center ml-1 pr-1' onClick={onOpen} data-automation='pincode-change'>
                        <Text className='text-xs font-Open-Sans pl-1' color='text-primary1-500'>CHANGE</Text>
                    </button>
                </div>
            )
            : (
                <button data-automation='pincode-change' className={`${isVerifiedUser ? 'hidden lg:flex' : 'hidden'} cursor-auto items-center md:ml-15 bg-basic-white`}>
                    <div className=''>
                        <Text className='text-12-18 text-left' fontWeight={400} color='text-gray-500'>Delivery Location</Text>
                        <div className='flex items-center justify-between'>
                            <Text className='text-14-20 pr-1' fontWeight={600} color='text-gray-600'>{currentPincode?.toString()?.split(" ")?.pop()}</Text>
                            <Text onClick={onOpen} className='text-xs font-Open-Sans' color='text-primary1-500'>CHANGE</Text>
                        </div>

                    </div>
                </button>
            )
    )
}

export default PincodeChange