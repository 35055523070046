import React from 'react'

import ImageNext from 'atoms/ImageNext'
import Text from 'atoms/Text'
import { CLR } from 'styles/theme'

const THEME = {
    v1: {
        parentDivEven: '',
        textHover: '',
    },
    v2: {
        parentDivEven: ' group-hover:bg-primary1-500 !group-hover:text-basic-white',
        textHover: 'group-hover:text-basic-white',
    }
}
const PartnersCard = ({ className, name, userType, avatar, description, theme = 'v1', isEven, experience, qualification }) => {
    return (
        <div className='h-full pt-11.5 md:pt-15 group'>
            <div className={`${className} ${THEME[theme]?.parentDivEven || ''} bg-gray-100 transition-all h-full flex flex-col relative rounded-lg`}>

                <div className={`${THEME[theme]?.parentDivEven || ''}bg-gray-100 transition-all flex p-3  min-w-[80px]  rounded-full overflow-hidden mx-auto absolute -top-[45px] md:-top-[60px] left-1/2 -translate-x-1/2`}>
                    <ImageNext className='rounded-full' src={avatar} alt={name} width={80} height={80} defaultImageFor='avatar' />
                </div>

                <Text tag='p' className={`${THEME[theme]?.textHover || ''} ${userType ? 'grow' : ''} transition-all text-12-16 text-center pt-10 md:pt-12 mb-2`} fontWeight={400} color={CLR.gray900.text} >
                    {description}
                </Text>

                <Text tag='h4' type='body' className={`${THEME[theme]?.textHover || ''} transition-all text-12-16 leading-6 text-center mb-0.5 line-clamp-1`} fontWeight={600} color={CLR.gray900.text} >
                    {name}
                </Text>

                {qualification && <Text tag='p' type='body' className={`${THEME[theme]?.textHover || ''} transition-all text-12-16 text-center mb-0.5 line-clamp-1`} color={CLR.gray900.text} >
                    {qualification}
                </Text>}

                {experience && <Text tag='p' type='body' className={`${THEME[theme]?.textHover || ''} transition-all text-12-16 text-center mb-0.5 line-clamp-1`} color={CLR.gray900.text} >
                    {experience}
                </Text>}

                <Text className={`${THEME[theme]?.textHover || ''} transition-all text-12-16 leading-6.5 text-center line-clamp-1`} fontWeight={400} color={CLR.gray600.text} >
                    {userType}
                </Text>

            </div>
        </div>
    )
}

export default PartnersCard