import React, { useEffect, useState } from 'react'
import ImageNext from 'atoms/ImageNext'
import { useRouter } from 'next/router'
import { getAuth } from 'services/identity.service'
import Text from 'atoms/Text'
import Button from 'atoms/Button'
import { getAppointmentsCount } from 'services/reseller.service'
import ModalForPatientConsultation from 'molecules/Modals/ModalForPatientConsultation'

const AppointmentCount = ({ className = '', handlerOpenStoreModal, width }) => {
    const router = useRouter();
    const isPractitionerPage = router.pathname.includes('practitioner');
    const auth = getAuth();
    const [scheduledAppointment, setScheduledAppointment] = useState(0);
    const [consultedAppointment, setConsultedAppointment] = useState(0);
    const [isConsultationEnabled, setIsConsultationEnabled] = useState(false);
    const [showPatientConsultationModal, setShowPatientConsultationModal] = useState(false);

    const textStyle = 'text-gray-900 text-xs md:text-sm font-Open-Sans';
    const labelTextStyle = 'text-gray-900 text-xs md:text-sm font-Open-Sans font-semibold';

    const imageSize = width < 640 ? { width: 150, height: 110 } : { width: 140, height: 103 };

    useEffect(async () => {
        const res = await getAppointmentsCount(auth?.user?.id);
        if (res?.status) {
            setScheduledAppointment(res?.entity?.scheduledAppointments);
            setConsultedAppointment(res?.entity?.consultedAppointments);
            setIsConsultationEnabled(res?.entity?.isVideoConsultationEnabled);
        }
    }, []);

    const handleModalStateChange = () => {
        setShowPatientConsultationModal(false);
    };

    return (
        <div className={`${className} w-45 md:w-[376px] p-3 md:px-5 md:py-4 border-[1px] border-gray-200 rounded-lg`}>
            <Text className='text-sm md:text-lg text-gray-900 font-Montserrat font-semibold md:whitespace-nowrap'>
                {isConsultationEnabled ? 'Patient Consultations' : 'Appointment Calender'}
            </Text>
            <div className={`flex ${isConsultationEnabled ? 'gap-4' : ''}`}>
                <div className='flex flex-col justify-between mt-3'>
                    {
                        isConsultationEnabled ?
                            <>
                                <div className='flex justify-between md:gap-19 mt-0'>
                                    <Text className={textStyle}>Scheduled</Text>
                                    <Text className={`${labelTextStyle} font-semibold`}>{scheduledAppointment}</Text>
                                </div>
                                <div className='flex justify-between md:gap-19 mt-2.5'>
                                    <Text className={textStyle}>Consulted</Text>
                                    <Text className={`${labelTextStyle} font-semibold`}>{consultedAppointment}</Text>
                                </div>
                            </> :
                            <Text className='text-xs md:text-sm font-Open-Sans'>Consultation not enabled</Text>
                    }
                    <div className='mt-5 block md:hidden'>
                        <ImageNext src='/images/Isolation_Mode.webp' alt='calendar image' {...imageSize} />
                    </div>

                    <Button size='extraSmall' className={`w-37.5 ${isConsultationEnabled ? 'mt-6 md:mt-3' : 'mt-14 md:mt-7'} whitespace-nowrap`} onClick={() => isConsultationEnabled ? router.push('/my-appointments') : setShowPatientConsultationModal(true)}>
                        {isConsultationEnabled ? 'Apt Calendar' : 'Learn More'}
                    </Button>
                </div>
                <div className='md:block hidden'>
                    <ImageNext src='/images/Isolation_Mode.webp' alt='calendar image' {...imageSize} />
                </div>
            </div>
            {
                showPatientConsultationModal && <ModalForPatientConsultation isModalOpen={showPatientConsultationModal} handleModalStateChange={handleModalStateChange}/>
            }
        </div>
    );
}

export default AppointmentCount;
