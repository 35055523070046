import React, { useState } from 'react'
import IconCopyThick from 'atoms/SvgIcons/IconCopyThick.svg'
import IconShare from 'atoms/SvgIcons/IconShare.svg'
import Text from 'atoms/Text'
import { CLR } from 'styles/theme'
import Tooltip from 'rc-tooltip'
import Loader from 'components/Loader/Loading';
import IconShareProfile from 'atoms/SvgIcons/HompagePatientIcons/IconShareProfile.svg'

const VARIANT = {
    DEFAULT: {
        wrapper: 'w-9.5 h-9.5',
        iconSize: 20,
    },
    SMALL: {
        wrapper: 'w-7 h-7',
        iconSize: 15,
    },
}


const Share = ({ className = '', type, onClick, ToolTipDirection, variant = 'DEFAULT', sharingIsNotAllowed = false, bypassLoaderAndTooltip = false }) => {
    const [visible, setVisible] = useState(false);
    const [loader, setLoader] = useState(false)

    const shareHandler = (e) => {
        if (sharingIsNotAllowed && bypassLoaderAndTooltip) {
            onClick(e);
            return;
        }
        if (sharingIsNotAllowed) return;
        setLoader(true)
        setTimeout(() => {
            setLoader(false)
            setVisible(true);

            setTimeout(() => {
                setVisible(false);
            }, 2000);
        }, 1000);
        onClick(e)
    }

    return (
        type === 'ONLY_ICON'
            ? <div className={`${className} ${VARIANT[variant]?.wrapper} rounded-full bg-gray-100 p-2 shadow-xs transition-all hover:bg-gray-200 flex justify-center items-center`} onClick={shareHandler}>
                {loader
                    ? <Loader type='spinner' className="w-full" color='text-primary1-300' />
                    : <Tooltip
                        placement="top"
                        visible={visible}
                        overlayClassName='bg-gray_800'
                        animation="zoom"
                        trigger="click"
                        overlayStyle={{
                            opacity: 1,
                            borderRadius: 4,
                            background: 'unset'

                        }}
                        overlayInnerStyle={{
                            background: 'rgb(31, 31, 55)',
                            padding: '4px 8px',
                            fontSize: 12,
                            color: 'rgb(255 255 255)',
                            border: 'unset',
                            minHeight: 'unset',
                            fontWeight: 600,
                        }}
                        overlay={<span>Link Copied</span>}
                    >
                        <IconShare className='text-gray-500' size={VARIANT[variant]?.iconSize} />
                    </Tooltip>
                }

            </div>
            : type === 'shareIcon' ?
                <div className={`${className} ${VARIANT[variant]?.wrapper} bg-gray-100 p-2 transition-all flex items-center`} onClick={shareHandler}>
                    {loader
                        ? <Loader type='spinner' className="w-6" color='text-primary1-300' />
                        : <Tooltip
                            placement="top"
                            visible={visible}
                            overlayClassName='bg-gray_800'
                            animation="zoom"
                            trigger="click"
                            overlayStyle={{
                                opacity: 1,
                                borderRadius: 4,
                                background: 'unset'

                            }}
                            overlayInnerStyle={{
                                background: 'rgb(31, 31, 55)',
                                padding: '4px 8px',
                                fontSize: 12,
                                color: 'rgb(255 255 255)',
                                border: 'unset',
                                minHeight: 'unset',
                                fontWeight: 600,
                            }}
                            overlay={<span>Link Copied</span>}
                        >
                            <IconShareProfile className='w-6 h-6 md:w-7.5 md:h-7.5 cursor-pointer hover:text-primary1-500'/>
                        </Tooltip>
                    }

                </div>

                :
                <Text type='bodySmall' color={CLR.gray600.text} fontWeight={600} className={`${className} inline-flex gap-1.5 group hover:text-primary1-500 cursor-pointer py-1`} onClick={onClick}>
                    <IconCopyThick size={VARIANT[variant]?.iconSize} className='fill-gray-400 group-hover:fill-primary1-500' />
                    <span className='shrink-0'>Copy</span>
                </Text>
    )
}

export default Share