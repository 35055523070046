import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupValidator } from "lib/yup-validator";

import { appointment_request_auth, createLead, verifyLeadOtp } from "services/my-leads";
import WidgetEvents, { onConfirmingBookingAppointment, onConfirmingBookingAppointmentClose, onResendingBookingAppointmentOtp, onSubmitBookingAppointmentDetails, onSubmitBookingAppointmentDetailsClose, onVerifyingBookingAppointmentOtp } from "components/my-leads/LeadMoEngage";
import { GetServiceblePincode, GetServicesSpecilizationList, LoginWithMobileOtp, GetPincodeDataForProfile } from "services/user.service";
import OtpCard from "molecules/v1/OtpCard";
import ThankYouCard from "../ModalConsultDoctor/ThankYouCard";
import { setAuth, setSourceOfUser, getAuth } from "services/identity.service";
import { useRouter } from "next/router";
import { CHANNEL } from "constant/warehouse_pincode_mapping";
import { getDashboardConstant } from "services/reseller.service";
import appointment_flow from "../../assets/mo_engage/appointment_flow.json"
import { captureEvent } from 'services/analytics.service';
import { APPOINTMENT_GA_CATEGORY } from "constant/myAppointment";
import AppointmentForm from "./AppointmentForm";
import Modal from "atoms/Modal";

export default function MakeAppointmentListing({ section, doctorId = null, isCoverContent, buttonId, isFrontForm = 'false', isModalOpen, setIsModalOpen= () => { }, onBackFromBookAppointment, doctor = null, isShowBackFirstPage = false, className = '', isGridView, appointmentBenefitsList, leadType = 0, isPopup = false, followUpData, selectedSlotTime = null, consultationFee = 0, appointmentType, clinicId = null, isHomePage=false, showModal, setIsShowBookApptModal, blogDetail=false, heading }) {

    const [screen, setScreen] = useState(0);
    const [alertMsg, setAlertMsg] = useState(null);
    const [mobile, setMobile] = useState("");
    const [city, setCity] = useState("");
    const [queryPrompt, setQueryPrompt] = useState("");
    const [queryLeadId, setQueryLeadId] = useState(null);
    const [otpValue, setOtpValue] = useState("");
    const [isDisableProceedBtn, setIsDisableProceedBtn] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    let specializationId;
    const [firstLastPincodeValue, setLastPincodeValue] = useState("");
    const [appointmentBenefits, setAppointmentBenefits] = useState([]);
    const [isLoadingAppointBenefits, setIsLoadingAppointBenefits] = useState(false);
    const router = useRouter();
    const [disableFieldKey, setDisableFieldKey] = useState(false);
    const [leadDoctorId, setLeadDoctorId] = useState(null);
    const [disableIsFollowUp, setDisableIsFollowUp] = useState(false);
    const auth = getAuth();
    const [isEnableWhatsapp, setEnableWhatsapp] = useState(false);


    const formData = {
        name: auth?.user?.fullName ?? '',
        mobile: auth?.user?.mobileNumber ?? '',
        pincode: auth?.user?.tbl_doctor_profile?.pincode ?? '',
    }

    const eventCategory = 'Appointment_Create';
    const eventNames = appointment_flow?.entity?.OTP_Click?.event_name;

    const onClose = ({ isResetFrontForm = false }) => {
        setScreen(0);
        setOtpValue('');
        isResetFrontForm && reset();
    }


    const {
        register,
        handleSubmit,
        control,
        watch,
        setError,
        setValue,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm({
        mode: 'onChange',
        resolver: yupValidator(
            yup.object().shape({
                mobile: yup.string().required("Mobile number is required").matches(/^((?:(?:\+|0{0,2})93(\s*[\-]\s*)?|[0]?)?[6789]\d{9})*$/, "Mobile number is not valid"),
                fullName: yup
                    .string()
                    .required("Name is Required")
                    .test("match", "Name should not contain salutation.", async (value) => !/^(Mr\.|Mr\s|mr\.|mr\s|Mrs\.|Mrs\s|mrs\.|mrs\s|Miss\.|Miss\s|miss\.|miss\s|Dr\.|Dr\s|dr\.|dr\s|Sr\.|Sr\s|sr\.|sr\s)/.test(value.trim().toLocaleLowerCase())),
                queryPrompt: yup.string().trim()
                    .required("This field is mandatory"),

                pincode: yup.string().required("Pincode is Required").test('len', "Pincode not recognised by the system", async val => {
                    if (!(/^[1-9][0-9]{5}$/.test(val))) {
                        return false
                    }
                    if (val != firstLastPincodeValue) {
                            const pincodeResponse = await GetPincodeDataForProfile({ pincode: val, forProfile: 1 })
                            setLastPincodeValue(val)
                            if (pincodeResponse.entity?.block == 'NA') {
                                setValue('city', pincodeResponse.entity?.district)
                            } else {
                                setValue('city', pincodeResponse.entity?.block)
                            }
                            pincodeResponse.entity && setValue('state', pincodeResponse.entity?.state?.toLocaleLowerCase()?.replace(/\b\w/g, match => match.toUpperCase()))
                            if(pincodeResponse.entity){
                                return true;
                            }else{
                                return false
                            }
                    } else {
                        return true
                    }
                }).typeError("Should be a number"),
            })

        )
    });

    const handlerAutoLogin = async ({ mobileNumber, otp, queryLeadId, leadType, doctorId, isPaid, alertMessage=null }) => {
        const apiData = {
            country_code: "91",
            mobileNumber,
            otp,
            userType: 1,
        }
        if (auth?.user?.id) {
            auth.user['leadDetails'] = { leadId: queryLeadId, leadType: leadType, doctorId: doctorId, isPaid, alertMessage }
            setAuth(auth);
            otpRedirect();
        }
        else {
            const response = await LoginWithMobileOtp(apiData)
            if (response.data.message === "Successfull User Login") {
                captureEvent(eventCategory, eventNames, 'LogIn', {});
                const user = response.data.entity;
                user.isVerifyCheckedKey = user?.user?.tbl_doctor_profile?.is_verified
                user.user['leadDetails'] = { leadId: queryLeadId, leadType: leadType, doctorId: doctorId, isPaid, alertMessage }
                setAuth(user);
                otpRedirect();
            } else {
                setAlertMsg({ message: response?.data?.message, type: 'error' })
            }
        }
    }

    const onSubmitForm = async (data) => {
        setIsLoading(true)
        const { fullName, mobile, city, state, queryPrompt, specializationIdForm, pincode } = data
        setQueryPrompt(queryPrompt);
        if (auth?.user?.id) {
            await verifyOtp('', auth?.user?.id);


        } else {
            captureEvent(eventCategory, eventNames, 'Initiate', { action: 'Sent' });
            onSubmitBookingAppointmentDetails(
                () => {
                    try {
                        createLead({ fullName, mobile, city: city, state: state, queryPrompt, section, doctorId: Number(doctorId), specializationId: Number(specializationId), pincode, leadType: doctorId ? 0 : 1 })
                            .then(response => {
                                captureEvent(eventCategory, eventNames, 'Success', {});

                                setIsLoading(false)
                                if (!response.data.status && response.data.message !== `Not abel to find any doctor for specializationId :${specializationId} ,  because of No doctor found`) {
                                    setAlertMsg({
                                        message: !response.data.status ? response.data.message : response.message,
                                        type: "error"
                                    });
                                    return;
                                }
                                setLeadDoctorId(doctorId);
                                isFrontForm == true && setIsModalOpen(true)
                                setScreen(screen => screen + 1);
                            })
                            .catch(error => {
                                captureEvent(eventCategory, eventNames, 'Failure', {});
                                setIsLoading(false)
                                throw error
                            });
                    } catch (error) {
                        setIsLoading(false)
                        setAlertMsg({ message: error.message, type: "error" });
                    }
                },
                {
                    [WidgetEvents.SUBMIT_APPOINTMENT_BOOKING.attributes.PATIENT_NAME]: fullName,
                    [WidgetEvents.SUBMIT_APPOINTMENT_BOOKING.attributes.PATIENT_LOCATION]: city,
                    [WidgetEvents.SUBMIT_APPOINTMENT_BOOKING.attributes.PATIENT_QUERY_PROMPT]: queryPrompt,
                    [WidgetEvents.SUBMIT_APPOINTMENT_BOOKING.attributes.PAGE]: section
                }
            );
        }
    };

    const onSubmitOtp = (otp) => {
        verifyOtp(otp);
        captureEvent(eventCategory, eventNames, 'Success', { action: 'Verify' });
    };

    function verifyOtp(otp = null, patientUserId) {
        let verifyLeadId;
        setIsDisableProceedBtn(true)
        setIsLoading(true)
        onVerifyingBookingAppointmentOtp(
            () => {
                try {
                    const mobile = watch('mobile');
                    doctorId = leadDoctorId ?? doctorId;
                    const comment = watch('queryPrompt')
                    let requestUrl;
                    if (auth?.user?.id) {
                        requestUrl = appointment_request_auth({
                            otp, mobile, doctorId: Number(doctorId), city: watch('city'), state: watch('state'), createdBy: auth?.user?.id || null, updatedBy: auth?.user?.id || null, fullName: watch('fullName'), pincode: watch('pincode'), queryPrompt: comment, section, parentUserId: patientUserId, appointmentTime: selectedSlotTime,
                            clinicId: clinicId,
                            appointmentType: appointmentType,
                        });
                    } else {
                        requestUrl = verifyLeadOtp({
                            otp, mobile, doctorId: Number(doctorId), city: watch('city'), state: watch('state'), createdBy: auth?.user?.id || null, updatedBy: auth?.user?.id || null, fullName: watch('fullName'), pincode: watch('pincode'), queryPrompt: comment, section, parentUserId: patientUserId, appointmentTime: selectedSlotTime,
                            clinicId: clinicId,
                            appointmentType: appointmentType,
                        });
                    }
                    if (requestUrl) {
                        requestUrl.then(async (res) => {
                            let response = res?.data ?? res;
                            if (!response?.status) {
                                setIsDisableProceedBtn(false);
                                setIsLoading(false)
                                if (response?.errorType === "validation") {
                                    if (Array.isArray(response?.message)) {
                                        setAlertMsg({ message: response?.message?.[0], type: "error" });
                                    } else {
                                        setAlertMsg({ message: "Invalid OTP. Please try again.", type: "error" });
                                    }
                                } else {
                                    setAlertMsg({ message: response?.message, type: "error" });
                                    setTimeout(() => setIsModalOpen(false), 5000);
                                }
                                return;
                            } else if (response?.status) {
                                if (response?.data?.status) {
                                    reset();
                                } else {
                                    setIsLoading(false)
                                    setIsDisableProceedBtn(false);
                                }
                            }
                            onConfirmingBookingAppointment(
                                () => { },
                                {
                                    [WidgetEvents.CONFIRMING_BOOK_APPOINTMENT.attributes.MOBILE_NUMBER]: mobile,
                                    [WidgetEvents.CONFIRMING_BOOK_APPOINTMENT.attributes.PAGE]: section
                                }
                            );
                            let appointmentData = response?.data?.entity ?? response?.entity;
                            verifyLeadId = appointmentData?.id;
                            verifyLeadId ? setAlertMsg({ message: response?.message, type: "success" }) : '';
                            setQueryLeadId(verifyLeadId);
                            await handlerAutoLogin({ mobileNumber: mobile, otp: otp, queryLeadId: verifyLeadId, leadType: verifyLeadId ? 0 : 1, doctorId, isPaid: 0, alertMessage: verifyLeadId ? '' : appointmentData?.alertMessage})
                            setIsLoading(false)
                        })
                            .catch(error => {
                                setIsLoading(false)
                                setIsDisableProceedBtn(false);
                                throw error
                            });
                    }
                } catch (error) {
                    setIsLoading(false)
                    setIsDisableProceedBtn(false);
                    setAlertMsg({ message: error.message, type: "error" });
                }
            },
            {
                [WidgetEvents.VERIFYING_BOOK_APPOINTMENT_OTP.attributes.BOOKING_ID]: verifyLeadId,
                [WidgetEvents.VERIFYING_BOOK_APPOINTMENT_OTP.attributes.MOBILE_NUMBER]: mobile,
                [WidgetEvents.VERIFYING_BOOK_APPOINTMENT_OTP.attributes.PAGE]: section
            }
        );

    }

    function otpRedirect() {
        onClose({ isResetFrontForm: true })
        setSourceOfUser(JSON.stringify({ type: 'appointmentFormLogout' }))
        if (router.pathname === '/' || router.pathname === '/request-appointment') {
            window.scrollTo(0, 0);
            router.reload();
        } else {
            router.push('/');
        }
    }

    const onResendOtp = () => {
        captureEvent(eventCategory, eventNames, 'Initiate', { action: 'Resend' });
        onResendingBookingAppointmentOtp(
            () => {
                try {
                    const mobile = watch('mobile');
                    createLead({
                        mobile, fullName: watch('fullName'), doctorId: Number(leadDoctorId),
                    }).then(response => {
                        if (!response.status) {
                            throw new Error(response.data.message);
                        }
                        setAlertMsg({ message: response.data.message, type: "success" });
                    })
                        .catch(error => { throw error });
                } catch (error) {
                    setAlertMsg({ message: error.message, type: "error" });
                }
            },
            {
                doctor: { [WidgetEvents.Doctor_Appointment_Click.attributes.DOCTOR_ID]: doctorId ? doctorId : null },
                blogs: { [WidgetEvents.Blog_Appointment_Click.attributes.AUTHOR_ID]: null }
            },
            doctorId ? "doctor" : "blogs"
        );
    };

    const onBack = () => {
        screen === 1
            ? setScreen(screen - 1)
            : onBackFromBookAppointment()
    }

    useEffect(() => {
        if (otpValue.length === 4) {
            setIsDisableProceedBtn(false)
        } else {
            setIsDisableProceedBtn(true)
        }
    }, [otpValue.length]);

    useEffect(async () => {
        if (!doctorId) {
            let dashboard;

            if (appointmentBenefitsList) {
                dashboard = appointmentBenefitsList;
            } else {
                setIsLoadingAppointBenefits(true);
                const dashboardResponse = await getDashboardConstant('');
                dashboard = dashboardResponse?.entity?.request_appointment
            }

            const appointmentBenefitsList = [
                { id: 1, src: '/images/rupeeBookOnly.svg', reqLabel: dashboard?.bookPrice, style: 'w-20' },
                { id: 2, src: '/images/homeGetAppt.svg', reqLabel: dashboard?.appointmentTime, style: '' },
                { id: 3, src: '/images/homeFollowUp.svg', reqLabel: dashboard?.followUp, style: '' },
                { id: 4, src: '/images/homeRx.svg', reqLabel: dashboard?.prescription, style: '' },
            ]

            setAppointmentBenefits(appointmentBenefitsList)
            setIsLoadingAppointBenefits(false);
        }
    }, [appointmentBenefitsList])

    useEffect(() => {
        setValue('mobile', auth?.user?.mobileNumber ? auth?.user?.mobileNumber : '');
        setDisableFieldKey(auth?.user?.id ? true : false)
    }, []);

    useEffect(() => {
        if (followUpData && followUpData?.is_popup) {
            setValue('mobile', followUpData?.mobile);
            setValue('fullName', followUpData?.name);
            setDisableIsFollowUp(true)
        }
    }, [followUpData]);

    const screens = [
        () => (
            <AppointmentForm
                onSubmit={onSubmitForm}
                formData={formData}
                handleSubmit={handleSubmit}
                setValue={setValue}
                register={register}
                errors={errors}
                setEnableWhatsapp={setEnableWhatsapp}
                isEnableWhatsapp={isEnableWhatsapp} 
                showModal={showModal}
                setIsShowBookApptModal={setIsShowBookApptModal}
                isHomePage={isHomePage}
                blogDetail={blogDetail}
                heading={heading}
            />
        ),
        ({ alertMsg, setAlertMsg }) => (
            showModal ? 
            <Modal isModalOpen={true} isShowCloseBtn={true} handlerModalClose={() => setIsShowBookApptModal(false)}>
                <OtpCard
                className='border max-h-[400px] border-primary1-200 mx-3 mb-3 rounded-2xl'
                bodyClass='px-7.5 pb-7.5 max-w-[382px] mx-auto'
                isLoading={isLoading} heading='Verification'
                description={`We have sent a 4- digit OTP via SMS and WhatsApp on +91 ${watch('mobile')}`}
                alertMsg={alertMsg}
                setAlertMsg={setAlertMsg}
                setOtpValue={setOtpValue}
                otpValue={otpValue}
                isDisableProceedBtn={isDisableProceedBtn}
                onSendOtp={onSubmitOtp}
                onReSendOtp={onResendOtp}
                setIsDisableProceedBtn={setIsDisableProceedBtn}
            />
            </Modal> :
            <OtpCard
                className='border max-h-[400px] border-primary1-200 mx-3 mb-3 rounded-2xl'
                bodyClass='px-7.5 pb-7.5 max-w-[382px] mx-auto'
                isLoading={isLoading} heading='Verification'
                description={`We have sent a 4- digit OTP via SMS and WhatsApp on +91 ${watch('mobile')}`}
                alertMsg={alertMsg}
                setAlertMsg={setAlertMsg}
                setOtpValue={setOtpValue}
                otpValue={otpValue}
                isDisableProceedBtn={isDisableProceedBtn}
                onSendOtp={onSubmitOtp}
                onReSendOtp={onResendOtp}
                setIsDisableProceedBtn={setIsDisableProceedBtn}
            />
        ),
        () => (
            <ThankYouCard
                thankYouMsg={`Your appointment request has been booked. You will get ${watch('fullName')}'s detail on your registered mobile number shortly.`}
            />
        )
    ];

    return (
        <>
            {screens[screen]({ alertMsg, setAlertMsg })}
        </>
    );
}