import Badge from "atoms/Badge";
import Button from "atoms/Button";
import InputSearch from "atoms/InputSearch";
import LinkAnchor from "atoms/LinkAnchor";
import IconContactV2 from "atoms/SvgIcons/IconContactV2.svg";
import IconNotification from "atoms/SvgIcons/IconNotification.svg";
import Text from "atoms/Text";
import { useRouter } from "next/router";
import { useState, useEffect, useRef } from "react";
import { getAuth } from "services/identity.service";
import { getNotificationCount } from "services/notification.service";
import { useCartContext } from "../context/cartContext";
import IconCart from "atoms/SvgIcons/IconCart.svg";
import useWindowSize from "customHook/useWindowSize";

export default function SearchContactLoginMob() {
    const auth = getAuth();
    const router = useRouter();
    const route = router.pathname.split('/')[1];
    const [isVerifiedUser, setIsVerifiedUser] = useState(false);
    const [notificationCount, setNotificationCount] = useState(0);
    const [isNotificationRead, setIsNotificationRead] = useState(false)
    const { isLoadingCart } = useCartContext();
    const anchorRef = useRef(null);
    const { cartCount } = useCartContext();
    const [windowWidth] = useWindowSize();


    useEffect(() => {
        setIsVerifiedUser(!!getAuth())
    }, [setIsVerifiedUser, getAuth, router.pathname])

    useEffect(() => {
        if (auth?.user.id) {
            getNotificationCount(auth?.user.id).then((data) => {
                if (isNotificationRead) {
                    setNotificationCount(0)
                } else {
                    setNotificationCount(data?.entity)
                }
            })
        }
    }, [isNotificationRead])

    return (
        <div className="bg-basic-white w-full py-2.5 px-4 flex items-center gap-5 justify-between overflow-hidden">
            <div onClick={() => { router.push('/medicines/allMedicines') }} >
                <InputSearch className={`sm:flex h-10 max-w-full text-sm border border-gray-300`} inputClass='ml-3' placeholder={'Search'} searchText={''} dataAutomation='search-medicines' />
            </div>

            <div className="flex items-center gap-5">
                {auth && <LinkAnchor href={isVerifiedUser ? '/notifications' : 'javascript:void(0)'} className={`${isVerifiedUser ? '' : 'iconLinks--disable'} group`} dataAutomation='notification'>
                    <Text className='flex flex-col items-center whitespace-nowrap iconLinks__text' type='caption' color='hover:text-primary1-500 text-gray-900'>
                        <Badge type='primary' badgeContent={notificationCount} isLoading={isLoadingCart}>
                            <IconNotification className='sidebarIcon iconLinks__icon w-6 cursor-pointer' />
                        </Badge>
                        <span className='mt-0.5 hidden lg:block'>Notification</span>
                    </Text>
                </LinkAnchor>}

                <LinkAnchor href={'/contact-us'} dataAutomation='need-help'>
                    <Text className='flex flex-col items-center whitespace-nowrap iconLinks__text' type='caption' color='hover:text-primary1-500 text-gray-900'>
                        <Badge type='primary' badgeContent={0}>
                            <IconContactV2 className='sidebarIcon iconLinks__icon w-6 cursor-pointer nirog' />
                        </Badge>
                        <span className='mt-0.5 hidden lg:block'>Support</span>
                    </Text>
                </LinkAnchor>

                {auth && <LinkAnchor href={isVerifiedUser && windowWidth < 768 ? '/cart' : 'javascript:void(0)'} className={`${isVerifiedUser ? '' : 'iconLinks--disable'} group`} ref={anchorRef}
                    {...(isVerifiedUser)} onClick={() => {
                        setShowProfilePopup(false); setShowCartPopup(true); fetchCartDetail();
                    }} dataAutomation='my-cart'>
                    <Text className='flex flex-col items-center whitespace-nowrap iconLinks__text relative' type='caption' color='hover:text-primary1-500 text-gray-900'>
                        <Badge type='primary' badgeContent={cartCount} isLoading={isLoadingCart}>
                            <IconCart className='sidebarIcon iconLinks__icon w-6 cursor-pointer' />
                        </Badge>
                    </Text>
                </LinkAnchor>}

                {(route !== 'sign-up' && !auth)
                    && <LinkAnchor href={(route == 'practitioner') || (router.query.type == 'practitioner') ? '/login?type=practitioner' : '/login?type=individual'}>
                        <Button className={'text-sm min-h-[36px] py-0 px-3 w-[78px]'} size="small" dataAutomation='login-signup'>Login</Button>
                    </LinkAnchor>
                }
            </div>
        </div>
    );
}
