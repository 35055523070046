import ImageNext from 'atoms/ImageNext';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';

import IconBookmark from 'atoms/SvgIcons/IconBookmark.svg';
import IconCart from 'atoms/SvgIcons/IconCart.svg';
import IconCategory from 'atoms/SvgIcons/IconCategory.svg';
import IconClose from 'atoms/SvgIcons/IconClose.svg';
import IconDollar from 'atoms/SvgIcons/IconDollar.svg';
import IconHamburger from 'atoms/SvgIcons/IconHamburger.svg';
import IconHeart from 'atoms/SvgIcons/IconHeart.svg';
import IconInformation from 'atoms/SvgIcons/IconInformation.svg';
import IconLocation from 'atoms/SvgIcons/IconLocation.svg';
import IconLogout from 'atoms/SvgIcons/IconLogout.svg';
import IconNotification from 'atoms/SvgIcons/IconNotification.svg';
import IconProfile from 'atoms/SvgIcons/IconProfile.svg';
import IconSearch from 'atoms/SvgIcons/IconSearch.svg';
import UserCategoryBadge from 'atoms/UserCategoryBadge';
import { useUserContext } from 'components/context/UserContext';
import { searchMedicine } from 'core/medicines/SearchMedicine';
import ModalForPincodeChange from 'molecules/Modals/ModalForPincodeChange';
import PincodeChange from 'molecules/PincodeChange';
import TextIcon from 'molecules/TextIcon';
import Skeleton from 'react-loading-skeleton';
import { getAllDoctors } from 'services/ayurvedic-doctor.service';
import { getSearchedClinic } from 'services/medicine.service';
import * as moengage from "services/moengage.service";
import { getNotificationCount } from 'services/notification.service';
import { getTransformedProducts } from 'utils/medicine-listing/tranform-medicine-lists';
import mo_engage_click_data from "../assets/mo_engage/click_data";
import mo_engage_ecomm from "../assets/mo_engage/e_commerce";
import Avatar from '../atoms/Avatar';
import Badge from '../atoms/Badge';
import Button from '../atoms/Button';
import Container from '../atoms/Container';
import InputSearch from '../atoms/InputSearch';
import LinkAnchor from '../atoms/LinkAnchor';
import Text from '../atoms/Text';
import { onProfileIconClick } from "../components/ProfileCard/ProfileMoengage";
import { useAddressContext } from '../components/context/addressContext';
import { useCartContext } from '../components/context/cartContext';
import MedicineShortSummery from '../molecules/MedicineShortSummery';
import useWindowSize from './../customHook/useWindowSize';
import { getAuth, getSourceOfUser, removeAuth, removeSourceOfUser, setRedirectUrl } from './../services/identity.service';
import EcommSearchMenu from './EcommSearchMenu';
import IndividualsSearchSection from './IndividualsSearchSection';

const PhpUrl = (process.env.NODE_ENV == 'development') ? "https://stg3web.1veda.in" : process.env.NEXT_PUBLIC_API_URL_PHP;
const nirogstreet = '/images/nirogstreet.svg';
const buckerUrl = process.env.NEXT_PUBLIC_BUCKET_URL

import Loading from 'components/Loader/Loading';
import dynamic from 'next/dynamic';
import IconFilterV2 from 'atoms/SvgIcons/IconFilterV2.svg';
import IconMyClinic from 'atoms/SvgIcons/IconMyClinic.svg';
import { getBlogsListing } from '@/components/blog/BlogListingApiHandlers';
import mo_engage_nirog_partner from "../assets/mo_engage/nirog_partner"
import IconReferralSidebar from 'atoms/SvgIcons/IconReferralSidebar.svg';
import IconBankDetail from 'atoms/SvgIcons/IconBankDetail.svg';
import { saveUserSearch } from 'services/user.service';
import { getTransformedProductsData } from 'utils/medicine-listing/tranform-medicine-lists-v2';
import IconMyMedicine from 'atoms/SvgIcons/IconMyMedicine';
import IconAppointmentReq from 'atoms/SvgIcons/IconAppointmentReq.svg';
import IconCalenderTickV2 from 'atoms/SvgIcons/IconCalenderTickV2.svg';
import IconContactV2 from 'atoms/SvgIcons/IconContactV2.svg';
import AppointmentThankYouModal from '@/components/BookAnAppointment/AppointmentThankYouModal';
import NeedBulkMedicine from '@/components/HomePage/NeedBulkMedicine';
import { USER_TYPES } from 'constant';
import { handleAnalyticEventHandleHeaderNavigation, handleAnalyticEventHandleProceedOrderFlow } from 'utils/ga-analytic';
import googleAnalytics from "../assets/mo_engage/order_flow_ga.json"
import { hasAccess } from 'core/common/userAccess';
import MakeAppointmentListing from '@/components/AyurvedicDoctor/MakeAppointmentListing';
const MenuV2 = dynamic(() => import('../molecules/MenuV2'), { ssr: false, loading: () => <Loading type='layer' /> });
const ModalForPatientUser = dynamic(() => import('molecules/Modals/ModalForPatientUser'), { ssr: false, loading: () => <Loading type='layer' /> });

const HeaderBase = ({ className, isNotificationRead, isMobileInnerPage = false, onTriggerSearch, ...props }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [showCartPopup, setShowCartPopup] = useState(false);
    const [showProfilePopup, setShowProfilePopup] = useState(false);
    const { cartDetails, fetchCartDetail, removeItemFromCart, isLoadingCart, userType, cartCount, showAddMoreItemsToOrder } = useCartContext();
    const [isVerifiedUser, setIsVerifiedUser] = useState(false)
    const [windowWidth] = useWindowSize();
    const [isMobSearch, setIsMobSearch] = useState(false);
    const anchorRef = useRef(null);
    const profileRef = useRef(null);
    const searchMobRef = useRef(null);
    const userRef = useRef(null)
    const router = useRouter();
    const route = router.pathname.split('/')[1]
    const auth = getAuth();
    const entity = mo_engage_ecomm.entity;
    const [searchList, setSearchList] = useState([]);
    const [isFetchSearch, isSetFetchSearch] = useState(false);
    const [search, setSearch] = useState('');
    const [isShowSearch, setIsOpenSearch] = useState(false);
    const [searchType, setSearchType] = useState(`${auth?.user?.userType === 1 ? 'doctor' : 'medicine'}`);
    const [notificationCount, setNotificationCount] = useState(0);
    const [offset, setOffset] = useState(0);
    const [reqAppointmentThankUModal, setReqAppointmentThankUModal] = useState({ isOpen: false });
    const limit = 25
    const click_data_entity = mo_engage_click_data.entity;
    const attributes_home = {};
    attributes_home[click_data_entity["path"]["source_of_click"]] = click_data_entity.path.from_home;
    const attributes_profile = {};
    attributes_profile[click_data_entity["path"]["source_of_click"]] = click_data_entity.path.from_profile;
    const { user: { userCategory, profile_pic, is_verified }, userAccess } = useUserContext();
    const { showAddress, addressList, setShowAddress, showBillingAddress, setShowBillingAddress, isOpenPincodeModal, reset, setIsOpenPincodeModal, handleModalStateChange, pincodeValue, setPincodeValue, isShowAddressModal, stateList } = useAddressContext()
    const phpBucketUrl = (process.env.NODE_ENV == 'development') ? "https://stg3web.1veda.in" : process.env.NEXT_PUBLIC_API_URL_PHP;
    const entity_partner = mo_engage_nirog_partner.entity
    const [patientIsModalOpen, setPatientIsModalOpen] = useState(false);
    const [addPatientAddress, setAddPatientAddress] = useState(false);
    let attributes = {};
    const analyticEntity = googleAnalytics.entity;
    const isPractitonerHomepage = router?.pathname == '/practitioner';
    const [width] = useWindowSize();

    const [isShowBookApptModal, setIsShowBookApptModal] = useState(false);
    const type = router.asPath?.includes("clinics") ? "clinic" : "doctor"
    const handleProceedToCart = (e) => {
        if (!isVerifiedUser) {
            e.preventDefault();
            return false;
        }

        handleAnalyticEventHandleProceedOrderFlow({ router })
        router.push('/cart');
    }

    const handlerLogout = () => {
        moengage.destroySession();
        const userType = auth?.user?.userType;
        removeAuth();
        auth?.user?.userType == 1 ? (router.push('/'), router.reload()) : router.push('/practitioner');
    }

    const handlerHideSearch = useCallback(() => {
        setIsMobSearch(false)
        setSearch('')
    }, [isMobSearch, setIsMobSearch])

    const onSearchEnter = (e) => {
        if (e.key === 'Enter') {
            switch (searchType) {
                case 'medicine':
                    saveUserSearch({ search: search }, auth?.user?.id)
                    router.push(`/medicines/allMedicines?search=${search.toString()}`)
                    moengage.trackEvent(entity.search_medicine.event_name, { [entity.search_medicine.attributes_search_medicine.search_text]: e.target.value })
                    break;

                case 'blog':
                    router.push(`/blogs`);
                    moengage.trackEvent(entity.search_blog.event_name, { [entity.search_blog.attributes_search_blog.search_text]: e.target.value })
                    break;

                case 'clinic':
                    router.push(`/ayurveda/clinics/${search !== "" ? `?search=${search}` : ""}`)
                    moengage.trackEvent(entity.search_clinic.event_name, { [entity.search_clinic.attributes_search_clinic.search_text]: e.target.value })
                    break;

                case 'doctor':
                    router.push(`/ayurveda/doctors${search !== "" ? `?search=${search}` : ""}`)
                    moengage.trackEvent(entity.search_doctor.event_name, { [entity.search_doctor.attributes_search_doctor.search_text]: e.target.value })
                    break;

                default:
                    break;
            }
        }


    }

    const viewMedicineDetails = (slug, id, medicineName, brand, brand_id, price, isTopDeal) => {
        let attributes = {};
        attributes[entity["product_click"]["attributes_product_click"]["category_key"]] = brand;
        attributes[entity["product_click"]["attributes_product_click"]["variant_id_key"]] = brand_id;
        attributes[entity["product_click"]["attributes_product_click"]["product_id_key"]] = id;
        attributes[entity["product_click"]["attributes_product_click"]["product_key"]] = medicineName;
        attributes[entity["product_click"]["attributes_product_click"]["price_key"]] = price;
        attributes[entity["product_click"]["attributes_product_click"]["is_top_deal_key"]] = isTopDeal;
        moengage.trackEvent(entity.product_click.event_name, attributes)
        router.push(`/medicine/${brand?.replaceAll(" ", "-").toLowerCase()}/${slug}`);
    }

    const getMedicine = ({ search, isInitialFatch = false }) => {
        searchMedicine(search, auth, limit, offset).then(response => {
            if (response?.status) {
                let userCategory = response?.data?.entity?.profile?.doctor_category;
                userCategory = userCategory ? userCategory : 0;
                const transformedProducts = getTransformedProductsData(response?.data?.entity?.list, userCategory);
                const newSearchList = transformedProducts.filter(medicine => {
                    if (medicine.mrp) return medicine
                }) || [];

                if (offset > 0) {
                    setSearchList([...searchList, ...newSearchList])
                    setOffset(isInitialFatch ? limit : offset + limit)
                } else {
                    setSearchList(newSearchList)
                }
            }
            isSetFetchSearch(false);
        });
    }

    const getBlog = ({ search, isInitialFatch = false }) => {
        getBlogsListing({ search, page: (offset / limit) + 1, featured: false })
            .then(response => {
                setSearchList([...searchList, ...response?.rows || []])
                isSetFetchSearch(false);
                setOffset(isInitialFatch ? limit : offset + limit);
            });
    }

    const getClinic = ({ search, isInitialFatch = false }) => {
        getSearchedClinic({ searchTrimmed: search, offset, limit }, auth).then(response => {
            setSearchList([...searchList, ...response?.data?.entity?.rows || []])
            isSetFetchSearch(false);
            setOffset(isInitialFatch ? limit : offset + limit)
        });
    }


    const getDoctor = ({ search, isInitialFatch = false }) => {
        getAllDoctors({ page: 1, limit, search: search })
            .then((response) => {
                setSearchList([...response?.entity?.rows || []])
                isSetFetchSearch(false);
                setOffset(isInitialFatch ? limit : offset + limit)
            });
    }

    const getListOfGlobalSearch = ({ searchType, search }) => {
        if (search == "") return;
        switch (searchType) {
            case 'medicine':
                getMedicine({ search })
                break;

            case 'blog':
                getBlog({ search })
                break;

            case 'clinic':
                getClinic({ search })
                break;

            case 'doctor':
                getDoctor({ search })
                break;
        }
    }

    useEffect(() => {
        if (auth?.user?.id) {
            listOfAddress()
        }
    }, [])

    useEffect(() => {
        if (auth?.user?.id)
            listOfAddress()
        reset()
    }, [isShowAddressModal])

    const listOfAddress = () => {
        const default_addr = addressList?.filter((items) => {
            return items.is_default === 1
        })
        if (default_addr?.length === 1) {
            setPincodeValue(`${default_addr[0]?.State?.name ?? ""} ${default_addr[0]?.pin_code}`)
        }
        else {
            setPincodeValue(auth?.user?.tbl_doctor_profile?.pincode)
        }
        if (auth?.user?.userType == 1 && pincodeValue && default_addr?.length === 0) {
            setAddPatientAddress(true)
        } else {
            setAddPatientAddress(false)
        }
    }

    useEffect(() => {
        listOfAddress()
    }, [addressList, pincodeValue])

    useEffect(() => {
        setIsVerifiedUser(!!getAuth())
    }, [setIsVerifiedUser, getAuth, router.pathname])

    useEffect(() => {
        let searchTrimmed = search.trim()
        setOffset(0);
        if (searchTrimmed == "") return
        let delayDebounceFn;
        if (searchType === 'medicine') {
            isSetFetchSearch(true);
            delayDebounceFn = setTimeout(() => {
                if (searchTrimmed !== '') setIsOpenSearch(true);
                getMedicine({ search: searchTrimmed, isInitialFatch: true })
            }, 800)
        } else if (searchType === 'blog') {
            delayDebounceFn = setTimeout(() => {
                if (searchTrimmed !== '') setIsOpenSearch(true);
                isSetFetchSearch(true);
                getBlog({ search, isInitialFatch: true })
            }, 800)
        } else if (searchType === 'clinic') {
            delayDebounceFn = setTimeout(() => {
                if (searchTrimmed !== '') setIsOpenSearch(true);
                isSetFetchSearch(true);
                getClinic({ search, isInitialFatch: true })
            }, 800)
        } else if (searchType === 'doctor') {
            delayDebounceFn = setTimeout(() => {
                if (searchTrimmed !== '') setIsOpenSearch(true);
                isSetFetchSearch(true);
                getDoctor({ search: searchTrimmed, isInitialFatch: true })
            }, 800)
        }
        return () => clearTimeout(delayDebounceFn)
    }, [search]);


    useEffect(() => {
        if (auth?.user.id) {
            getNotificationCount(auth?.user.id).then((data) => {
                if (isNotificationRead) {
                    setNotificationCount(0)
                } else {
                    setNotificationCount(data?.entity)
                }
            })
        }
    }, [isNotificationRead])

    useEffect(() => {
        if (!isShowSearch) {
            setOffset(0);
        }
    }, [isShowSearch])

    useEffect(() => {
        if (auth?.user?.userType == 1 && !pincodeValue) {
            setPatientIsModalOpen(true)
        }
        else {
            setPatientIsModalOpen(false)
        }
    }, [pincodeValue])
    useEffect(() => {
        const sourceOfUser = getSourceOfUser();

        if (sourceOfUser) {
            setReqAppointmentThankUModal({ isOpen: true, doctor: sourceOfUser?.doctor });
            removeSourceOfUser()
        }
    }, [])

    useEffect(() => {
        if (route === 'medicines') {
            setSearchType('medicine')
        }
    }, [])

    const isShowIcons = (width > 640 && router?.pathname !== '/' || width > 640 && router?.pathname == '/' || router?.pathname == '/medicines/allMedicines' || router?.pathname == '/medicine/[...slug]');

    return (
        <>
            <header className={`${className} flex bg-basic-white h-14 md:h-19 relative z-40 justify-center border-b border-gray-200`}>
                <Container type='static' className="px-5 flex justify-between flex-row items-center gap-3 xxs:gap-4">

                    <div className='flex flex-shrink-0 items-center'>

                        {<button onClick={() => setShowMenu(prev => ({ ...prev, show: true }))} className='mr-4.5 lg:hidden' data-automation='menu-open'>
                            <IconHamburger className="w-6 fill-gray-900" />
                        </button>}

                        <LinkAnchor href='/' className="flex items-center flex-shrink-0" dataAutomation='NirogStreet_Icon'>
                            <ImageNext className='select-none' priority src={nirogstreet} alt={'nirogstreet'} width={windowWidth > 768 ? 88 : 76} height={windowWidth > 768 ? 36 : 30} />
                        </LinkAnchor>

                        {(auth?.user?.userType == 1 && (router.pathname == "/" || router.pathname.includes("ayurveda")) || !auth) ? null :
                            <PincodeChange
                                uiFor='web'
                                onOpen={() => {
                                    setIsOpenPincodeModal(true)
                                    setShowAddress(true)
                                }}
                                currentPincode={pincodeValue}
                                isVerifiedUser={isVerifiedUser}
                            />
                        }
                    </div>

                    {
                        (router.asPath.includes("login") || router.asPath.includes("sign-up")) ? null :

                            ((router.pathname == "/" || router.asPath?.includes("ayurveda") || router.asPath?.includes("clinic") || router.asPath == "/login?type=individual") && (!auth || auth?.user?.userType == 1))
                                ?
                                (windowWidth >= 768 ?
                                    <IndividualsSearchSection
                                        className="hidden md:flex"
                                        search={props?.search}
                                        searchLocation={props?.searchLocation}
                                        type={type}
                                        onTriggerSearch={onTriggerSearch}
                                        ref={userRef}
                                    />
                                    :
                                    null)
                                :
                                <InputSearch className="hidden md:flex" placeholder={route === 'sign-up' ? 'Search' : `Search for ${searchType}`}
                                    searchText={search} onChange={setSearch} setSearchType={setSearchType} searchType={searchType}
                                    searchList={searchList} isShowSearch={isShowSearch} setIsOpenSearch={setIsOpenSearch} isFetchSearch={isFetchSearch}
                                    viewMedicineDetails={viewMedicineDetails} onSearchEnter={onSearchEnter}
                                    isShowSearchDropdown={true}
                                    dataAutomation='universal-search'
                                    getListOfGlobalSearch={getListOfGlobalSearch}
                                    ref={userRef}
                                    onBlur={() => {
                                        if (searchList.length > 0) saveUserSearch({ search: search }, auth?.user?.id)
                                    }}
                                    setSearchList={setSearchList} theme='universalSearch'
                                />

                    }

                    <div className='flex justify-center items-center lg:items-start gap-1 lg:gap-5 shrink-0'>
                        {auth && !isShowIcons && <PincodeChange
                            uiFor='mobile'
                            onOpen={() => setIsOpenPincodeModal(true)}
                            currentPincode={pincodeValue}
                            isVerifiedUser={true}
                        />}

                        {
                            auth && auth?.user?.userType !== 1 && (
                                route === 'feeds'
                                    ? <IconSearch className='md:hidden w-6 text-gray-900 hover:text-primary1-500'
                                        onClick={() => setIsMobSearch(true)} />
                                    : null
                            )
                        }

                        {isMobSearch && <div className='w-full h-full absolute left-0 top-0 px-3.5 sm:px-5 flex justify-center items-center bg-gray-50 rounded-2xl z-90'>
                            <div className='relative max-w-132.5 w-full'>
                                <InputSearch ref={searchMobRef} type='small' onChange={setSearch} className='w-full' inputClass='ml-5' placeholder="Search" searchText={search} />
                                <button className='absolute right-6 top-1/2 transform -translate-y-1/2' onClick={handlerHideSearch}>
                                    <IconClose
                                        className="w-5 fill-none stroke-gray-900 hover:stroke-primary1-500"
                                    />
                                </button>
                            </div>
                        </div>}

                        {auth && <LinkAnchor href={isVerifiedUser ? '/savedMedicines' : 'javascript:void(0)'} className={`${isVerifiedUser ? '' : 'iconLinks--disable'} group hidden md:block`} dataAutomation='notification'>
                            <Text className='flex flex-col items-center whitespace-nowrap iconLinks__text' type='caption' color='hover:text-primary1-500 text-gray-900'
                                onClick={() => handleAnalyticEventHandleHeaderNavigation({ router, eventName: analyticEntity?.Header_Navigation?.event_name?.Saved_Medicines })}>
                                <Badge type='primary' badgeContent={0}>
                                    <IconHeart className='sidebarIcon iconLinks__icon w-5.5 cursor-pointer' />
                                </Badge>
                                <span className='mt-0.5 hidden lg:block'>Saved Medicines</span>
                            </Text>
                        </LinkAnchor>}

                        {auth && isShowIcons && <LinkAnchor href={isVerifiedUser ? '/notifications' : 'javascript:void(0)'} className={`${isVerifiedUser ? '' : 'iconLinks--disable'} group`} dataAutomation='notification'>
                            <Text className='flex flex-col items-center whitespace-nowrap iconLinks__text' type='caption' color='hover:text-primary1-500 text-gray-900'
                                onClick={() => moengage.trackEvent(click_data_entity.notification_click.event_name, attributes_home)}>
                                <Badge type='primary' badgeContent={notificationCount} isLoading={isLoadingCart}>
                                    <IconNotification className='sidebarIcon iconLinks__icon w-6 cursor-pointer' />
                                </Badge>
                                <span className='mt-0.5 hidden lg:block'>Notification</span>
                            </Text>
                        </LinkAnchor>}

                        {windowWidth >= 1024 && route !== 'contact-us' && (!auth && router?.pathname !== '/' || (auth && router.pathname == '/')) && <LinkAnchor href={'/contact-us'} className={`group hidden md:block`} dataAutomation='need-help'>
                            <Text className='flex flex-col items-center whitespace-nowrap iconLinks__text' type='caption' color='hover:text-primary1-500 text-gray-900'>
                                <Badge type='primary' badgeContent={0}>
                                    <IconContactV2 className='sidebarIcon iconLinks__icon w-5.5 cursor-pointer nirog' />
                                </Badge>
                                <span className='mt-0.5 hidden lg:block'>Support</span>
                            </Text>
                        </LinkAnchor>}


                        <div className={`${auth ? '' : 'hidden sm:block'} shrink-0`}>
                            {windowWidth >= 1024 && auth && auth?.user && <LinkAnchor href={isVerifiedUser && windowWidth < 768 ? '/cart' : 'javascript:void(0)'} className={`${isVerifiedUser ? '' : 'iconLinks--disable'} group`} ref={anchorRef}
                                {...(isVerifiedUser)} onClick={() => {
                                    handleAnalyticEventHandleHeaderNavigation({ router, eventName: analyticEntity?.Header_Navigation?.event_name?.Cart_Button })
                                    setShowProfilePopup(false); setShowCartPopup(true); fetchCartDetail();
                                }} dataAutomation='my-cart'>

                                <Text className='flex flex-col items-center whitespace-nowrap iconLinks__text relative' type='caption' color='hover:text-primary1-500 text-gray-900'>
                                    <Badge type='primary' badgeContent={cartCount} isLoading={isLoadingCart}>
                                        <IconCart className='sidebarIcon iconLinks__icon w-6 cursor-pointer' />
                                    </Badge>
                                    <span className='mt-0.5 hidden lg:block'>Cart</span>
                                </Text>

                            </LinkAnchor>}

                            {windowWidth >= 768 && cartDetails && <EcommSearchMenu subClass='h-[304px] border-b border-gray-200' isShowSearch={showCartPopup} setIsOpenSearch={setShowCartPopup} type='pointer' title={'My cart'} align={'end'}
                                anchorRef={anchorRef} cartDetails={cartDetails?.length} onClick={handleProceedToCart} pointerLeftPosition={20} dataAutomation='my-cart' maxWidth={445} showAddMoreItemsToOrder={showAddMoreItemsToOrder} >
                                {cartDetails?.length === 0
                                    ? <div className='text-2xl text-gray-400 absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>Cart is Empty</div>
                                    : cartDetails?.map(({ cartId, product_name, brand_name, preview_img, price_info, quantity, package_price_id, discount_percent, discounted_price, list, brandName, medicineName, availableUnit, notifyMeQty, totalInStock, cartItemTotal }, index) => {
                                        // let totalPrice = priceCalculator(list, userType)
                                        return (
                                            isLoadingCart
                                                ? (
                                                    <>
                                                        {[...Array(cartDetails.length)].map((_, index) => (
                                                            <div className='flex justify-between content-end m-3' key={index}>
                                                                <div>
                                                                    <Skeleton width={156} height={25} active className='flex-shrink-0' />
                                                                    <Skeleton width={200} height={12} className='flex-shrink-0' />
                                                                </div>
                                                                <div className='text-right pr-3'>
                                                                    <Skeleton width={96} height={10} className='flex-shrink-0' />
                                                                    <Skeleton width={12} height={18} className='text-right mr-2' />
                                                                </div>

                                                            </div>
                                                        ))}
                                                    </>
                                                )
                                                : <MedicineShortSummery
                                                    type='removeType'
                                                    className="px-4 py-3 pr-6"
                                                    id={cartId}
                                                    discountPrice={cartItemTotal}
                                                    image={preview_img}
                                                    medicineName={medicineName}
                                                    brandName={brandName}
                                                    percentage={discount_percent !== null && discount_percent !== undefined ? discount_percent : price_info?.discount_percent}
                                                    // price={totalPrice}
                                                    size={price_info?.medicine_packaging_size}
                                                    quantity={quantity}
                                                    packagePriceId={package_price_id}
                                                    handlerMedicineRemove={removeItemFromCart}
                                                    totalCart={availableUnit}
                                                    notifyMeQuantity={notifyMeQty}
                                                    totalAvailableInventory={totalInStock}
                                                    packageMarginId={list[0]?.discount?.id}
                                                />
                                        )
                                    }

                                    )
                                }
                            </EcommSearchMenu>
                            }
                        </div>


                        {windowWidth >= 1024 && <div>
                            {auth && <LinkAnchor href={isVerifiedUser ? '/profile' : 'javascript:void(0)'} className={`${isVerifiedUser ? '' : 'iconLinks--disable'} group hidden lg:block`} ref={profileRef}
                                onMouseEnter={() => { setShowProfilePopup(true); setShowCartPopup(false); }} dataAutomation='profile'>

                                <Text className='flex flex-col items-center whitespace-nowrap iconLinks__text' type='caption' color='hover:text-primary1-500 text-gray-900' onClick={() => onProfileIconClick()}>
                                    {auth
                                        ? <Avatar className='w-8 h-8' src={profile_pic ? buckerUrl + profile_pic : auth?.user?.userType !== 1 ? '/images/icon_doctor.svg' : '/images/patient_profile.svg'} alt={auth?.user?.fullName} />
                                        : <IconProfile className='sidebarIcon iconLinks__icon w-6 cursor-pointer' />}
                                    <span className={`${isVerifiedUser ? 'mt-0.5' : 'mt-1.5'}`}>Profile</span>
                                </Text>
                            </LinkAnchor>}

                            {auth && showProfilePopup && <EcommSearchMenu className='rounded-2xl' subClass={` ${is_verified != 1 ? (auth?.user?.userType !== 1 ? 'max-h-[420px]' : 'max-h-[310px]') : (auth?.user?.userType !== 1 ? 'max-h-[440px] ' : 'max-h-[310px]')} min-h-[200px] h-[calc(100vh_-_100px)]`}
                                isShowSearch={showProfilePopup} setIsOpenSearch={setShowProfilePopup}
                                type='pointer' align={'end'} anchorRef={profileRef} pointerLeftPosition={12} maxWidth={242} >
                                <ul className='flex flex-col justify-between overflow-y-auto h-full customScrollbar '>

                                    {is_verified != 1 && is_verified != undefined && auth?.user?.userType !== 1 ? <li>
                                        <div className='px-6 mt-4 mb-1'>
                                            <LinkAnchor href='/profile-verification' dataAutomation='verify-profile'>
                                                <Button size="small" className={"w-full px-1"}>Verify your profile</Button>
                                            </LinkAnchor>
                                        </div>
                                    </li> : (null)}
                                    <li className={`${route === 'profile' ? 'activeSidebar' : ''} h-full items-center flex min-h-11`} >
                                        <LinkAnchor href='/profile' className="flex items-center px-6 py-0 w-full h-full group" dataAutomation='my-profile'>
                                            <TextIcon
                                                className={`gap-4.5 group-hover:text-primary1-500 leading-6.5`}
                                                direction={'LEFT'}
                                                fontWeight={600}
                                                type={"bodySmall"}
                                                onClick={() => moengage.trackEvent(click_data_entity.my_profile_click.event_name, attributes_profile)}
                                                icon={<IconProfile className='shrink-0 sidebarIcon' size={20} />}
                                            >
                                                My Profile
                                            </TextIcon>
                                        </LinkAnchor>
                                    </li>

                                    {
                                        auth && auth?.user?.userType !== 1 ? <>
                                            <li className={`${route === 'myorders' ? 'activeSidebar' : ''} h-full items-center flex min-h-10`} >
                                                <LinkAnchor href='/myorders' className="flex items-center px-6.5 py-0 w-full h-full group" dataAutomation='my-order'>
                                                    <TextIcon
                                                        className={`gap-4.5 group-hover:text-primary1-500 leading-6.5`}
                                                        direction={'LEFT'}
                                                        fontWeight={600}
                                                        type={"bodySmall"}
                                                        onClick={() => moengage.trackEvent(click_data_entity.my_orders_click.event_name, attributes)}
                                                        icon={<IconCart className='shrink-0 sidebarIcon' size={20} />}
                                                    >
                                                        My Orders
                                                    </TextIcon>
                                                </LinkAnchor>
                                            </li>
                                            <li className={`${route === 'myMedicine' ? 'activeSidebar' : ''} h-full items-center flex min-h-10`} >
                                                <LinkAnchor href='/my-medicine-list' className="flex items-center px-7 py-0 w-full h-full group" dataAutomation='myMedicine'>
                                                    <TextIcon
                                                        className={`gap-4 group-hover:text-primary1-500 leading-9.5`}
                                                        direction={'LEFT'}
                                                        fontWeight={600}
                                                        type={"bodySmall"}
                                                        icon={<IconMyMedicine className='shrink-10' />}
                                                    >
                                                        My Medicine List
                                                    </TextIcon>
                                                </LinkAnchor>
                                            </li>
                                            {![USER_TYPES?.CHEMIST, USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER].includes(auth?.user?.userType) ?
                                                <li className={`${route === 'bookmarks' ? 'activeSidebar' : ''} h-full items-center flex min-h-11`} >
                                                    <LinkAnchor href='/bookmarks' className="flex items-center px-6 py-0 w-full h-full group" dataAutomation='bookmarks'>
                                                        <TextIcon
                                                            className={`gap-4.5 group-hover:text-primary1-500 leading-6.5`}
                                                            direction={'LEFT'}
                                                            fontWeight={600}
                                                            type={"bodySmall"}
                                                            onClick={() => moengage.trackEvent(click_data_entity.book_mark_click.event_name, attributes_profile)}
                                                            icon={<IconBookmark className='shrink-0 sidebarIcon' size={18} />}
                                                        >
                                                            Saved Posts
                                                        </TextIcon>
                                                    </LinkAnchor>
                                                </li> : null}

                                            <li className={`${route === 'nirogmoney' ? 'activeSidebar' : ''} h-full items-center flex min-h-11`} >
                                                <LinkAnchor href='/nirogmoney' className="flex items-center px-6 py-0 w-full h-full group" dataAutomation='nirog-money'>
                                                    <TextIcon
                                                        className={`gap-4.5 group-hover:text-primary1-500 leading-6.5`}
                                                        direction={'LEFT'}
                                                        fontWeight={600}
                                                        type={"bodySmall"}
                                                        onClick={() => moengage.trackEvent(click_data_entity.nirog_money_click.event_name, attributes_profile)}
                                                        icon={<IconDollar className='shrink-0 sidebarIcon sidebarIconFill' size={20} />}
                                                    >
                                                        Nirog Money
                                                    </TextIcon>
                                                </LinkAnchor>
                                            </li>

                                            {hasAccess(userAccess?.Referral?.referralAccess) ? <li className={`${route === 'referral' ? 'activeSidebar' : ''} h-full items-center flex min-h-11`} >
                                                <LinkAnchor href='/referral' className="flex items-center px-6 py-0 w-full h-full group" dataAutomation='my-profile'>
                                                    <TextIcon
                                                        className={`gap-4.5 group-hover:text-primary1-500 leading-6.5`}
                                                        direction={'LEFT'}
                                                        fontWeight={600}
                                                        type={"bodySmall"}
                                                        onClick={() => moengage.trackEvent(click_data_entity.my_profile_click.event_name, attributes_profile)}
                                                        icon={<IconReferralSidebar className='shrink-0 sidebarIcon' size={20} />}
                                                    >
                                                        Refer Your Friends
                                                    </TextIcon>
                                                </LinkAnchor>
                                            </li> : null}

                                            {/* {auth && auth?.user?.userType !== 6 ? (<li className={`${route === 'myclinic' ? 'activeSidebar' : ''} h-full items-center flex min-h-11`} >
                                        <LinkAnchor href={is_verified === 1 ? '/myclinic' : '/profile-verification'} className="flex items-center px-6 py-0 w-full h-full group" dataAutomation='my-clinic'>
                                            <TextIcon
                                                className={`gap-4.5 group-hover:text-primary1-500 leading-6.5`}
                                                direction={'LEFT'}
                                                fontWeight={600}
                                                onClick={() => moengage.trackEvent(click_data_entity.my_clinic_click.event_name, attributes_profile)}
                                                icon={<IconMyClinic className='shrink-0 sidebarIcon' size={24} />}
                                            >
                                                My Clinic
                                            </TextIcon>
                                        </LinkAnchor>
                                    </li>) : (null)
                                    } */}

                                            <li className={`${route === 'myAddress' ? 'activeSidebar' : ''} h-full items-center flex min-h-11`} >
                                                <LinkAnchor href='/myAddress' className="flex items-center px-6 py-0 w-full h-full group" dataAutomation='my-address'>
                                                    <TextIcon
                                                        className={`gap-4.5 group-hover:text-primary1-500 leading-6.5`}
                                                        direction={'LEFT'}
                                                        fontWeight={600}
                                                        type={"bodySmall"}
                                                        onClick={() => moengage.trackEvent(click_data_entity.my_address_click.event_name, attributes_profile)}
                                                        icon={<IconLocation className='shrink-0 sidebarIcon' size={20} />}
                                                    >
                                                        My Addresses
                                                    </TextIcon>
                                                </LinkAnchor>
                                            </li>

                                            <li className={`${route === 'bankdetails' ? 'activeSidebar' : ''} h-full items-center flex min-h-11`} >
                                                <LinkAnchor href='/bankdetails' className="flex items-center px-6 py-0 w-full h-full group" dataAutomation='bankdetails'>
                                                    <TextIcon
                                                        className={`gap-4.5 group-hover:text-primary1-500 leading-6.5`}
                                                        direction={'LEFT'}
                                                        fontWeight={600}
                                                        type={"bodySmall"}
                                                        onClick={() => moengage.trackEvent(click_data_entity.user_category_click.event_name, attributes_profile)}
                                                        icon={<IconBankDetail className='shrink-0 ' size={20} />}
                                                    >
                                                        Bank Details
                                                    </TextIcon>
                                                </LinkAnchor>
                                            </li>


                                            <li className={`${route === 'category' ? 'activeSidebar' : ''} h-full items-center flex min-h-11`} >
                                                <LinkAnchor href='/category' className="flex items-center px-6 py-0 w-full h-full group" dataAutomation='category'>
                                                    <TextIcon
                                                        className={`gap-4.5 group-hover:text-primary1-500 leading-6.5`}
                                                        direction={'LEFT'}
                                                        fontWeight={600}
                                                        type={"bodySmall"}
                                                        onClick={() => moengage.trackEvent(click_data_entity.user_category_click.event_name, attributes_profile)}
                                                        icon={<IconCategory className='shrink-0 sidebarIcon' size={20} />}
                                                    >
                                                        Category
                                                    </TextIcon>
                                                </LinkAnchor>
                                            </li>

                                            <li className={`${route === 'company' ? 'activeSidebar' : ''} h-full items-center flex min-h-11`} >
                                                <LinkAnchor href='/company' className="flex items-center px-6 py-0 w-full h-full group" dataAutomation='company'>
                                                    <TextIcon
                                                        className={`gap-4.5 group-hover:text-primary1-500 leading-6.5`}
                                                        direction={'LEFT'}
                                                        fontWeight={600}
                                                        type={"bodySmall"}
                                                        onClick={() => moengage.trackEvent(click_data_entity.company_click.event_name, attributes_profile)}
                                                        icon={<IconInformation className='shrink-0 sidebarIcon sidebarIconFill' size={18} />}
                                                    >
                                                        Company
                                                    </TextIcon>
                                                </LinkAnchor>
                                            </li>
                                        </> : <>

                                            <li className={`${route === 'my-appointments' ? 'activeSidebar' : ''} h-full items-center flex min-h-11`} >
                                                <LinkAnchor href='/my-appointments?page=1' className="flex items-center px-6 py-0 w-full h-full group" dataAutomation='my-appointments'>
                                                    <TextIcon
                                                        className={`gap-4.5 group-hover:text-primary1-500 leading-6.5`}
                                                        direction={'LEFT'}
                                                        fontWeight={600}
                                                        type={"bodySmall"}
                                                        onClick={() => moengage.trackEvent(click_data_entity.company_click.event_name, attributes_profile)}
                                                        icon={<IconCalenderTickV2 className='shrink-0 sidebarIcon sidebarIconFill' size={18} />}
                                                    >
                                                        My Appointments
                                                    </TextIcon>
                                                </LinkAnchor>
                                            </li>

                                            <li className={`${route === 'myorders' ? 'activeSidebar' : ''} h-full items-center flex min-h-11`} >
                                                <LinkAnchor href='/myorders' className="flex items-center px-6 py-0 w-full h-full group" dataAutomation='my-orders'>
                                                    <TextIcon
                                                        className={`gap-4.5 group-hover:text-primary1-500 leading-6.5`}
                                                        direction={'LEFT'}
                                                        fontWeight={600}
                                                        type={"bodySmall"}
                                                        onClick={() => moengage.trackEvent(click_data_entity.company_click.event_name, attributes_profile)}
                                                        icon={<IconCart className='shrink-0 sidebarIcon sidebarIconFill' size={18} />}
                                                    >
                                                        My Orders
                                                    </TextIcon>
                                                </LinkAnchor>
                                            </li>
                                            <li className={`${route === 'myAddress' ? 'activeSidebar' : ''} h-full items-center flex min-h-11`} >
                                                <LinkAnchor href='/myAddress' className="flex items-center px-6 py-0 w-full h-full group" dataAutomation='my-address'>
                                                    <TextIcon
                                                        className={`gap-4.5 group-hover:text-primary1-500 leading-6.5`}
                                                        direction={'LEFT'}
                                                        fontWeight={600}
                                                        type={"bodySmall"}
                                                        onClick={() => moengage.trackEvent(click_data_entity.my_address_click.event_name, attributes_profile)}
                                                        icon={<IconLocation className='shrink-0 sidebarIcon' size={20} />}
                                                    >
                                                        My Addresses
                                                    </TextIcon>
                                                </LinkAnchor>
                                            </li>
                                            <li className={`${route === 'bankdetails' ? 'activeSidebar' : ''} h-full items-center flex min-h-11`} >
                                                <LinkAnchor href='/bankdetails' className="flex items-center px-6 py-0 w-full h-full group" dataAutomation='bankdetails'>
                                                    <TextIcon
                                                        className={`gap-4.5 group-hover:text-primary1-500 leading-6.5`}
                                                        direction={'LEFT'}
                                                        fontWeight={600}
                                                        type={"bodySmall"}
                                                        onClick={() => moengage.trackEvent(click_data_entity.user_category_click.event_name, attributes_profile)}
                                                        icon={<IconBankDetail className='shrink-0 ' size={20} />}
                                                    >
                                                        Bank Details
                                                    </TextIcon>
                                                </LinkAnchor>
                                            </li>
                                        </>}
                                    <li className={`${route === '' ? 'activeSidebar' : ''} h-full items-center flex min-h-11`}>
                                        <Button variant='Link' href='' onClick={handlerLogout} className="flex items-center -ml-13 p-0 w-full text-gray-900 hover:text-stroke-primary1-500 h-full group" dataAutomation='logout'>
                                            <TextIcon
                                                className={`gap-4.5 group-hover:text-primary1-500 leading-6.5`}
                                                direction={'LEFT'}
                                                fontWeight={600}
                                                type={"bodySmall"}
                                                icon={<IconLogout className='shrink-0 sidebarIcon sidebarIconFill' size={20} />}
                                            >
                                                Logout
                                            </TextIcon>
                                        </Button>
                                    </li>

                                </ul>
                            </EcommSearchMenu>}
                        </div>}

                        {/* {auth && <UserCategoryBadge className='h-13.5 hidden xxs:flex items-center shrink-0' userCategory={userCategory} type="WITH_LABEL" />} */}


                        {<div className='flex items-center gap-6'>


                            {auth && auth?.user && windowWidth < 1024 && isShowIcons &&
                            <>
                                <LinkAnchor href={isVerifiedUser && windowWidth < 768 ? '/cart' : 'javascript:void(0)'} className={`${isVerifiedUser ? '' : 'iconLinks--disable'} group`} ref={anchorRef}
                                    {...(isVerifiedUser)} onClick={() => {
                                        setShowProfilePopup(false); setShowCartPopup(true); fetchCartDetail();
                                    }} dataAutomation='my-cart'>
                                    <Text className='flex flex-col items-center whitespace-nowrap iconLinks__text relative' type='caption' color='hover:text-primary1-500 text-gray-900'>
                                        <Badge type='primary' badgeContent={cartCount} isLoading={isLoadingCart}>
                                            <IconCart className='sidebarIcon iconLinks__icon w-6 cursor-pointer' />
                                        </Badge>
                                    </Text>
                                </LinkAnchor>

                                {auth && route !== 'contact-us' && <LinkAnchor href={'/contact-us'} className={`group md:hidden`} dataAutomation='need-help'>
                                    <Text className='flex flex-col items-center whitespace-nowrap iconLinks__text' type='caption' color='hover:text-primary1-500 text-gray-900'>
                                        <Badge type='primary' badgeContent={0}>
                                            <IconContactV2 className='sidebarIcon iconLinks__icon w-5.5 cursor-pointer nirog' />
                                        </Badge>
                                        <span className='mt-0.5 hidden lg:block'>Support</span>
                                    </Text>
                                </LinkAnchor>}
                            </>
                            }

                            {(route !== 'sign-up' && !auth && (!isPractitonerHomepage || width > 640))
                                && <LinkAnchor href={(route == 'practitioner') || (router.query.type == 'practitioner') ? '/login?type=practitioner' : '/login?type=individual'}>
                                    <Button className={'text-sm min-h-[36px] py-0 px-3 w-[78px]'} size="small" dataAutomation='login-signup'>Login</Button>
                                </LinkAnchor>
                            }

                        </div>}


                    </div>
                </Container>
            </header>

            {
                (!isMobileInnerPage && !router?.pathname == '/') &&
                <PincodeChange
                    uiFor='mobile'
                    onOpen={() => setIsOpenPincodeModal(true)}
                    currentPincode={pincodeValue}
                    isVerifiedUser={isVerifiedUser}
                />
            }

            <ModalForPincodeChange
                setPincodeValue={setPincodeValue}
                modalTitle={showBillingAddress ? "Choose Billing Address" : "Choose Shipping Address"}
            />

            {(showMenu.show || showMenu.isFirstRendered) && <MenuV2 showMenu={showMenu} setShowMenu={setShowMenu} setIsShowBookApptModal={setIsShowBookApptModal}/>}

            {reqAppointmentThankUModal?.isOpen && <AppointmentThankYouModal fullName={reqAppointmentThankUModal?.doctor?.fullNameSalutation} isModalOpen={reqAppointmentThankUModal?.isOpen} setIsModalOpen={(bool) => setReqAppointmentThankUModal({ ...reqAppointmentThankUModal, isOpen: bool, })} addPatientAddress={addPatientAddress} setAddPatientAddress={setAddPatientAddress} />}
            {patientIsModalOpen && <ModalForPatientUser isModalOpen={patientIsModalOpen} />}
            {(isShowBookApptModal && width < 768) && <MakeAppointmentListing section={"leadForm"} appointmentType={1} isHomePage={true} showModal={isShowBookApptModal} setIsShowBookApptModal={setIsShowBookApptModal}/>}
        </>
    )
}

export default HeaderBase
