import { Svg } from "atoms/SvgIcon";

const IconCash = ({ ...rest }) => {
    return <Svg viewBox="0 0 16 16" type="IconCash" fill="none" { ...rest } >
        {/* <g clip-path="url(#clip0_10362_21998)"> */}
            <path d="M15.5312 4.71879H14.9269L14.0782 1.32385C14.0139 1.06695 13.7496 0.914195 13.4949 0.986758L0.336437 4.73804C0.134344 4.79745 0 4.98338 0 5.18754V14.5625C0 14.8214 0.209875 15.0313 0.46875 15.0313H15.5312C15.7901 15.0313 16 14.8214 16 14.5625V5.18754C16 4.92863 15.7901 4.71879 15.5312 4.71879ZM13.9606 4.71879H3.82294L13.2862 2.0211L13.9606 4.71879ZM15.0625 14.0938H0.9375V5.65629H15.0625V14.0938Z" fill="#4B4B63"/>
            <path d="M10.8156 7.53128H11.9728C12.135 8.10287 12.5862 8.55409 13.1578 8.71631V11.0337C12.5862 11.1959 12.135 11.6472 11.9728 12.2188H10.8125C10.5536 12.2188 10.3438 12.4286 10.3438 12.6875C10.3438 12.9464 10.5536 13.1562 10.8125 13.1562H12.3766C12.6354 13.1562 12.8453 12.9464 12.8453 12.6875C12.8453 12.2567 13.1958 11.9062 13.6266 11.9062C13.8854 11.9062 14.0953 11.6964 14.0953 11.4375V8.3125C14.0953 8.05362 13.8854 7.84375 13.6266 7.84375C13.1958 7.84375 12.8453 7.49328 12.8453 7.0625C12.8453 6.80362 12.6354 6.59375 12.3766 6.59375H10.8156C10.5567 6.59375 10.3469 6.80362 10.3469 7.0625C10.3469 7.32138 10.5567 7.53128 10.8156 7.53128Z" fill="#4B4B63"/>
            <path d="M2.375 11.9063C2.80578 11.9063 3.15625 12.2567 3.15625 12.6875C3.15625 12.9464 3.36612 13.1563 3.625 13.1563H5.18594C5.44481 13.1563 5.65469 12.9464 5.65469 12.6875C5.65469 12.4287 5.44481 12.2188 5.18594 12.2188H4.02878C3.86656 11.6472 3.41534 11.196 2.84375 11.0337V8.71634C3.41534 8.55412 3.86656 8.1029 4.02878 7.53131H5.18594C5.44481 7.53131 5.65469 7.32144 5.65469 7.06256C5.65469 6.80369 5.44481 6.59381 5.18594 6.59381H3.625C3.36612 6.59381 3.15625 6.80369 3.15625 7.06256C3.15625 7.49334 2.80578 7.84381 2.375 7.84381C2.11612 7.84381 1.90625 8.05369 1.90625 8.31256V11.4376C1.90625 11.6964 2.11612 11.9063 2.375 11.9063Z" fill="#4B4B63"/>
            <path d="M7.99922 12.2172C9.29113 12.2172 10.3422 11.1662 10.3422 9.87428C10.3422 8.58237 9.29113 7.53131 7.99922 7.53131C6.70731 7.53131 5.65625 8.58234 5.65625 9.87428C5.65625 11.1662 6.70731 12.2172 7.99922 12.2172ZM7.99922 8.46881C8.77419 8.46881 9.40469 9.09931 9.40469 9.87428C9.40469 10.6492 8.77419 11.2797 7.99922 11.2797C7.22425 11.2797 6.59375 10.6492 6.59375 9.87428C6.59375 9.09931 7.22425 8.46881 7.99922 8.46881Z" fill="#4B4B63"/>
        {/* </g> */}
        {/* <defs>
            <clipPath id="clip0_10362_21998">
                <rect width="16" height="16" fill="white"/>
            </clipPath>
        </defs> */}
    </Svg>;
};

export default IconCash;