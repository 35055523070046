import React from "react";
import Text from "atoms/Text";

const ClinicServices = ({
    className = "",
    services,
    ...props
}) => {
    return (
        <div id="clinicServicesSection" className={`${className}`}>
            <Text className={`text-xs font-semibold text-gray-900 mb-2`}>
                Services
            </Text>
            <div
                id="clinicServices"
                className={`
                    flex
                    flex-wrap
                    max-h-14
                    overflow-hidden
                    w-fit lg:w-auto
                    gap-1 md:gap-2
                `}
            >
                {services?.map((service) => (
                    <div
                        id="clinicServiceTag"
                        className={`
                            text-xs
                            leading-4
                            text-center
                            text-gray-900
                            bg-gray-100
                            border
                            border-gray-200
                            font-normal
                            rounded-lg
                            h-6
                            p-1
                            line-clamp-1
                        `}
                    >
                        {service}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ClinicServices;