import React from 'react';
import Heading from '../../atoms/Heading';
import Text from '../../atoms/Text';
import Modal from '../../atoms/Modal';
import { useRouter } from 'next/router';

const ModalRewardsEarned = ({ isModalOpen, handleModalStateChange, rewards=[] }) => {
    const router = useRouter();

    return (
        isModalOpen && (
            <Modal
                className="px-5 pt-6 pb-6 md:max-w-[690px] w-full fixed"
                isModalOpen={isModalOpen}
                handlerModalClose={(event) => handleModalStateChange({ event })}
                dataAutomation="modal-rewards-earned"
                isShowCloseBtn
            >
                <div className="space-y-4">
                    {rewards.map((item, index) => (
                        <div key={index}>
                            <Heading type="h4" className="font-semibold text-gray-900 text-xs">
                                {item?.label}
                            </Heading>
                            <Text className="text-xs leading-4 text-gray-900">{item?.value}</Text>
                        </div>
                    ))}
                </div>
            </Modal>
        )
    );
};

export default ModalRewardsEarned;
