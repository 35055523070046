import Card from "atoms/Card";
import LinkAnchor from "atoms/LinkAnchor";
import IconSearch from "atoms/SvgIcons/IconSearch.svg";
import Text from "atoms/Text";
import React from "react";

const SearchTermCard = (props) => {
    const { className, name, href, type } = props;
    return (
        <LinkAnchor href={href} className="w-full">
            <Card type="square" className={`${className} flex justify-between items-center gap-4 shadow-none w-full bg-basic-white hover:bg-primary1-50 transition-regular cursor-pointer border-b border-gray-200 min-h-[50px]`}>
                <div className="flex gap-3 items-center">
                    <IconSearch className="w-4.5 h-4.5 flex-shrink-0" stroke="#9C9CAF" />
                    <Text type="body" className="text-gray-900 text-sm">{name}</Text>
                </div>
                {type?.length > 0 && <Text type="body" className="text-gray-400 text-[11px] uppercase">{type}</Text>}
            </Card>
        </LinkAnchor>
    );
};

export default SearchTermCard;