import Container from "atoms/Container";
import { useState } from "react";
import BuyMedicinePopup from "./BuyMedicinePopup";
import useWindowSize from "customHook/useWindowSize";
import { useRouter } from "next/router";
import { getAuth } from "services/identity.service";

const ExploreMore = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [width] = useWindowSize();
    const router = useRouter();
    const auth = getAuth();
    
    const handleVaidyaClick = () => {
        if (!auth) {
            router.push('/login-practitioner')
        }
        else if (auth?.user?.tbl_doctor_profile?.is_verified == 1) {
            window.location.href = process.env.NEXT_PUBLIC_VEDIC_TOOL_LINK
        }
    };

    const items = [
        {
            label: "Buy Medicines",
            icon: "/images/buy-medicines.webp",
            onClick: () => setIsPopupOpen(true),
            showOnMobileOnly: true,
        },
        {
            label: "Nirog Partner",
            icon: "/images/nirog-partner.webp",
            link: "/reseller",
            showOnMobileOnly: true,
        },
        {
            label: "Offers",
            icon: "/images/offers.webp",
            link: "/offers",
            showOnMobileOnly: true,
        },
        {
            label: "Clinical Discussions",
            icon: "/images/clinical-discussions.webp",
            link: "/feeds",
        },
        {
            label: "Webinars by Vaidyas",
            icon: "/images/webinar.webp",
            link: "/webinar",
        },
        {
            label: "Free Ayurveda Courses",
            icon: "/images/learning.webp",
            link: "/learning",
        },
        {
            label: "News & Blogs",
            icon: "/images/health-blogs.webp",
            link: "/blogs",
        },
        {
            label: "Quiz for Students",
            icon: "/images/quiz.webp",
            link: "/quizzes",
        },
        ...(auth
            ? [
                {
                    label: "Vaidya Tool",
                    icon: "/images/vaidya-tool.webp",
                    onClick: handleVaidyaClick,
                    showOnMobileOnly: true,
                },
                {
                    label: "Find Doctors",
                    icon: "/images/find-doctors.webp",
                    link: "/ayurveda/doctors",
                },
                {
                    label: "Find Clinics",
                    icon: "/images/find-clinics.webp",
                    link: "/ayurveda/clinics",
                },
            ]
            : []),
    ];

    return (
        <div className="md:py-8 py-6">
            <Container type="static" className="px-4">
                {(auth || width > 640) && auth?.user?.userType == 2 && (
                    <h2 className="text-lg md:text-lg.5 font-semibold text-gray-900 mb-3">Explore More</h2>
                )}
                <div className="grid grid-cols-4 lg:grid-cols-7 gap-2 md:gap-7">
                    {items.map((item, index) => (
                        <div
                            key={index}
                            onClick={item.onClick ? item.onClick : () => router.push(item.link)}
                            className={`cursor-pointer flex flex-col items-center justify-center py-2 md:px-5 md:py-6 bg-gray-100 hover:bg-gray-200 rounded-lg shadow transition duration-200 ${
                                item.showOnMobileOnly ? "block md:hidden" : "block"
                            }`}
                        >
                            <img
                                src={item.icon}
                                alt={item.label}
                                className="w-7.5 h-7.5 md:w-12 md:h-12 mb-2 md:mb-3.5"
                            />
                            <p className="text-xs md:text-sm text-gray-900 text-center px-1 md:px-0 leading-4">{item.label}</p>
                        </div>
                    ))}
                </div>
            </Container>

            {isPopupOpen && width < 640 && (
                <BuyMedicinePopup isOpen={isPopupOpen} setIsOpen={setIsPopupOpen} />
            )}
        </div>
    );
};

export default ExploreMore;
