const ApiUrl = process.env.NEXT_PUBLIC_API_URL;
const DyteUrl = process.env.NEXT_PUBLIC_DYTE_BASE_URL;
const PhpApiUrl = process.env.NEXT_PUBLIC_API_URL_PHP;

const UrlParamsReplace = (url, params = {}) => {
  let urlWithPrefix = `${ApiUrl}${url}`;
  if (params) {
    Object.keys(params).forEach(
      (key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key]))
    );
  }
  return urlWithPrefix;
};

const DyteUrlParamsReplace = (url, params = {}) => {
  let urlWithPrefix = `${DyteUrl}${url}`;
  if (params) {
    Object.keys(params).forEach(
      (key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key]))
    );
  }
  return urlWithPrefix;
};

const PhpUrlParamsReplace = (url, params = {}) => {
  let urlWithPrefix = `${PhpApiUrl}${url}`;
  if (params) {
    Object.keys(params).forEach(
      (key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key]))
    );
  }
  return urlWithPrefix;
};

const QaUrlParamsReplace = (url, params = {}) => {
  let urlWithPrefix = `https://stg3web.1veda.in/api/core${url}`;
  if (params) {
    Object.keys(params).forEach(
      (key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key]))
    );
  }
  return urlWithPrefix;
};
export const DR_REGISTRATION = (id) => UrlParamsReplace('/users/:id/dr-registration', id);
export const SEND_OTP_FOR_REG = () => UrlParamsReplace('/users/sendOtp/register');
export const SEND_OTP_FOR_UPDATE = () => UrlParamsReplace('/users/sendOtp/update');
export const VERIFY_OTP_MOBILE = () => UrlParamsReplace('/users/verifyOtp/mobile');
export const VERIFY_OTP_EMAIL = () => UrlParamsReplace('/users/verifyOtp/email');
export const creat_user_URL = () => UrlParamsReplace('/v1/users/otp/verify')
export const SEND_OTP_FOR_LOGIN = () => UrlParamsReplace('/users/sendOtp/mobile')
export const LOGIN_WITH_MOBILE_OTP = () => UrlParamsReplace('/users/login/mobile')
export const LOGIN_WITH_EMAIL_PASSWORD = () => UrlParamsReplace('/users/login/email/password')
export const LOGIN_WITH_MOBILE_PASSWORD = () => UrlParamsReplace('/users/login/mobile/password')
export const SEND_EMAIL_OTP = () => UrlParamsReplace('/users/sendOtp/email')
export const LOGIN_WITH_EMAIL_OTP = () => UrlParamsReplace('/users/login/email')
export const STUDENT_REGISTRATION = (id) => UrlParamsReplace('/users/:id/student-registration', id);
export const CHEMIST_REGISTRATION = (id) => UrlParamsReplace('/users/:id/chemist-registration', id);
export const RETAILER_REGISTRATION = (id) => UrlParamsReplace('/users/:id/retailer-registration', id);
export const DR_REGISTRATION_NODE = (id) => UrlParamsReplace('/users/:id/dr-registration', id);
export const USER_REGISTRATION_UPDATE_DELETE = (userId, id) => UrlParamsReplace('/users/:userId/registration/:id', { userId, id });
export const CHECK_IF_VERIFIED = (userId, userType) => UrlParamsReplace(`/v3/is_verified/:userId${userType ? '?userType=:userType' : ''}`, { userId, userType })
export const CHECK_IF_VERIFIED_REFRESH = (userId, lastUpdatedAt, userType, userRole) => UrlParamsReplace('/v3/is_verified/:userId?lastUpdatedAt=:lastUpdatedAt&userType=:userType&userRole=:userRole', { userId, lastUpdatedAt, userType, userRole })
export const GET_USER_ACCESS = (userId) => UrlParamsReplace('/v1/users/user-access/:userId', { userId })

export const GET_ALL_FEEDS_V1 = (skip, limit, search, userId, tagNames, sortingPostsByTrending, fetchingFeaturedPosts) => UrlParamsReplace('/v1/feeds/:userId?skip=:skip&limit=:limit&search=:search&tagNames=:tagNames&sortingPostsByTrending=:sortingPostsByTrending&fetchingFeaturedPosts=:fetchingFeaturedPosts', { skip, limit, search, userId, tagNames, sortingPostsByTrending, fetchingFeaturedPosts })
export const GET_ALL_FEEDS = (skip, limit, search, user_id) => UrlParamsReplace('/v0/feeds?skip=:skip&limit=:limit&search=:search&user_id=:user_id', { skip, limit, search, user_id })
export const GET_ALL_COMMUNITY_FEEDS = (skip, limit, search, user_id, community_id, post_type) => UrlParamsReplace('/feeds?skip=:skip&limit=:limit&search=:search&user_id=:user_id&community_id=:community_id&post_type=:post_type', { skip, limit, search, user_id, community_id, post_type })
export const GET_ALL_BOOKMARKS_FEEDS = (skip, limit, search, user_id) => UrlParamsReplace('/v0/bookmarks/feeds?skip=:skip&limit=:limit&user_id=:user_id&search=:search', { skip, limit, user_id, search })
export const GET_ALL_SAVED_MEDICINES = (userID, pageNo, pageSize, search) => PhpUrlParamsReplace(`/v0/medicinebookmark/medicine-bookmark?userID=:userID&pageNo=:pageNo&pageSize=:pageSize&search=${search ? ':search' : ''}`, { userID, pageNo, pageSize, search })
export const GET_FEED_BY_ID = (feed_id, user_id) => UrlParamsReplace('/feeds/:feed_id?user_id=:user_id', { feed_id, user_id })

export const CREATE_FEED_URL = () => UrlParamsReplace('/feeds')
export const UPDATE_FEED_URL = (feed_id) => UrlParamsReplace('/feeds/:feed_id/update', { feed_id })
export const DELETE_FEED_URL = (feed_id) => UrlParamsReplace('/feeds/:feed_id/delete', { feed_id })
export const CREATE_LIKE_URL = () => UrlParamsReplace('/likes')
export const CREATE_LIKE_ON_COMMENT_URL = (comment_id, user_id, feed_id) => UrlParamsReplace('/comments/:comment_id/like?user_id=:user_id&feed_id=:feed_id', { comment_id, user_id, feed_id })
export const CREATE_LIKE_ON_SUBCOMMENT_URL = (subcomment_id, user_id, feed_id) => UrlParamsReplace('/subcomments/:subcomment_id/like?user_id=:user_id&feed_id=:feed_id', { subcomment_id, user_id, feed_id })
export const DELETE_COMMENT_ON_FEED_URL = (feed_id, comment_id, user_id) => UrlParamsReplace('/feeds/:feed_id/comments/:comment_id/delete?user_id=:user_id', { feed_id, comment_id, user_id })
export const DELETE_SUBCOMMENT_ON_FEED_URL = (feed_id, comment_id, subcomment_id, user_id) => UrlParamsReplace('/feeds/:feed_id/comments/:comment_id/subcomment/:subcomment_id/delete?user_id=:user_id', { feed_id, comment_id, subcomment_id, user_id })
export const COMMENT_ON_FEEDS = (feedId, userId, postOwnerId) => UrlParamsReplace('/feeds/:feedId/users/:userId/comments?postOwnerId=:postOwnerId', { feedId, userId, postOwnerId })
export const GET_TRENDING_CATEGORIES = () => UrlParamsReplace("/feeds/categories/trending-categories");

export const USER_FOLLOWERS_LIST = (userId) => UrlParamsReplace('/followers/users/:userId', { userId });
export const USER_FOLLOWINGS_LIST = (userId) => UrlParamsReplace('/followings/users/:userId', { userId });
export const USER_FOLLOW_UNFOLLOW = (user_id, followed_id, type) => UrlParamsReplace('/feeds/:user_id/:followed_id/:type/followers', { user_id, followed_id, type })
export const GET_ALL_TAGS = () => UrlParamsReplace('/tags')
export const PERSONAL_AND_CONTACTS = () => PhpUrlParamsReplace('/v0/user/update-profile',)
export const PERSONAL_AND_CONTACTS_GET = () => PhpUrlParamsReplace('/v0/user/profile')
export const BOOKMARK_ON_FEEDS = (feedId, userId, postOwnerId) => UrlParamsReplace('/feeds/:feedId/users/:userId/bookmark?postOwnerId=:postOwnerId', { feedId, userId, postOwnerId })



export const GET_COMMENT_OF_FEED_URL = (feedId, user_id) => UrlParamsReplace('/feeds/:feedId/comments?user_id=:user_id', { feedId, user_id })
export const GET_ALL_COMMUNITY_LIST = () => PhpUrlParamsReplace('/v0/group/all-groups-new')


export const CREATE_SUB_COMMENT_ON_FEED = (feed_id, comment_id, postOwnerId) => UrlParamsReplace('/feeds/:feed_id/comments/:comment_id/sub_comments?postOwnerId=:postOwnerId', { feed_id, comment_id, postOwnerId })
export const GET_MY_COMMUNITY_LIST = () => PhpUrlParamsReplace('/v0/group/index')
export const JOIN_COMMUNITY = () => PhpUrlParamsReplace('/v0/group/invite')
export const GROUP_REQUEST = () => PhpUrlParamsReplace('/v0/group/private-community')
export const INVITATION_REQUEST = () => PhpUrlParamsReplace('/v0/group/invited-community')
export const ACCEPT_DECLINE_REQUEST = () => PhpUrlParamsReplace('/v0/group/accept-decline')
export const GET_ALL_QUIZ = (offset, limit, user_id, search, sortType) => UrlParamsReplace("/quizzes?offset=:offset&limit=:limit&user_id=:user_id&search=:search&sortType=:sortType", { offset, limit, user_id, search, sortType })
export const GET_QUIZ_INFO = (quizId, userId) => UrlParamsReplace('/quizzes/:quizId?user_id=:userId', { quizId, userId })
export const GET_QUIZ_QUES = (quizId) => UrlParamsReplace('/quizzes/:quizId/questions', { quizId })
export const GET_USER_SCORE_FOR_QUIZZES = (id) => UrlParamsReplace('/users/:id/quizResult', { id })
export const GET_QUIZ_ATTENDEES = (id) => UrlParamsReplace('/v1/quiz/participants/:id', { id })

export const GET_CATEGORY_FOR_USERS = (id, userType) => UrlParamsReplace('/nirogCategory/users/:id?userType=:userType', { id, userType });

export const MEMBER_LIST_URL = () => PhpUrlParamsReplace('/v0/group/search-users')
export const CREATE_COMMUNITY_URL = () => PhpUrlParamsReplace('/v0/group/create')
export const UPDATE_COMMUNITY_URL = () => PhpUrlParamsReplace('/v0/group/update')
export const LEAVE_COMMUNITY_URL = () => PhpUrlParamsReplace('/v0/group/accept-decline')



export const CREATE_EXPERIENCE = () => PhpUrlParamsReplace('/v0/user/experiences');
export const DELETE_EXPERIENCE = () => PhpUrlParamsReplace('/v0/user/delete-experience');

export const CREATE_AWARD = () => PhpUrlParamsReplace('/v0/user/awards');
export const DELETE_AWARD = () => PhpUrlParamsReplace('/v0/user/delete-award');

export const CREATE_MEMBERSHIP = () => PhpUrlParamsReplace('/v0/user/membership');
export const DELETE_MEMBERSHIP = () => PhpUrlParamsReplace('/v0/user/delete-membership');

export const SERVICES_AND_SPECIALIZATIONS = () => PhpUrlParamsReplace('/v0/user/services');
export const GET_USER_SPECIALIZATIONS = (userId) => UrlParamsReplace("/v1/user/specialization/:userId", { userId });

export const CREATE_EDUCATION = () => PhpUrlParamsReplace('/v0/user/education');
export const DELETE_EDUCATION = () => PhpUrlParamsReplace('/v0/user/delete-qualification');

export const CREATE_SERVICES = () => PhpUrlParamsReplace('/v0/user/services-specializations')
export const CREATE_SPECIALIZATION = () => PhpUrlParamsReplace('/v0/user/services-specializations')

export const INVITE_USERS_LIST = () => PhpUrlParamsReplace('/v0/group/invite-user-list')
export const INVITE_MEMBERS_URL = () => PhpUrlParamsReplace('/v0/group/invite-members')
export const ATTEMPT_QUIZ = ({ quizId, queId }) => UrlParamsReplace('/quizzes/:quizId/questions/:queId/submitAnswer', { quizId, queId })
export const FINAL_SUBMIT_QUIZ = ({ userId, quizId }) => UrlParamsReplace('/users/:userId/quizzes/:quizId/submitResult', { userId, quizId })
export const CAPTURE_USER_QUIZ_INTEREST = () => UrlParamsReplace('/v1/quiz/interestedUser')

// E-comm module endpoints
export const PRODUCT_LISTING_URL = () => PhpUrlParamsReplace("/v0/medicine/listing");
export const PRODUCT_LISTING_BU_ID = () => PhpUrlParamsReplace("/v0/medicine/medicine-detail");
export const CREATE_NOTIFY_ME = (userId) => UrlParamsReplace("/v2/user/:userId/notify_me", { userId });
export const ADD_TO_CART = () => UrlParamsReplace("/v0/draft-cart");
export const REMOVE_FROM_CART = (id) => UrlParamsReplace("/v2/cart/:id/delete", { id });
export const UPDATE_CART = () => UrlParamsReplace("/v2/cart");
export const CHECK_AVAILIBLITY = () => PhpUrlParamsReplace("/v0/medicine/check-availability");
// export const BOOKMARK = () => PhpUrlParamsReplace("/v0/medicinebookmark/medicine-bookmark");

// Homepage modules
export const GET_DOCTORS_LIST = () => UrlParamsReplace("/v0/doctors");
export const GET_TRUSTED_DOCTORS_LIST = ({ page, limit, city, search, like, type }) => UrlParamsReplace('/v1/doctors?page=:page&limit=:limit&city=:city&search=:search&like=:like&type=:type&isCurated=1', { page, limit, city, search, like, type });
export const GET_SHORTLISTED_FEEDS_LIST = () => UrlParamsReplace("/v0/shortlist/feeds");
export const GET_FOLLOWEE_LIST_BY_FOLLOWER_ID = (followerID) => UrlParamsReplace("/v0/followee?followerID=:followerID", { followerID });

// E-comm module node endpoints
export const PRODUCT_LISTING_BY_ID_NODE = (slug, userID) => UrlParamsReplace("/v0/products/:slug?userID=:userID", { slug, userID });
export const PRODUCT_LISTING_BY_ID_HOME = (slug) => UrlParamsReplace("/v1/products/:slug", { slug });
export const GET_MEDICINE_LIST_BY_ID = (skip, limit, search, brand_filter, category_filter, is_bookmark, user_type, is_deal, userId, id, banner_product_list_id, banner_product_list_type, is_reseller = false, categoryLevelOneFilter, categoryLevelThreeFilter, doctorId, isOTC, isTopSellingMedicines = false) => UrlParamsReplace(`/v3/products?id=:id&skip=:skip&limit=:limit&userId=:userId&is_bookmark=:is_bookmark&is_deal=:is_deal&user_type=:user_type&brand_filter=:brand_filter&category_filter=:category_filter&search=:search&banner_product_list_type=:banner_product_list_type&banner_product_list_id=:banner_product_list_id&is_reseller=:is_reseller&categoryLevelOneFilter=:categoryLevelOneFilter&categoryLevelThreeFilter=:categoryLevelThreeFilter&doctorId=:doctorId&platform=web${isTopSellingMedicines ? `&isTopSellingMedicines=:isTopSellingMedicines`:''}&${isOTC == 0 || isOTC == 1 ? 'isOTC=:isOTC' : ''}`, { skip, limit, search, brand_filter, category_filter, is_bookmark, user_type, is_deal, userId, id, banner_product_list_type, banner_product_list_id, is_reseller, categoryLevelOneFilter, categoryLevelThreeFilter, doctorId, isTopSellingMedicines, isOTC });
export const PRODUCT_CATEGORY_URL_NODE = () => UrlParamsReplace("/v1/products/category");
export const PRODUCT_CATEGORY_URL_WITHOUT_AUTH = () => UrlParamsReplace("/v1/products/category");
export const GET_PRODUCT_INVENTORYV3 = (packageId, userId, isReseller = false, packagingId, productListId, memberPatientId, doctorId='') => UrlParamsReplace("/v3/products/:packageId/inventory?userId=:userId&reseller=:isReseller&packagingId=:packagingId&productListId=:productListId&memberPatientId=:memberPatientId&doctorId=:doctorId&platform=web", { packageId, userId, isReseller, packagingId, productListId, memberPatientId, doctorId });
export const GET_PRODUCT_INVENTORY = (packageId, userId, isReseller = false, packagingId, productListId, memberPatientId, doctorId) => UrlParamsReplace("/v2/products/:packageId/inventory?userId=:userId&reseller=:isReseller&packagingId=:packagingId&productListId=:productListId&memberPatientId=:memberPatientId&doctorId=:doctorId", { packageId, userId, isReseller, packagingId, productListId, memberPatientId, doctorId });
export const TRENDING_BRANDS = (limit, skip) => UrlParamsReplace("/v1/products/brands/featured?skip=:skip&limit=:limit", { skip, limit })
export const BOOKMARK = () => UrlParamsReplace("/v0/medicinebookmark/medicine-bookmark");

// Global search
export const GET_SEARCHED_BLOG = (search, offset, limit) => UrlParamsReplace("/v0/search/blogs?search=:search&&offset=:offset&&limit=:limit", { search, offset, limit });
export const GET_SEARCHED_CLINIC = (search, offset, limit) => UrlParamsReplace("/v0/search/clinics?search=:search&&offset=:offset&&limit=:limit", { search, offset, limit });
export const GET_SEARCHED_DOCTOR = (search, offset, limit) => UrlParamsReplace("/v0/search/doctors?search=:search&&offset=:offset&&limit=:limit", { search, offset, limit });


export const PRODUCT_CATEGORY_URL = () => PhpUrlParamsReplace("/v0/medicine/category-listing");
export const DELETE_BOOKMARK = (userID, package_id) => UrlParamsReplace("/v0/medicinebookmark/medicine-bookmark?userID=:userID&package_id=:package_id", { userID, package_id });

export const CART_DETAILS_URL = (userID, appId, get_cart_detail) => UrlParamsReplace("/cartDetails/:userID/:appId/:get_cart_detail", { userID, appId, get_cart_detail });
export const PAYMENT_DETAILS = () => UrlParamsReplace("/v2/orders/billing")
export const PLACE_ORDER = () => UrlParamsReplace('/v0/orders');
export const PLACE_ORDER_V1 = () => UrlParamsReplace('/v3/orders');
export const CART_COUNT = (userID) => UrlParamsReplace('/v2/cart/:userID/count', { userID });
export const REQUEST_RETURN = () => UrlParamsReplace('/v0/orders/return');
export const CREATE_RETURN_REQUEST = () => UrlParamsReplace('/v1/orders/return');

export const APPLY_COUPON = () => PhpUrlParamsReplace("/v0/medicine/apply-coupon")
export const GET_ORDER_LIST_V1 = (user_id) => UrlParamsReplace("/v1/users/:user_id/orders", { user_id });
export const GET_ORDER_LIST = () => UrlParamsReplace("/v0/order-listing");
export const GET_ORDER_DETAILS = () => PhpUrlParamsReplace("/v0/medicine/order-summary");
export const GET_ORDER_DETAILS_ACTIVE = (userId, orderId, reseller) => UrlParamsReplace("/v3/users/:userId/order/:orderId?reseller=:reseller", { userId, orderId, reseller });
export const ORDER_ADVANCE_CART = () => PhpUrlParamsReplace("v0/medicine/advance-cart");
export const GET_ORDER_TRACK_REQUEST = (hashedId) => UrlParamsReplace("/v3/order/:hashedId/track", { hashedId });
export const GET_ORDER_INVOICE_REQUEST = (hashedId) => UrlParamsReplace('/v1/order/:hashedId/invoice', { hashedId });

export const GET_NEW_ORDER_INVOICE = ({ user_id, order_id, isAppointment }) => UrlParamsReplace(`/v1/users/:user_id/orders/:order_id/invoice?isAppointment=:isAppointment`, { user_id, order_id, isAppointment });

export const COMMUNITY_DETAILS = () => PhpUrlParamsReplace("/v0/group/detail");

export const RETURN_ORDER_REQUEST = () => PhpUrlParamsReplace("/v0/medicine/return-request");
export const GET_USER_BANK_ACCOUNTS = (userId) => UrlParamsReplace("/v0/users/:userId/paymentOptions", { userId });
export const ADD_USER_BANK_ACCOUNT = (userId) => UrlParamsReplace("/v0/users/:userId/paymentOptions", { userId });
export const UPDATE_USER_PAYMENT_OPTION = (userId, id) => UrlParamsReplace("/v0/users/:userId/paymentOptions/:id", { userId, id })
// export const REFUND_ORDER_REQUEST = () => PhpUrlParamsReplace("/v0/medicine/refund-request");
export const REFUND_ORDER_REQUEST = (isOnlineStatus) => UrlParamsReplace("/orders/refund?isOnline=:isOnlineStatus", { isOnlineStatus });
export const UPDATE_REFUND_REQUEST = (orderId) => UrlParamsReplace("/orders/:orderId/refund", { orderId });

export const GET_ALL_NIROGMONEY_BY_USER_ID = ({ userId, search, pageNo, pageSize }) => UrlParamsReplace(`/nirogmoney/nirogmoney?userID=:userId&pageNo=:pageNo&pageSize=:pageSize&search=${search ? ':search' : ''}`, { userId, pageNo, pageSize, search });
export const ADD_NIROG_MONEY = () => UrlParamsReplace("/v1/nirogmoney");
export const GET_NIROG_MONEY_TERMSCONDITIONS = () => UrlParamsReplace("/termscondition/termsconditions");

export const GET_DYNAMIC_LINK = () => UrlParamsReplace('/dynamicLink');
export const GET_DYNAMIC_LINK_V1 = () => UrlParamsReplace("/v1/dynamicLink");
export const REMOVE_COUPON = () => PhpUrlParamsReplace("/v0/medicine/remove-coupon");
export const ADD_PUSH_NOTIFICATION = () => UrlParamsReplace('/v1/notification');
// export const GET_NOTIFICATIONS = ({ userId, pageNo, pageSize }) => PhpUrlParamsReplace('/v0/notification/notification?userID=:userId&pageNo=:pageNo&pageSize=:pageSize', { userId, pageNo, pageSize });


export const GET_WEBINAR_LIST = (userId) => UrlParamsReplace('/webinar/:userId', { userId });
export const MARK_WEBINAR_INTRESTED = () => UrlParamsReplace('/webinar');
export const GET_HOST_PRESENTER_WEBINAR = () => UrlParamsReplace('/webinar/host-presenter');

export const DYTE_END_PONT = (data) => DyteUrlParamsReplace('meetings/:meetingId/participants', data)
export const USER_SEARCH_FOR_MENTION = (user) => UrlParamsReplace('/users/2/:user', { user });

export const GET_PRE_SIGNED_URL = () => UrlParamsReplace("/aws/get-pre-signed-url");

export const GET_UPLOAD_PRE_SIGNED_URL = () => UrlParamsReplace("/aws/get-upload-pre-signed-url");
export const CHANGE_PASSWORD_EMAIL = () => UrlParamsReplace('/users/changePassword/email');
export const CHANGE_PASSWORD_MOBILE = () => UrlParamsReplace('/users/changePassword/mobile');

export const GET_USER_PROFILE_NODE = (userId , accordion) => UrlParamsReplace(`/v1/user/:userId/get-profile?${accordion ? 'accordion=:accordion' : ''}`, { userId , accordion});
export const GET_OTHER_USER_PROFILE_NODE = (userId) => UrlParamsReplace('/v1/user/:userId/get-profile/view', { userId });
export const UPDATE_USER_PERSONAL_CONTACT_INFO = () => UrlParamsReplace('/v1/user/update-personal-contact');
export const ADD_EDUCATION_NODE = () => UrlParamsReplace('/v1/user/add-education');
export const UPDATE_EDUCATION_NODE = () => UrlParamsReplace('/v1/user/update-education');
export const DELETE_EDUCATION_NODE = (data) => UrlParamsReplace('/user/:userId/delete-education/:id', data);
export const ADD_EXPERIENCE_NODE = () => UrlParamsReplace('/v1/user/add-experience');
export const UPDATE_EXPERIENCE_NODE = () => UrlParamsReplace('/v1/user/update-experience');
export const DELETE_EXPERIENCE_NODE = (data) => UrlParamsReplace('/user/:userId/delete-experience/:id', data);
export const GET_SERVICES_SPECILIZATION_LIST = (data) => UrlParamsReplace('/get-services-specialization/:type', data);
export const ADD_SERVICES_NODE = () => UrlParamsReplace('/v1/user/add-services');
export const DELETE_SERVICES_NODE = (data) => UrlParamsReplace('/user/:userId/delete-service/:serviceId', data);
export const ADD_SPECILIZATION_NODE = () => UrlParamsReplace('/v1/user/add-specializations');
export const DELETE_SPECIALIZATION_NODE = (data) => UrlParamsReplace('/user/:userId/delete-specialization/:specializationId', data);
export const ADD_AWARD_NODE = () => UrlParamsReplace('/v1/user/add-award');
export const UPDATE_AWARD_NODE = () => UrlParamsReplace('/v1/user/update-award');
export const DELETE_AWARD_NODE = (data) => UrlParamsReplace('/user/:userId/delete-award/:id', data);
export const ADD_MEMBERSHIP_NODE = () => UrlParamsReplace('/v1/user/add-membership');
export const UPDATE_MEMBERSHIP_NODE = () => UrlParamsReplace('/v1/user/update-membership');
export const DELETE_MEMBERSHIP_NODE = (data) => UrlParamsReplace('/user/:userId/delete-membership/:id', data);
export const GET_PINCODE_DATA = ({ pincode, forProfile }) => UrlParamsReplace(`/pincodes/:pincode${forProfile ? "?forProfile=:forProfile" : ""}`, { pincode, forProfile });
export const CHANGE_PUBLIC_VISIBILITY = () => UrlParamsReplace('/v1/doctor/visibility');
export const GET_SERVICEBLE_PINCODE = (pincode, channel) => UrlParamsReplace('/serviceable_pincodes/:pincode?channel=:channel', { pincode, channel });

export const GET_QUALIFICATION_REFERENCES = () => UrlParamsReplace('/user/qualification-references');
export const GET_UNIVERSITY_REFERENCES = () => UrlParamsReplace('/user/university-references');
export const GET_COUNCIL_REFERENCES = () => UrlParamsReplace('/user/council-references');

export const GET_ALL_FEED_LIKES = (skip, limit, feed_id) => UrlParamsReplace('/likes?skip=:skip&limit=:limit&feed_id=:feed_id', { skip, limit, feed_id })
export const VIEW_CART = (userId) => UrlParamsReplace('/v2/cart/:userId?platform=1', { userId });
export const NOTIFY_ME = (userId) => UrlParamsReplace('/v2/user/:userId/notify_me', { userId });
export const GET_COUPON_LIST = (userId, platform) => UrlParamsReplace("/v1/coupons/:userId?platform=:platform", { userId, platform });
export const CANCLE_ORDER = () => UrlParamsReplace("/v1/orders/cancel")

export const LIST_OF_STATES = (country_id) => UrlParamsReplace("/v0/state?country_id=:country_id", { country_id });
export const GET_ADDRESS_URL = (userID) => UrlParamsReplace("/v1/address?user_id=:userID", { userID });
export const GET_RESELLER_ADDRESS_URL = (userID, patientId) => UrlParamsReplace("/v1/address?user_id=:userID&patient_id=:patientId", { userID, patientId });
export const ADD_ADDRESS_URL = () => UrlParamsReplace("/v1/address")
export const UPDATE_ADDRESS_URL = (address_id) => UrlParamsReplace("/v1/address/:address_id", { address_id });
export const DELETE_MYADDRESS = (address_id) => UrlParamsReplace("/v0/address/:address_id", { address_id });
export const GET_PATIENT_ADDRESS = (userID, patientId) => UrlParamsReplace("/v1/address?user_id=:userID&patient_id=:patientId", { userID, patientId });
export const ADD_PATIENT_ADDRESS_URL = (userId, patientId) => UrlParamsReplace("/v1/address?user_id=:userId&patient_id=:patientId", { userId, patientId })
export const DELETE_PATIENT_ADDRESS = (address_id, userId, patientId) => UrlParamsReplace("/v0/address/:address_id?user_id=:userId&patient_id=:patientId", { address_id, userId, patientId });

export const USER_FOLLOW = () => UrlParamsReplace('/v0/follow');
export const USER_UNFOLLOW = () => UrlParamsReplace('/v0/unfollow');
export const BANNER_LIST_URL = (bannerType, userId, channel) => UrlParamsReplace("/v1/banner?bannerType=:bannerType&userId=:userId&channel=:channel", { bannerType, userId, channel });
export const BANNER_LIST_URL_WITH_PUB = (bannerType, userId, channel) => UrlParamsReplace("/pub/v1/banner?bannerType=:bannerType&userId=:userId&channel=:channel", { bannerType, userId, channel });

export const GET_RESELLER_DASHBOARD_INFO = (userId) => UrlParamsReplace('/v0/reseller/:userId', { userId });
export const RESELLER_BANK_DEATILS_KYC = () => UrlParamsReplace('/v0/reseller/kyc');
export const RESELLER_ORDER_LISTING = (userId, pageNumber, search, memberPatientId, appointmentId) => UrlParamsReplace('/v0/reseller/:doctorId/orders?page=:pageNo&search=:search&memberPatientId=:memberPatientId&appointmentId=:appointmentId', { doctorId: userId, pageNo: pageNumber, search, memberPatientId, appointmentId });
export const RESELLER_ORDER_DETAILS_BY_ID = (userId, orderId, isReseller) => UrlParamsReplace('/v3/users/:userId/order/:orderId?reseller=:isReseller', { userId, orderId, isReseller });
export const RESELLER_GET_PATIENT_ADDRESS_BY_MOBILE = (mobile, clinicId, doctorId) => UrlParamsReplace('/v0/reseller/:clinicId/patient/:mobile?doctorId=:doctorId', { mobile, clinicId, doctorId });
export const RESELLER_ADD_PATIENT_ADDRESS = () => UrlParamsReplace('/v0/reseller/patient');
export const GET_RESELLER_PATIENT_CART = (userId, memberPatientId, doctorId) => UrlParamsReplace('/v2/cart/:userId?platform=1&reseller=true&memberPatientId=:memberPatientId&doctorId=:doctorId', { userId, memberPatientId, doctorId });
export const RESELLER_ADD_TO_CART = () => UrlParamsReplace('/v0/reseller/draft-cart');
// export const RESELLER_PAYMENT_SUMMERY = (userId, doctorId) => UrlParamsReplace('/v1/reseller/paymentSummary/:user_id/:doctor_id', { user_id: userId, doctor_id: doctorId });
export const RESELLER_ADD_PRESCRIPTION = (appointment) => UrlParamsReplace('/v0/reseller/:appointment/prescription?viewType=web', { appointment });
export const RESELLER_CREATE_ORDER = () => UrlParamsReplace('/v3/orders');
export const RESELLER_UPDATE_DRAFT_CART = () => UrlParamsReplace('/v0/reseller/draft-cart');
export const RESELLER_DELETE_CART_ITEM = (id) => UrlParamsReplace('/v0/reseller/draft-cart/:id', { id });
export const DELETE_CART_ITEMS = (id) => UrlParamsReplace('/v2/cart/:id/delete', { id });
export const RESELLER_CREATE_SHAREABLE_LINK = () => UrlParamsReplace('/v0/reseller/patient/share');
export const RESELLER_GET_PAYMENT_SUMMERY_FROM_TOKEN = () => UrlParamsReplace('/v1/reseller/paymentsummary/patient');
export const RESELLER_VERFIY_PAYMENT_CODE = (code) => UrlParamsReplace('/v0/reseller/patient/:code', { code });
export const RESELLER_SEND_PAYMENT_LINK = () => UrlParamsReplace('/v0/reseller/patient/sendlink');
//  export const RESELLER_DRAFT_CART_LIST = () => PhpUrlParamsReplace('/v0/reseller-orders/reseller-draft-order-list');
export const RESELLER_GET_CLINIC_FOR_DOCTOR = (userId) => UrlParamsReplace('/v0/reseller/:doctorId/clinic', { doctorId: userId });
export const RESELLER_MARK_PATIENT_ADDRESS_DEFAULT = () => UrlParamsReplace('/v0/reseller/patient/address');
export const RESELLER_GET_DEFAULT_ADDRESS = (patientUserId, memberPatientId) => UrlParamsReplace('/v0/reseller/patient/:patientUserId/defaultAddress?memberPatientId=:memberPatientId', { patientUserId, memberPatientId });
export const RESELLER_UPDATE_WHOLE_CART = () => UrlParamsReplace('/v2/prescription');
export const RESELLER_GET_PRESCRIPTION_BY_ORDERID = (orderId) => UrlParamsReplace('/v0/reseller/:orderId/prescription?viewType=web', { orderId });
export const RESELLER_GET_PRESCRIPTION_BY_APPOINTMENT = (appointmentId, type) => UrlParamsReplace('/v0/reseller/prescription/:appointmentId?type=:type&viewType=web', { appointmentId, type });
export const RESELLER_GET_PRESCRIPTION_DATA_BY_APPOINTMENT = (appointmentId, type) => UrlParamsReplace('/v0/reseller/prescription/:appointmentId/data?type=:type', { appointmentId, type });

export const RESELLER_GET_PATIENTS = ({ userId, offset, limit, patientMobile, sortBy='', patientID='', search }) => UrlParamsReplace('/v2/patients/:userId?offset=:offset&limit=:limit&patientsMobile=:patientMobile&sortBy=:sortBy&patientId=:patientID&search=:search', { userId, offset, limit, patientMobile, sortBy, patientID, search })
export const RESELLER_ADD_PATIENT = () => UrlParamsReplace('/v2/patient');
export const RESELLER_GET_APPOINTMENT = ({ appointmentId }) => UrlParamsReplace('/v3/appointment/:appointmentId', { appointmentId });
export const CREATE_RESELLER_APPOINTMENT = () => UrlParamsReplace('/v3/appointment');
export const RESELLER_CHECK_ORDER_LIMIT = ({ mobile, doctorId, memberPatientId }) => UrlParamsReplace('/v1/patients/check-order-limit?mobile=:mobile&doctorId=:doctorId&memberPatientId=:memberPatientId', { mobile, doctorId, memberPatientId });
export const RESELLER_VERIFY_OTP_MOBILE = (mobile) => UrlParamsReplace('/v2/send-otp/:mobile', { mobile });


export const GET_SINGLE_OLYMPIAD = (userId) => UrlParamsReplace("/v1/olympiadByDate?userId=:userId", { userId });
export const GET_SINGLE_OLYMPIAD_LEADERBOARD = (olympiadId, userId) => UrlParamsReplace("/v1/olympiadByDate/leaderboard?olympiadId=:olympiadId&userId=:userId", { olympiadId, userId });
export const GET_ALL_OLYMPIAD_LEADERBOARD = (userId) => UrlParamsReplace("/v1/olympiad/leaderboard?userId=:userId", { userId });
export const SUBMIT_OLYMPIAD_RESULT = () => UrlParamsReplace("/v1/olympiad/result");
export const GET_ALL_QUESTIONS_FOR_OLYMPIAD = (olympiadId, userId) => UrlParamsReplace("/v0/olympiad/:olympiadId/questions?userId=:userId", { olympiadId, userId });
export const SUBMIT_ANSWER = () => UrlParamsReplace("/v0/olympiad/answer");
export const ADD_INTERESTED_USER = () => UrlParamsReplace('/v1/olympiad/user');
export const DOWNLOAD_OLYMPIAD_CERTIFICATE = (name) => UrlParamsReplace('/v0/olympiad/certificate?name=:name', { name });
export const GET_OLYMPIAD_BY_DATE = (userId) => UrlParamsReplace('/v1/olympiadByDate?userId=:userId', { userId });
export const GET_OLYMPIAD_DETAILS = (userId) => UrlParamsReplace('/v1/olympiadDetails?userId=:userId', { userId });
export const GET_OLYMPIAD_IS_ACTIVE = (olympiadId) => UrlParamsReplace('/v1/getActiveOlympiadByOlympiadId?olympiadId=:olympiadId', { olympiadId });

export const GET_ALL_FEEDS_BY_USERID = (skip, limit, search, userId, viewedBy) => UrlParamsReplace("/userFeeds?skip=:skip&limit=:limit&search=:search&user_id=:userId&viewedBy=:viewedBy", { skip, limit, search, userId, viewedBy });
export const GET_NOTIFICATIONS_COUNT = ({ userId }) => UrlParamsReplace('/v1/notification/:userId/count', { userId });
export const UPDATE_NOTIFICATION = (notificationId) => UrlParamsReplace("/v1/notification/read/:notificationId", { notificationId });
export const READ_NOTIFICATION = () => UrlParamsReplace("/v1/notification/read");
export const GET_NOTIFICATIONS = ({ userId, pageNo, pageSize }) => UrlParamsReplace('/v1/notification/:userId?skip=:pageNo&limit=:pageSize', { userId, pageNo, pageSize });
export const GET_ALL_PINNED_FEED = (userId) => UrlParamsReplace("/pinnedFeed?user_id=:userId", { userId });
export const GET_CHECK_NEW_FEED = (feedTime) => UrlParamsReplace("/feeds/latest?lastFeedTime=:feedTime", { feedTime });
export const GET_SIMILAR_USERS = ({ userId, limit }) => UrlParamsReplace("/v1/feed/similar-users/:userId?limit=:limit", { userId, limit });
export const GET_TRENDING_ENGAGEMENT_ACTIVITIES = ({ userId, limit, page }) => UrlParamsReplace("/v1/feed/trending-activities/:userId?limit=:limit&page=:page", { userId, limit, page });
export const GET_ALL_DOCTORS = ({ page, limit, city, search, like, type, pincode, isCurated, gender, experience, fee, specialities, sortBy, consultationType, languages, isFeaturedDoctors, isTopDoctor }) => UrlParamsReplace(`/v2/doctors?page=:page&limit=:limit&city=:city&search=:search&like=:like&type=:type${pincode ? "&pincode=:pincode" : ""}&isCurated=:isCurated&gender=:gender&experience=:experience&fee=:fee&specialities=:specialities&sortBy=:sortBy&consultationType=:consultationType&languages=:languages&isFeaturedDoctors=:isFeaturedDoctors&isTopDoctor=:isTopDoctor`, { page, limit, city, search, like, type, pincode, isCurated, gender, experience, fee, specialities, sortBy, consultationType, languages, isFeaturedDoctors, isTopDoctor });
export const GET_DOCTOR_DETAILS_BY_CITY_SLUG = ({ city, slug }) => UrlParamsReplace('/v1/doctor/:city/:slug', { city, slug });
export const GET_DOCTOR_CLINICS_TIMINGS = ({ doctorId }) => UrlParamsReplace('/v1/doctors/:doctorId/timings', { doctorId });
export const GET_DOCTORS_BLOGS = ({ doctorId, limit, page }) => UrlParamsReplace('/v1/doctors/:doctorId/blogs?limit=:limit&page=:page', { doctorId, limit, page });
export const GET_DOCTORS_VIDEOS = ({ doctorId, limit, page }) => UrlParamsReplace('/v1/doctors/:doctorId/videos?limit=:limit&page=:page', { doctorId, limit, page });
export const GET_DOCTORS_FAQS = ({ doctorId, limit, page, search }) => UrlParamsReplace('/v1/doctors/:doctorId/faqs?limit=:limit&page=:page&search=:search', { doctorId, limit, page, search });

export const GET_ALL_CLINICS = (skip, limit, locationSearch, clinicSearch, isSmartClinic) => UrlParamsReplace('/v0/clinics?skip=:skip&limit=:limit&locationSearch=:locationSearch&clinicSearch=:clinicSearch&isSmartClinic=:isSmartClinic', { skip, limit, locationSearch, clinicSearch, isSmartClinic });
export const GET_ALL_CLINICS_V1 = (skip, limit, clinicSearch, locationSearch, state, services, fee, clinicIds, clinicSlugs, isSmartClinic, gender,experience) => UrlParamsReplace('/v1/clinics?skip=:skip&limit=:limit&clinicSearch=:clinicSearch&locationSearch=:locationSearch&state=:state&services=:services&fee=:fee&clinicIds=:clinicIds&clinicSlugs=:clinicSlugs&isSmartClinic=:isSmartClinic&gender=:gender&experience=:experience', { skip, limit, clinicSearch, locationSearch, state, services, fee, clinicIds, clinicIds, clinicSlugs, isSmartClinic, gender, experience })
export const GET_CLINIC_DETAILS = (slug, city) => UrlParamsReplace('/v0/clinic/:city/:slug', { slug, city });
export const GET_CLINIC_DOCTORS = (id, skip, limit) => UrlParamsReplace('/v0/clinics/:id/doctors?skip=:skip&limit=:limit', { id, skip, limit });
export const GET_CLINIC_BLOGS = (id, skip, limit) => UrlParamsReplace('/v0/clinics/:id/blogs?skip=:skip&limit=:limit', { id, skip, limit });
export const GET_CLINIC_IMAGES = (id, skip, limit) => UrlParamsReplace('/v0/clinics/:id/images?skip=:skip&limit=:limit', { id, skip, limit });

export const GET_LMS_CATEGORY = (limit, offset, userId) => UrlParamsReplace('/v0/lms/category?limit=:limit&offset=:offset&userId=:userId', { limit, offset, userId });
export const GET_LMS_CATEGORY_DETAILS = (limit, offset, categoryId, userId) => UrlParamsReplace('/v0/lms/categoryDetails?limit=:limit&offset=:offset&categoryId=:categoryId&userId=:userId', { limit, offset, categoryId, userId });
export const GET_LMS_COURSE_DETAILS = (limit, offset, courseId, userId) => UrlParamsReplace('/v0/lms/courseDetails?limit=:limit&offset=:offset&courseId=:courseId&userId=:userId', { limit, offset, courseId, userId });
export const GET_LMS_TOPIC_DETAILS = (limit, offset, topicId, userId) => UrlParamsReplace('/v0/lms/topicDetails?limit=:limit&offset=:offset&topicId=:topicId&userId=:userId', { limit, offset, topicId, userId });
export const GET_LMS_TOPIC_VIDEO = (videoID, userId) => UrlParamsReplace('/v0/lms/videoDetails?videoID=:videoID&userId=:userId', { videoID, userId });
export const GET_CONTINUE_LECTURES = (limit, skip, userId) => UrlParamsReplace('/v0/lecture/continue?limit=:limit&skip=:skip&userId=:userId', { limit, skip, userId });
export const UPDATE_CATEGORY_LIKE = () => UrlParamsReplace('/v0/category/like');
export const UPDATE_LECTURE_VIEW = () => UrlParamsReplace('/v0/lecture/view');
export const GET_IS_RENDER_LMS = (serviceName) => UrlParamsReplace('/render?serviceName=:serviceName', { serviceName });
export const DOWNLOAD_CERTIFICATE = (context) => UrlParamsReplace('/v0/certificate/generate?context=:context', { context });
export const UPDATE_CATEGORY_VIEW = () => UrlParamsReplace('/v0/category/view');
export const GET_REFRESH_SLUG_TO_ID = (context) => UrlParamsReplace('/v0/refresh?context=:context', { context });
export const VALIDATEADDRESS = (gstno, userId) => UrlParamsReplace('/v0/address/gst/validate?gstno=:gstno&userId=:userId', { gstno, userId });

export const GET_PREVIOUS_ORDERS = (user_id) => UrlParamsReplace("/v1/users/:user_id/orders", { user_id });
export const ADD_TO_CART_V1 = (userId) => UrlParamsReplace("/v1/cart/:userId", { userId });
export const ADD_UPDATE_ALT_MOBILE_NO = (userId) => UrlParamsReplace('/v2/users/:userId/alt-mobile', { userId });

export const GET_LINK_PREVIEW = (url) => UrlParamsReplace('/v0/link/preview?url=:url', { url });

export const RESELLER_GET_CONSTANTS = () => UrlParamsReplace('/v0/reseller/constants');
export const DASHBOARD_GET_CONSTANTS = (type) => UrlParamsReplace('/dashboard/constants?type=:type', { type });
export const PENDING_PAYMENT_REMINDER = (userId) => UrlParamsReplace('/v1/users/:userId/outstanding-amount', { userId })
export const GET_PAYMENT_DETAILS = (hashedId) => UrlParamsReplace('/v1/orders/payment/:hashedId', { hashedId })
export const CREATE_POSTPAID_PAYMENT = (orderId) => UrlParamsReplace('/v1/orders/:orderId/postpaid', { orderId });
export const UPDATE_POSTPAID_PAYMENT = (orderId) => UrlParamsReplace('/v1/orders/:orderId/postpaid', { orderId });

export const GET_BLOG_BY_SLUG = (slug) => UrlParamsReplace('/v1/blog/:slug', { slug });
export const GET_BLOG_BY_SLUG_WITH_PUB = (slug) => UrlParamsReplace('/pub/v1/blog/:slug', { slug });
export const GET_ALL_BLOG_CATEGORIES = () => UrlParamsReplace('/v1/blogs/categories');
export const GET_ALL_BLOG_CATEGORIES_WITH_PUB = () => UrlParamsReplace('/pub/v1/blogs/categories');
export const GET_ALL_BLOGS_LISTING_VIA_ELASTIC = () => UrlParamsReplace('/v1/blogs/elastic');
export const GET_ALL_BLOGS_LISTING_VIA_ELASTIC_WITH_PUB = () => UrlParamsReplace('/pub/v1/blogs/elastic')

export const UPDATE_BLOG_LIKE_SHARE_COUNT = (blogId, action) => UrlParamsReplace('/v1/blogs/:blogId/:action/count', { blogId, action });
export const UPDATE_BLOG_LIKE_SHARE_COUNT_WITH_PUB = (blogId, action) => UrlParamsReplace('/pub/v1/blogs/:blogId/:action/count', { blogId, action });


export const GET_SIMILAR_DOCTOR_CLINIC = (id) => UrlParamsReplace('/v0/clinics/:id/doctors/similar', { id });

export const MAP_URL_FOR_SITEMAP = () => UrlParamsReplace('/v0/sitemap');

export const GET_MY_LEADS = ({ doctorId, offset, limit, search, sortType, patientMobile }) => UrlParamsReplace('/v1/leads?doctorId=:doctorId&offset=:offset&limit=:limit&search=:search&sortType=:sortType', { doctorId, offset, limit, search, sortType, patientMobile })
export const CREATE_PATIENT = () => UrlParamsReplace('/v0/reseller/patient')
export const GET_APPOINTMENT = ({ doctorId, patientUserId, clinicId, leadId, actionType }) => UrlParamsReplace('/v1/lead/appointment?doctorId=:doctorId&patientUserId=:patientUserId&clinicId=:clinicId&leadId=:leadId&actionType=:actionType', { doctorId, patientUserId, clinicId, leadId, actionType })
export const CREATE_LEAD_APPOINTMENT = () => UrlParamsReplace('/v1/lead/appointment')
export const UPDATE_LEAD_FOLLOW_UP_DATE = (appointmentId) => UrlParamsReplace('/v1/leads/:appointmentId/followup', { appointmentId });
export const CAPTURE_LEAD_NUTRITION = (appointmentId) => UrlParamsReplace('/v1/leads/:appointmentId/nutrition', { appointmentId });
export const CAPTURE_LEAD_STATUS = (mobile) => UrlParamsReplace('/v1/leads/:mobile/status', { mobile });
export const GET_LEAD_PATIENT_INFO = ({ doctorId, mobile, clinicId }) => UrlParamsReplace('/v1/lead/patient?doctorId=:doctorId&mobile=:mobile&clinicId=:clinicId', { doctorId, mobile, clinicId })
export const RESET_INFLIGHT_LEAD = () => UrlParamsReplace('/v1/leads/inflight/reset')
export const MARK_LEAD_INFLIGHT = () => UrlParamsReplace('/v1/leads/inflight')

export const CREATE_CLINIC_PROFILE_URL = () => UrlParamsReplace('/v1/createClinic');
export const UPDATE_CLINIC_PROFILE_URL = () => UrlParamsReplace('/v1/clinic/update');
export const CREATE_CLINIC_VIDEO_URL = (clinicId) => UrlParamsReplace('/v0/clinics/:clinicId/videos', { clinicId });
export const GET_CLINIC_VIDEO_URL = (clinic_profile_id) => UrlParamsReplace('/v1/clinicVideos/:clinic_profile_id', { clinic_profile_id });
export const DELETE_CLINIC_VIDEO_URL = () => UrlParamsReplace('/v1/clinic/deleteVideo');
export const GET_CLINIC_PROFILE_URL = (clinic_profile_id) => UrlParamsReplace('/v1/clinic/:clinic_profile_id', { clinic_profile_id });
export const GET_CLINIC_TIMING_URL = (clinic_profile_id) => UrlParamsReplace('/v1/getClinicTimings/:clinic_profile_id', { clinic_profile_id });
export const UPDATE_CLINIC_TIMING_URL = () => UrlParamsReplace('/v1/updateClinicTimings');
export const ADD_TO_PDP = () => UrlParamsReplace('/v2/cart/pdp');
export const GET_LEADS_DETAILS = (id) => UrlParamsReplace("/v1/leads/:id/details", { id })
export const RESELLER_DRAFT_CART_LIST = () => UrlParamsReplace('/v0/reseller-orders/reseller-draft-order-list');
export const SUGGEST_MEDICINE = () => UrlParamsReplace('/v1/product/suggestion')

export const OFFER_MEDICINES = (limit, skip, userID) => UrlParamsReplace("/v1/products/offer-discount?skip=0&limit=10&userId=:userID&search=", { skip, limit, userID })

export const DELETE_OFFER_MEDICINES = (userID) => UrlParamsReplace("/v1/cart/:userID/offer-discount", { userID });
export const ADD_OFFER_MEDICINES = (userID) => UrlParamsReplace("/v1/cart/:userID", { userID });

export const ADD_SALE_NOTIFICATION = () => UrlParamsReplace('/v1/saleNotioncation');
export const GET_SALE_NOTIFICATION = (mobile, saleLiveDay) => UrlParamsReplace('/v1/saleNotioncation/:mobile/:saleLiveDay', { mobile, saleLiveDay });
export const SAVE_USER_SEARCH = (userID) => UrlParamsReplace('/v1/users/:userID/medicineSearch', { userID })
export const GET_DEAL_DATA = (slug) => UrlParamsReplace('/v0/deal/?slug=:slug', { slug })
export const GET_DEAL_DATA_WITH_PUB = (slug) => UrlParamsReplace('/pub/v0/deal/?slug=:slug', { slug })
export const GET_USER_SEARCH = (userID) => UrlParamsReplace('/v1/users/:userID/medicineSearch', { userID })
export const UPDATE_USER_SEARCH_TERM_STATUS = (userId) => UrlParamsReplace('/v1/users/:userId/medicineSearch', { userId })

export const GET_TRENDING_MEDICINES = (skip, limit, userId, personalisationType) => UrlParamsReplace(`/v3/personalised-products?skip=${skip}&limit=${limit}&userId=${userId}&personalisationType=${personalisationType}`)

export const GET_REFFERAL_CONTENT = (userId) => UrlParamsReplace("/v1/users/referral/:userId", { userId });
export const GET_INTERESTED_OLYMPIAD_USER = () => UrlParamsReplace('/v1/get-olympiad-interested-user');
export const VERIFY_OTP_OLYMPIAD_USER = () => UrlParamsReplace('/v1/olympiad-lead/verify');
export const SEND_OTP_OLYMPIAD_USER = () => UrlParamsReplace('/v1/olympiad-lead-otp');
export const GET_OFFER_CONFIG = () => UrlParamsReplace('/v0/offer')
export const GET_OFFER_CONFIG_WITH_PUB = () => UrlParamsReplace('/pub/v0/offer')


export const CREATE_ONLINE_PAYMENT = () => UrlParamsReplace('/v2/orders/payment/online');
export const UPDATE_ONLINE_PAYMENT = () => UrlParamsReplace('/v2/orders/payment/online');
export const GET_DELIVERED_ORDERS_OUTSTANDING = (userId) => UrlParamsReplace(`/v2/orders/outstanding/${userId}`);
export const MY_MEDICINE_LIST = (userId, skip, limit) => UrlParamsReplace(`/v3/products/my-medicines/${userId}?skip=${skip}&limit=${limit}&platform=web`)

export const GET_LEAD_CITIES = () => UrlParamsReplace("/v0/leads/cities");
export const CREATE_LEAD = () => UrlParamsReplace("/v1/appointment/request/otp");
export const VERIFY_LEAD_OTP = () => UrlParamsReplace('/v2/appointment/request/verify');
export const RESEND_LEAD_OTP = ({ queryLeadId }) => UrlParamsReplace(`/v1/leads/${queryLeadId}/resend`);
export const GET_INDIVIDUAL_REQUEST_APPOINTMENT = ({ offset, limit, search, sortType, patientMobile }) => UrlParamsReplace('/v1/leads?offset=:offset&limit=:limit&search=:search&sortType=:sortType&patientMobile=:patientMobile', { offset, limit, search, sortType, patientMobile })
export const GET_INDIVIDUAL_ORDER_LISTING = ({ userId }) => UrlParamsReplace(`/v1/individual/${userId}/orders?limit=10`, { userId });

export const GET_SIMILAR_MEDICINE = (productId, userId, skip, limit, reseller, doctorId) => UrlParamsReplace(`/v2/products/${productId}/similar?userId=${userId}&skip=${skip}&limit=${limit}&orderBy=avgPurchaseMargin&reseller=${reseller}&doctorId=${doctorId}`)
export const RESELLER_CART_COUNT = (userID, memberPatientId, doctorId) => UrlParamsReplace('/v2/cart/:userID/count?reseller=true&memberPatientId=:memberPatientId&doctorId=:doctorId', { userID, memberPatientId, doctorId });
export const GET_RESELLER_ADD_PRESCRIPTION = (appointment) => UrlParamsReplace('/v2/cart/:appointment/prescription', { appointment });
export const FEEDBACK_ANSWER = () => UrlParamsReplace(`/v0/form/answer`);
export const GET_MODULE_QUESTION = (moduleId, channel, userId, entityId, formType, formId) => UrlParamsReplace(`/v1/forms-questions-module/${userId}?moduleId=${moduleId}&channel=${channel}&entityId=${entityId}&formType=${formType}&formId=${formId}`)
export const CREATE_ORDER_LEAD_APPOINTMENT = () => UrlParamsReplace("/v3/orders/appointment");
export const SELL_BUSINESS_OTP = () => UrlParamsReplace(`/v0/business/send-otp`);
export const SELL_BUSINESS_OTP_WITH_PUB = () => UrlParamsReplace(`/pub/v0/business/send-otp`);
export const SELL_BUSINESS_QUERY = () => UrlParamsReplace(`/v0/business/query`);
export const SELL_BUSINESS_QUERY_WITH_PUB = () => UrlParamsReplace(`/pub/v0/business/query`);


export const MARGIN_CALCULATION = () => UrlParamsReplace("/v2/cart/reseller/calculate-margin")

export const ADD_TO_CART_V2 = (userId) => UrlParamsReplace("/v2/cart/:userId", { userId });
export const VALIDATE_AND_GET_FILEKEY = () => UrlParamsReplace('/s3/validate-and-get-file-key');
export const GET_MICROSITE_DATA = (slug) => UrlParamsReplace("/v0/microsite/:slug", { slug })
export const GET_DELIVERY_TIME = (pincode, channel) => UrlParamsReplace("/v2/deliverytime?pinCode=:pincode&channel=:channel", { pincode, channel })
export const GET_ORDER_LIST_V3 = ({ userId, skip, limit, search, reseller, orderBy, isNewOrder, appointmentId, memberPatientId }) => UrlParamsReplace('/v3/orders?userId=:userId&skip=:skip&limit=:limit&search=:search&reseller=:reseller&orderBy=:orderBy&isNewOrder=:isNewOrder&appointmentId=:appointmentId&memberPatientId=:memberPatientId', { userId, skip, limit, search, reseller, orderBy, isNewOrder, appointmentId, memberPatientId })
export const ADD_CLINIC_SERVICES = (data) => UrlParamsReplace('/v1/clinic/service-specializations', { data });
export const DELETE_CLINIC_SERVICES = (clinicId, serviceId) => UrlParamsReplace('/v1/clinic/:clinicId/delete-service/:serviceId', { clinicId, serviceId });
export const GET_APPOINTMENT_REQUEST_DETAILS = ({ leadId, doctorId, userPatientId }) => UrlParamsReplace("/v2/appointment-request/:leadId/details?doctorId=:doctorId&userPatientId=:userPatientId", { leadId, doctorId, userPatientId })

export const UPDATE_CART_V2 = () => UrlParamsReplace("/v2/cart");
export const GET_REQUEST_CANCEL_ORDER = (orderId) => UrlParamsReplace(`/v3/related-order/${orderId}`);
export const GET_USER_TYPES_DATA = () => UrlParamsReplace("/v2/userTypes");
export const GET_DOCTOR_TIMINGS = ({ doctorId, clinicId = "", type = "" }) => UrlParamsReplace('/v2/doctor/:doctorId/timings?type=:type&clinicId=:clinicId', { doctorId, clinicId, type });
export const GET_DOCTOR_APPOINTMENT_SLOTS = ({ doctorId, clinicId = "", type = "" }) => UrlParamsReplace('/v1/doctor-slots/:doctorId?type=:type&clinicId=:clinicId', { doctorId, clinicId, type });
export const APPOINTMENT_REQUEST_AUTH = () => UrlParamsReplace('/v2/appointment/request');
export const GET_SYSTEM_PARAMETER = (paramName, type ) => UrlParamsReplace("/v0/system-parameter?paramName=:paramName&type=:type", { paramName, type })
export const USER_INACTIVE = (userId) => UrlParamsReplace("/users/deactivate/:userId", { userId });
export const CREATE_INACTIVE_USER = (user_id) => UrlParamsReplace('/createInvalidatedUser/:user_id', { user_id });
export const V2_GET_LEDGER = (userId,type,from,to) => UrlParamsReplace(`/v2/users/${userId}/ledger?type=:type&from=:from&to=:to`,{userId,type,from,to});
export const SEND_VERIFICATION_EMAIL = () => UrlParamsReplace('/v1/send-verification-email');
export const VERIFY_EMAIL = (token) => UrlParamsReplace(`/v1/verify-email?token=${token}`);
export const GET_DOCTOR_RATINGS = ({ doctorId, limit, page }) => UrlParamsReplace('/v1/doctors/:doctorId/ratings?limit=:limit&page=:page', { doctorId, limit, page });
export const CHANGE_CURATED_STATUS_FOR_DOCTOR = () => UrlParamsReplace("/v1/doctor/mark-curated");
export const VALIDATE_AND_BEFORE_UPLOAD_FILE = () => UrlParamsReplace('/v1/s3/validate-and-get-file-key');
export const PRINTABLE_SUMMARY = () => UrlParamsReplace("/v2/orders/summary-pdf");
export const REMOVE_USER_DEFAULT_ADDRESS = (userId) => UrlParamsReplace(`/v0/address/${userId}/removeDefault`);

export const RESELLER_DASHBOARD_DATA = ({ doctorId, offset, limit, filterDateValue='', search='', patientId='', appointmentId='', sortBy='', parentUserId=''}) => UrlParamsReplace('/v3/appointments?doctorId=:doctorId&offset=:offset&limit=:limit&filterDateValue=:filterDateValue&search=:search&patientId=:patientId&appointmentId=:appointmentId&sortBy=:sortBy&parentUserId=:parentUserId', { doctorId, offset, limit, filterDateValue, search, patientId, appointmentId, sortBy, parentUserId });
export const RESELLER_DASHBOARD_ACTIVITY_DATA = ({ doctorId, searchType }) => UrlParamsReplace('/v1/activity/summary?doctorId=:doctorId&searchType=:searchType', { doctorId, searchType });
export const APPOINTMENT_COUNTS = ({ doctorId, monthYear, isApptRequest }) => UrlParamsReplace('/v0/appointment/counts?doctorId=:doctorId&monthYear=:monthYear&isApptRequest=:isApptRequest', { doctorId, monthYear, isApptRequest });
export const GET_PATIENT_NOTES = (patientAssociationId) => UrlParamsReplace(`/v0/patient/${patientAssociationId}/note`);
export const ADD_PATIENT_NOTE = (patientAssociationId) => UrlParamsReplace("/v0/patient/:patientAssociationId/note", {patientAssociationId});
export const DELETE_PATIENT_NOTE = (patientAssociationId, noteId) => UrlParamsReplace("/v0/patient/:patientAssociationId/note/:noteId", {patientAssociationId, noteId});
export const GET_RESELLER_DASHBOARD_SLIDER_INFO = ({ doctorId }) => UrlParamsReplace("/v1/reseller/:doctorId/slider", { doctorId });
export const GET_EXISTING_APPOINTMENT = ({patientAssociationId, doctorId}) => UrlParamsReplace(`/v1/patient/${patientAssociationId}/appointment/${doctorId}`);
export const UPDATE_DEFAULT_BELLING_ADDRESS_URL = (address_id) => UrlParamsReplace("/v1/address/default/:address_id", { address_id });
export const GET_ENCRYPTED_TEXT = (text) => UrlParamsReplace('/v1/encrypt/:text', {text});
export const GET_ORDER_DETAILS_FOR_PREPAID_PAYMENT = (hashedId) => UrlParamsReplace('/v3/order/:hashedId', {hashedId});
export const GET_USER_PAYOUT_LIST = (params = {}) => {
  const safeParams = params || {};

  const filteredParams = Object.fromEntries(
    Object.entries(safeParams).filter(([key, value]) => value !== undefined && value !== null)
  );

  const queryString = new URLSearchParams(filteredParams).toString();

  return UrlParamsReplace(`/v0/payout?${queryString}`);
};
export const GET_MEDICAL_RECORDS = ({patientId, doctorId, appointmentId}) => UrlParamsReplace('/medical-records?patientId=:patientId&doctorId=:doctorId&appointmentId=:appointmentId', {patientId, doctorId, appointmentId});
export const UPLOAD_MEDICAL_RECORDS = () => UrlParamsReplace('/medical-records');
export const REMOVE_MEDICAL_RECORDS = () => UrlParamsReplace('/medical-records');
export const GET_CLINICS_BY_DOCTOR_ID = ({ doctorId }) => UrlParamsReplace("/v1/doctors/:doctorId/clinics", { doctorId });
export const GET_OUTSTANDING_PAYOUT = (channel) => UrlParamsReplace('/v0/payout/outstanding?channel=:channel', { channel });
export const TRANSFER_OUTSTANDING_PAYOUT = () => UrlParamsReplace('/v0/payout/outstanding');
export const GET_LANGUAGES = (searchKey) => UrlParamsReplace("/user/languages?searchKey=:searchKey",{searchKey});
export const GET_LANGUAGE_LIST_WITH_PUB = (searchKey) => UrlParamsReplace("/pub/user/languages?searchKey=:searchKey",{searchKey});
export const SAVE_PREFERRED_LANGUAGES = (userId) => UrlParamsReplace("/v1/user/:userId/language", {userId});
export const REMOVE_LANGUAGE = (userId,languageId) => UrlParamsReplace("/v1/user/:userId/language/:languageId", {userId,languageId});
export const UPDATE_CLINIC_FEE = (doctorId) => UrlParamsReplace('/v1/updateClinicDoctorFee/:doctorId', {doctorId});
export const GET_SEO_DATA = (pageSlug, city = "") => UrlParamsReplace('/get-seo-data?slug=:pageSlug&city=:city', { pageSlug, city });
export const UPDATE_USER_PROFESSION = (userId) => UrlParamsReplace("/users/:userId/profession", {userId});
export const GET_APPOINTMENTS_COUNT = (doctorId) => UrlParamsReplace("/v1/doctor/:doctorId/appointments/count", { doctorId });
export const GET_REWARDS_EARNED = (doctorId) => UrlParamsReplace("/v1/doctors/:doctorId/rewardsEarned", { doctorId });
export const CHANGE_CLINIC_CONSULTATION = () => UrlParamsReplace("/v1/updateClinicDoctorData");
export const GET_CONSULTATION_FEES_LIST = ({ type = "0" }) => UrlParamsReplace("/pub/v0/consultation-fees?type=:type", { type });
export const SEMANTIC_SEARCH = ({ page = 0, limit = 10, search = "", city = "" }) => UrlParamsReplace("/pub/v0/search?page=:page&limit=:limit&search=:search&city=:city", { page, limit, search, city });
export const GET_TRANSLITERATED_TEXT = ({ text, promptAction = 1 }) => UrlParamsReplace("/v0/transliterate?text=:text&promptAction=:promptAction", { text, promptAction });