import React, { useEffect, useState } from 'react'
import IconClose from 'atoms/SvgIcons/IconClose.svg';
import Modal from 'atoms/Modal';
import BodyForModal from 'molecules/Modals/BodyForModal';
import { setAuth, getAuth } from "services/identity.service";
import Text from 'atoms/Text';
import Button from 'atoms/Button';
import { useRouter } from 'next/router';
import { useAddressContext } from 'components/context/addressContext';
import ModalForAddAddress from 'molecules/Modals/ModalForAddAddress';
import Alert from 'molecules/Alert';
import Loading from "components/Loader/Loading";
import { APPOINTMENT_FLOW } from 'constant/myAppointment';
import IconCircleWatch from 'atoms/SvgIcons/Icon.watch.svg';
import Avatar from 'atoms/Avatar';
import moment from 'moment';
import IconRefund from 'atoms/SvgIcons/IconRefund.svg';
import Tooltip from 'atoms/Tooltip';
import IconInformation from 'atoms/SvgIcons/IconInformation.svg';
import IconArrowLeft from 'atoms/SvgIcons/IconArrowLeft.svg';
import LinkAnchor from 'atoms/LinkAnchor';
import { APPOINTMENT_TYPE } from 'constant';
const buckerUrl = process.env.NEXT_PUBLIC_BUCKET_URL

const AppointmentPayNowModal = ({ isModalOpen, setIsPayNowModalOpen, fullName = 'doctor', addPatientAddress, setAddPatientAddress, payNowAppointmentButton, rayzorPayPaymentId, iserror, setIsError, isLoading, appointmentDetail }) => {
    const auth = getAuth();
    const router = useRouter();
    const [leadTypeId, setLeadTypeId] = useState(0);
    const [appointmentLeadId, setAppointmentLeadId] = useState(0)
    const appointmentIsInClinic = appointmentDetail?.appointmentType == APPOINTMENT_TYPE?.IN_CLINIC_APPOINTMENT;
    const appointmentIsOnline = appointmentDetail?.appointmentType == APPOINTMENT_TYPE?.ONLINE_APPOINTMENT;

    const { showAddress, setShowAddress, showBillingAddress, setShowBillingAddress, isOpenPincodeModal, isChangeMessage, setIsOpenPincodeModal, addressList, handleModalStateChange, register,
        handleSubmit, modalForServicablePincode, isRemoveDisabled, activeAddress, updateAddressData, setUpdateAddressData, isEditCheckDisabled, setIsEditCheckDisabled, deliveryAddressIndex, billingAddressIndex,
        setValue, setIsModalOpen,
        getValues,
        clearErrors, errors, control, stateList, setAddressList, isDefaultDeliveryAddress, isDefaultBillingAddress,
        setError,
        watch, handleAddressChange, changeAddress, handleDeliveryAddress, onCreateAddress, isShowAddressModal, handleModalForServicablePincode, handleBillingAddress, listOfAddress, setStateCode, tradeName, setTradeName } = useAddressContext()
    const onClose = () => setIsPayNowModalOpen(false);
    useEffect(() => {
        setLeadTypeId(auth?.user?.leadDetails?.leadType)
        setAppointmentLeadId(auth?.user?.leadDetails?.leadId);
    }, [])

    const onClickBack = () => {
        router.push(`/ayurveda/${appointmentDetail?.doctorData?.districtSlug}/doctor/${appointmentDetail?.doctorData?.slug}`);
    }

    return (
        <>
            <Modal
                type="ghost"
                containerClass={`${isModalOpen || 'hidden'}`}
                className={`${leadTypeId == APPOINTMENT_FLOW.PAID ? 'max-w-[690px] min-h-[190px] !my-0 md:my-7.5 rounded-b-none md:rounded-lg' : 'max-w-full md:max-w-[690px] w-full mb-0 md:mb-7.5 customScrollbar '}`}
                handlerModalClose={leadTypeId == APPOINTMENT_FLOW.PAID ? false : onClose}
                isModalOpen={isModalOpen}
                containerPadding='px-0'
                containerAlignment='items-end md:items-center'
                dataAutomation='appointment'
            >
                {iserror && <Alert type="error" className='md:-top-1 z-100' effect="slideDown" resetAlertCallback={() => setIsError(null)} stayDuration={5}>
                    {iserror}
                </Alert>}
                <>
                    {isLoading && <Loading type='layer' />}
                    <button
                        className='absolute top-2 right-2 z-100 p-2'
                        onClick={onClose}
                    >
                        <IconClose className={`w-4 md:w-6 stroke-gray-900 hover:stroke-primary1-500`} data-automation='appointment-modal-close' />
                    </button>

                    <div className='flex flex-col relative customScrollbar'>

                        <BodyForModal type="bodyType1" className="p-0 relative overflow-y-auto flex-grow object-contain items-center justify-center">
                            {
                               appointmentLeadId == 0 && !appointmentDetail ? <>
                                    <div className='w-full md:max-w-[438px] py-12.5 px-3 text-center'>
                                        <div className='mx-6'>
                                            <div className='text-base text-gray-900 pb-5'>{auth?.user?.leadDetails?.alertMessage}</div>
                                        </div>
                                        <LinkAnchor className='flex justify-center items-center' href={"/ayurveda/doctors?topDoctors=1&paidDoctors=1"}>
                                            <Button className='w-62.5 items-center h-12 shrink-0 text-lg rounded-lg p-0'>Consult a Paid Doctor</Button>
                                        </LinkAnchor>
                                    </div>
                                </> : appointmentLeadId && !appointmentDetail ? <Loading type='layer' /> : <>
                                <div className='flex text-gray-900 items-center font-semibold text-2xl p-5 border-b border-b-gray-200'>
                                        {appointmentDetail?.doctorData?.slug && <div className="mr-5">
                                            <IconArrowLeft onClick={() => onClickBack()} className={`w-8 fill-none stroke-gray-700 hover:stroke-primary1-500 cursor-pointer`} dataAutomation='back-button' />
                                        </div>}
                                        <div className='text-lg.5 font-semibold'>
                                            Consultation Details
                                        </div>
                                    </div>
                                    <div className='w-full py-5 px-5 md:px-7'>

                                        <div className='flex justify-between'>
                                            <div className=''>
                                                <Text type='body' className="text-gray-500 font-bold font-16-18 mt-1 flex">
                                                    {moment(new Date(appointmentDetail?.appointmentTime)).format("hh:mm A, DD MMM, YYYY")} <LinkAnchor href={`/ayurveda/${appointmentDetail?.doctorData?.districtSlug}/doctor/${appointmentDetail?.doctorData?.slug}`} className='text-primary1-500 font-normal text-xs ml-4 mt-0.5'> Change Date & Time</LinkAnchor>  </Text>
                                                <Text className="text-sm text-gray-600">{appointmentDetail?.doctor?.fullName}</Text>
                                                <Text className="text-xs text-gray-600 line-clamp-1">{appointmentDetail?.doctorData?.specializations.map(item => item.name).join(', ')}</Text>
                                            </div>
                                            <div>
                                                <Avatar className='w-15 h-15 mt-5' src={appointmentDetail?.doctorData?.profilePic ? buckerUrl + appointmentDetail?.doctorData?.profilePic : '/images/icon_doctor.svg'} alt={auth?.user?.fullName} />

                                            </div>
                                        </div>
                                        <Text className="mt-5 mb-2 text-16-18">Your Details</Text>
                                        <div className='flex max-w-[300px] justify-between'>
                                            <div className='text-sm text-gray-500'>
                                                <Text className="text-xs text-gray-500">Full Name</Text>
                                                <Text className="text-sm text-gray-900">{appointmentDetail?.patientInfo?.name}</Text>
                                            </div>
                                            <div className='text-sm  text-gray-900'>
                                                <Text className=" text-xs text-gray-500">Mobile Number</Text>
                                                <Text className="text-sm text-gray-900">{appointmentDetail?.patientInfo?.User?.mobile}</Text>
                                            </div>
                                        </div>
                                        <Text className="mt-3 text-xs  text-gray-500"> Query</Text>
                                        <Text className="text-sm text-gray-900">{appointmentDetail?.queryPrompt}</Text>
                                        <div className='h-[8px] bg-gray-200 my-3'></div>
                                        <Text className="font-bold text-16-18">Cancellation Policy</Text>
                                        <div className='flex gap-2 my-2'>
                                            <IconCircleWatch />
                                            <Text className='text-sm text-gray-600'> If you wish to cancel or reschedule, you can do it upto 2 hours before the appointment time</Text>
                                        </div>
                                        {appointmentIsOnline ?
                                            <div className='flex gap-2 my-2'>
                                                <IconRefund className='mt-1 w-4 h-4' />
                                                <Text className="text-sm text-gray-600"> If a doctor fails to appear for an appointment and the rescheduling is not confirmed, the amount will be reimbursed to the original source account</Text>
                                            </div>
                                        : null}
                                        <div className='h-1 bg-gray-200 my-3'></div>
                                        <Text className="font-bold text-16-18">Bill Details</Text>
                                        <div className='flex justify-between'>
                                            <div className='flex justify-between'>
                                                <Text className="text-sm text-gray-900">{`Consultation Fee ${appointmentIsInClinic ? "(Payable at clinic)" : ""}`}</Text> 
                                                {appointmentIsOnline ?
                                                    <Tooltip tooltipContent='Inclusive of all taxes'>
                                                        <IconInformation className="w-4.5 ml-2 text-gray-500 hover:text-primary1-500" />
                                                    </Tooltip>
                                                : null}
                                            </div>
                                            <Text className="text-sm text-gray-900">₹{appointmentIsInClinic ? appointmentDetail?.doctorData?.clinics?.[0]?.consultationFee : appointmentDetail?.doctorData?.consultationFee}</Text>
                                        </div>
                                    </div>
                                    <div className='w-full shadow-md py-3'>
                                        <Button className='px-8 max-w-[350px] text-16-24 rounded-lg py-3 mx-auto' onClick={payNowAppointmentButton}>{(appointmentDetail?.isPaid && appointmentDetail?.doctorData?.consultationFee) ? `Pay & Confirm Appointment` : `Confirm Appointment`}</Button>
                                    </div>
                                </>

                            }
                        </BodyForModal>
                    </div>
                </>
            </Modal>

            {isShowAddressModal &&
                <ModalForAddAddress
                    label={"Add address"}
                    isModalOpen={isShowAddressModal}
                    handleModalStateChange={() => setIsModalOpen(false)}
                    onSubmit={onCreateAddress}
                    handleSubmit={(props) => { setAddPatientAddress(false); return handleSubmit(props); }}
                    setIsModalOpen={setIsModalOpen}
                    register={register}
                    errors={errors}
                    stateList={stateList}
                    setValue={setValue}
                    watch={watch}
                    setError={setError}
                    clearErrors={clearErrors}
                    setAddressList={setAddressList}
                    billingAddress={addressList}
                    deliveryAddress={addressList}
                    handleBillingAddress={handleBillingAddress}
                    control={control}
                    isDefaultBillingAddress={isDefaultBillingAddress}
                    isDefaultDeliveryAddress={isDefaultDeliveryAddress}
                    handleDeliveryAddress={handleDeliveryAddress}
                    isEditCheckDisabled={isEditCheckDisabled}
                    setStateCode={setStateCode}
                    setTradeName={setTradeName}
                    tradeName={tradeName}
                />
            }
        </>
    )
}

export default AppointmentPayNowModal