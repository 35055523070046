import React from 'react'
import moment from 'moment'
import Text from 'atoms/Text'
import Carousel from 'atoms/Carousel'
import Container from 'atoms/Container'
import LinkAnchor from 'atoms/LinkAnchor'
import DynamicCard from 'molecules/card/DynamicCard'
import LoadingStatic from 'components/Loader/LoadingStatic'
import IconTime from 'atoms/SvgIcons/IconTime.svg'
import Heading from 'atoms/Heading'
import IconArrowPrevious from 'atoms/SvgIcons/IconArrowPrevious.svg'
import IconArrowNext from 'atoms/SvgIcons/IconArrowNext.svg'
import { getAuth } from "./../services/identity.service";
import {moEngageFeaturedClick, moEngageFeaturedCarouselClick, moEngageBlogCardAuthorClick, moEngageBlogRelatedCarouselClick, moEngageBlogRelatedCardAuthorClick, moEngageBlogRelatedCardViewClick, moEngageBlogRelatedCardTitleClick } from "./../components/blog/BlogMonengage.js"
import IconRightArrow from 'atoms/SvgIcons/IconRightArrow.svg'

const RelatedBlog = ({ className = "", headingClass = '', descriptionTitle, blogs = [], isRelatedBlogs = false, isHomeBlogs = false, isLoading=false }) => {
  const auth = getAuth();
  const crousalHandler = (position) =>{
    if(isRelatedBlogs){
      moEngageBlogRelatedCarouselClick({POSITION:position});
    }else{
      moEngageFeaturedCarouselClick({POSITION:position})
    }
  }

  const cardHandler = (item, index) =>{
    if(isRelatedBlogs){
      moEngageBlogRelatedCardViewClick({BLOG_NAME: item?.heading});
      moEngageBlogRelatedCardTitleClick({BLOG_NAME: item?.heading});
    }else{
      moEngageFeaturedClick({POSITION: index, BLOG_NAME: item?.heading})
    }
  }

  const authorHandler = (item) =>{
    if(isRelatedBlogs){
      moEngageBlogRelatedCardAuthorClick({ AUTHOR_NAME: item?.writtenBy?.name});
    }else{
      moEngageBlogCardAuthorClick({ AUTHOR_USER_ID: item?.writtenBy?.id, USER_ID:auth?.user?.id});
    }
  }

  return (isLoading
    ? <LoadingStatic className=' h-40' />
    : blogs.length ? <div className={`${className}`}>
        <Container type='static' className='pb-7 px-2 '>
            <Heading className="font-Montserrat text-18-28  text-gray-900 mb-0 pt-5 pb-0 text-center" type='H2' weight={600}>{descriptionTitle}</Heading>
            <div className="w-full relative">
                <div className='w-full hidden md:block'>
                    <button onClick={()=> crousalHandler('Left')} className={`blogArrow--prev absolute -left-4 2xl:-left-8 top-1/2 -translate-y-1/2 rounded-full z-10 group`} data-automation='blog-left-arrow'>
                        <IconArrowPrevious className='text-gray-900 w-10' />
                    </button>
                    <button onClick={()=> crousalHandler('Right')} className={`blogArrow--next absolute -right-4 2xl:-right-8 top-1/2 -translate-y-1/2 rounded-full z-10 group`} data-automation='blog-right-arrow'>
                        <IconArrowNext className='!text-gray-900 w-10' />
                    </button>
                </div>
                <div type='static' className=' xl:px-4 2xl:px-0 '>
                    <Carousel
                        breakpoints={{
                            '0': {
                                slidesPerView: 1.1
                            },
                            '620': {
                                slidesPerView: 2.5
                            },
                            '1024': {
                                slidesPerView: 5.08
                            }
                        }}
                        isShowArrow
                        autoplayDelay={6000}
                        spaceBetween={15}
                        navigationElement='blog'
                        sliderList={blogs.map((item, index) =>
                            <DynamicCard onClick={()=> cardHandler(item, index)} key={item?.id} href={item?.href} image={item?.blogImage} alt="alter text" className="p-3 flex flex-col gap-2 w-full h-full md:h-64] hover:shadow-extraLight m-3" imageHeight={210} imageWidth={366} rounded="rounded-2xl" roundedImage="rounded-lg" >
                                <LinkAnchor onClick={() => authorHandler(item)} className="text-gray-600 text-[12px] md:text-[10px] inline-block line-clamp-1 mb-1.5" href={item?.writtenBy?.href}>
                                    {item?.writtenBy?.name}
                                </LinkAnchor>
                                <Text onClick={()=> cardHandler(item, index)} className="font-Open-Sans md:text-12-16 text-14-16 md mb-2 line-clamp-1" color="text-gray-600" fontWeight={700} tag="H4" type="body" >
                                    {item?.heading}
                                </Text>
                                <Text onClick={()=> cardHandler(item, index)} className="text-14-16  md:text-12-16 font-Open-Sans mb-2 line-clamp-2" color="text-gray-600" fontWeight={400} tag="div" type="bodySmall" >
                                    <div className='beautifyRegular text-12-14 text-gray-600 max-h-15' dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                                </Text>
                                <div className='mt-2 flex justify-between items-center'>
                                    <Text className='text-[10px] flex items-center' fontWeight={400} >
                                        <IconTime className='text-gray-400 mr-1' size={10} />
                                        <span>{item?.readTime} mins read</span>
                                    </Text>
                                    <Text className='text-[10px]' fontWeight={400} >
                                        {moment(item?.createdAt).format('DD MMM, YYYY')}
                                    </Text>
                                </div>
                            </DynamicCard>
                        )}
                    />
                </div>
            </div>
            {isHomeBlogs && <div className='flex justify-center mt-1'>
                <LinkAnchor href={'/blogs'} dataAutomation='blog-section-homepage' className='text-primary1-500 hover:underline font-semibold text-12-16 mx-auto inline-block'>
                    <span className='whitespace-nowrap'>View All</span> <IconRightArrow className='inline-block fill-none ml-1' size={12} />
                </LinkAnchor>
            </div>}
        </Container>
    </div> : null
  )
}

export default RelatedBlog