import React from 'react'

import Button from '../../atoms/Button'
import Heading from '../../atoms/Heading'
import Text from '../../atoms/Text'
import Modal from '../../atoms/Modal'
import { useRouter } from 'next/router'

const ModalForPatientConsultation = ({ isModalOpen, handleModalStateChange }) => {
    const router = useRouter();

    return (
        isModalOpen ? <Modal className='px-5 pt-10 pb-[45px] md:max-w-[438px] w-full fixed'
            isModalOpen={isModalOpen}
            handlerModalClose={(event) => handleModalStateChange({ event })}
            dataAutomation='modal-for-patient-consultation-info'
            isShowCloseBtn={true}
        >
            <Heading className='text-lg font-Montserrat text-center text-gray-900 font-semibold'>{'Did you know that you can get Patient consultations from Nirogstreet?'}</Heading>
            <Text type='body' className="text-center text-gray-900 text-sm my-2">Complete these simple settings on your profile:</Text>

            <ul className="grid grid-cols-2 list-disc list-inside gap-y-2 pl-2 md:pl-12 text-sm">
                <li>Verification</li>
                <li>Profile Details</li>
                <li>Profile Picture</li>
                <li>Consultation Settings</li>
            </ul>


            <div className='flex justify-between gap-3.5 mt-5'>
                <Button size='extraSmall_v3' className='w-62.5 px-3.5 mx-auto h-10 rounded-lg' onClick={() => router.push('/profile')} dataAutomation='update-details-for-patient-consultation'>Click here to update now</Button>
            </div>
        </Modal> : null
    )
}

export default ModalForPatientConsultation
