import { Svg } from "atoms/SvgIcon";

const IconCopyThick = ({ ...rest }) => {
	return <Svg viewBox='0 0 18 18' type='IconCopyThick' {...rest} >
		<g clip-path="url(#clip0_88810_27232)">
			<path d="M3.75 11.25H3C2.60218 11.25 2.22064 11.092 1.93934 10.8107C1.65804 10.5294 1.5 10.1478 1.5 9.75V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H9.75C10.1478 1.5 10.5294 1.65804 10.8107 1.93934C11.092 2.22064 11.25 2.60218 11.25 3V3.75M8.25 6.75H15C15.8284 6.75 16.5 7.42157 16.5 8.25V15C16.5 15.8284 15.8284 16.5 15 16.5H8.25C7.42157 16.5 6.75 15.8284 6.75 15V8.25C6.75 7.42157 7.42157 6.75 8.25 6.75Z" stroke="currentColor" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
		</g>
		<defs>
			<clipPath id="clip0_88810_27232">
				<rect width="18" height="18" fill="white" />
			</clipPath>
		</defs>
	</Svg>
};

export default IconCopyThick;