import React from 'react'

import Text from 'atoms/Text'
import { CLR } from 'styles/theme'
import Container from 'atoms/Container'
import AccordionItem from 'atoms/Accordion'
import ImageNext from 'atoms/ImageNext'

const THEME = {
    v1: {
        headingFontWeight: 600,
            headingClass: 'mb-5',
    },
    v2: {
        headingFontWeight: 600,
        headingClass: 'text-left mb-3 md:mb-4',
    }
}

const Faq = ({ className = '', heading, list, isShowShape, titleClass, theme = 'v1' }) => {
    return (
        <div className={`${className} relative`}>
            <div className='absolute -top-34' id='faq'></div>
            <Container type='static' className='md:px-5'>
                <Text type='h2' className={`${THEME[theme]?.headingClass} font-Montserrat text-lg md:text-lg.5 md:text-24 text-gray-900 font-semibold`}>{heading}</Text>

                {list?.map((item) => (
                    <AccordionItem className='relative z-1' key={item?.id} heading={item?.title} type='BORER_CHEVRON' titleClass={titleClass} dataAutomation='logistics-details' color={CLR.gray900.text} contentClass='text-14-20 text-gray-600'>
                        <div className="mt-1.5 textDefault beautifyRegular beautifyEnhance font-Poppins"
                            dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                    </AccordionItem>
                ))}
            </Container>

            {isShowShape && <ImageNext className='block' containerClass='absolute right-0 bottom-0 flex z-0' src='/images/shape-3.png' width={208} height={127} alt='shape' />}
        </div>
    )
}

export default Faq