import React from 'react'
import PropTypes from 'prop-types';
import IconCheckThick from './SvgIcons/IconCheckThick.svg';
import useWindowSize from 'customHook/useWindowSize';

const TabStepTitleV3 = ({ className, isActive, children, onClick, divider, isDisable }) => {
    const [width] = useWindowSize();

    const containerClasses = `${className} ${divider ? 'after:border' : ''} ${onClick ? 'after:border-primary2-500' : 'after:border-gray-300'} ${isDisable ? 'pointer-events-none opacity-70' : ''} relative flex flex-col items-start flex-1 after:absolute after:top-0 sm:after:top-2.5 ${width < 640 ? 'after:h-full after:left-2.5' : 'after:w-full'}`;

    const circleClasses = `${isActive ? 'border-primary1-500' : 'border-gray-300'} ${onClick ? 'bg-primary2-500 border-primary2-500' : ''} border bg-basic-white relative w-5 h-5 flex justify-center items-center rounded-full z-2`;

    const textClasses = `${(isActive || onClick) ? 'text-gray-900' : 'text-gray-600'} ${onClick ? '' : ''} pl-1 md:pl-0 md:pr-4 md:pt-1.25 text-xs md:text-sm`;

    return (
        <div onClick={() => {}} className={containerClasses}>
            <div className={`${width < 640 && 'flex flex-row pb-2'}`}>
                <div className={circleClasses}>
                    {onClick && <IconCheckThick className="fill-basic-white" />}
                    {!onClick && <span className={`${isActive ? 'bg-primary1-500' : ''} ${onClick ? 'bg-primary2-500' : ''} rounded-full bg-basic-white absolute z-9`} />}
                </div>
                <div className={textClasses}>
                    {children}
                </div>
            </div>
        </div>
    );
}

TabStepTitleV3.defaultProps = {
    className: '',
    isActive: false,
    divider: true,
    isDisable: false,
}

TabStepTitleV3.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    isActive: PropTypes.bool,
    divider: PropTypes.bool,
    isDisable: PropTypes.bool,
}

export default TabStepTitleV3;
