import { useState, useEffect } from "react";
import { getSystemParamData } from "services/user.service";

export default function HeaderRibbon() {
    const [isAnimating, setIsAnimating] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [messages, setMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState({ title: "", redirectToWeb: "#" });

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const res = await getSystemParamData('ribbon_alert_text', 'FEATURE_CONSTANTS');
                const fetchedMessages = res?.entity[0]?.paramStorageValue || [];
                setMessages(fetchedMessages);

                if (fetchedMessages.length > 0) {
                    setCurrentMessage(fetchedMessages[0]);
                }
            } catch (error) {
                console.error("Error fetching messages:", error);
            }
        };

        fetchMessages();
    }, []);

    useEffect(() => {
        if (messages.length === 0) return;

        let messageIndex = 0;

        const interval = setInterval(() => {
            setIsAnimating(true);

            setTimeout(() => {
                messageIndex = (messageIndex + 1) % messages.length;
                setCurrentMessage(messages[messageIndex]);
            }, 250);

            setTimeout(() => {
                setIsAnimating(false);
            }, 500);
        }, 3000);

        return () => clearInterval(interval);
    }, [messages]);

    return (
        isVisible && (
            <div className="bg-other-yellow-800 w-full py-2 px-4 flex items-center justify-between text-gray-900 md:text-lg text-sm overflow-hidden">
                <a
                    href={currentMessage?.redirectToWeb || ""}
                    className={`flex-grow text-center transform transition-transform duration-500 ${isAnimating ? "-translate-y-full opacity-0" : "translate-y-0 opacity-100"}`}
                >
                    {currentMessage?.title || "No message available"}
                </a>
                <button onClick={() => setIsVisible(false)} className="text-gray-900 hover:text-gray-600">
                    ✕
                </button>
            </div>
        )
    );
}
