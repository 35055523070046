import Button from 'atoms/Button';
import Textarea from 'atoms/forms/Textarea';
import Input from 'atoms/Input';
import LinkAnchor from 'atoms/LinkAnchor';
import Text from 'atoms/Text';
import { USER_INPUT_DATA_TYPES } from 'constant';
import useWindowSize from 'customHook/useWindowSize';
import Checkbox from 'molecules/Forms/Checkbox';
import React, { useState, useEffect } from 'react';
import Form from '../common/Form';
import Modal from 'atoms/Modal';

const AppointmentForm = ({ formData, errors, handleSubmit = () => { }, onSubmit = () => { }, register = () => { }, setEnableWhatsapp, isEnableWhatsapp, isHomePage, showModal, setIsShowBookApptModal, blogDetail, heading="Need Assistance!" }) => {
    const [isSticky, setIsSticky] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [width, height] = useWindowSize();

    const handlerCheckboxChange = (e) => {
        const { id, checked } = e;
        setEnableWhatsapp(checked)

        if (!checked) {
            setEnableWhatsapp(checked)
        };
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);




    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;

            if (scrollTop > 200) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        showModal ?
            <Modal type="modalTypeZero" isModalOpen={showModal} isShowCloseBtn={true} handlerModalClose={() => setIsShowBookApptModal(false)} className='overflow-y-scroll py-5 px-9'>
                <div className={`h-fit md:p-5 md:rounded-lg bg-basic-white transition-all duration-500 ease-in-out`}>
                    <Text type="h2" className={`${showModal ? 'text-lg.5' : 'text-lg'} text-gray-900 px-8 font-semibold text-center mb-1`}>
                        Book Appointment
                    </Text>
                    <Text type="p" className={` ${showModal ? 'md:text-lg text-sm' : 'text-sm'} text-gray-600 text-center`}>
                        Share your details & we will find the
                    </Text>
                    <Text type="p" className={`${showModal ? 'md:text-lg text-sm' : 'text-sm'} text-gray-600 px-4 text-center mb-4`}>
                        best doctor for you
                    </Text>

                    <Form onSubmit={handleSubmit(onSubmit)} className="">
                        <Input
                            type="text"
                            id="fullName"
                            register={register}
                            placeholder="Full Name*"
                            inputDataType={USER_INPUT_DATA_TYPES?.alphabets}
                            mandatory={true}
                            error={errors.fullName}
                            minimumLength={2}
                            maximumLength={256}
                            defaultValue={formData?.name}
                        />

                        <Input
                            id="mobile"
                            type="number"
                            register={register}
                            placeholder="Mobile Number*"
                            error={errors.mobile}
                            inputDataType={USER_INPUT_DATA_TYPES?.mobile}
                            maximumLength={10}
                            defaultValue={formData?.mobile}
                            disabled={!!formData?.mobile}
                        />

                        <Input
                            placeholder="Pincode*"
                            id="pincode"
                            register={register}
                            error={errors.pincode}
                            minimumLength={6}
                            maximumLength={6}
                            inputDataType={USER_INPUT_DATA_TYPES?.pincode}
                            defaultValue={formData?.pincode}
                        />

                        <Textarea
                            placeholder="Health concerns/symptoms"
                            mandatory={true}
                            id="queryPrompt"
                            inputDataType={USER_INPUT_DATA_TYPES?.alphanumeric}
                            minimumLength={5}
                            error={errors.queryPrompt}
                            register={register}
                            maximumLength={500}
                            textareaRows={showModal ? 1 : 2}
                        />

                        <Checkbox
                            className="mb-2 mt-2"
                            label="Get updates on your WhatsApp number"
                            handlerCheckboxChange={handlerCheckboxChange}
                            checked={isEnableWhatsapp}
                        />


                        <div className="text-gray-500 text-center text-xs">
                            By continuing, I agree to the <br />
                            <LinkAnchor className="inline-block text-primary1-500" href="/terms-of-service">
                                Terms & Conditions
                            </LinkAnchor>{' '}
                            of Requesting Appointment
                        </div>

                        <Button
                            type="submit"
                            disabled={!isEnableWhatsapp}
                            className="w-full bg-primary1-500 text-white p-2 mt-2 rounded-md text-center font-medium hover:bg-primary1-600 transition duration-200"
                        >
                            Book Now
                        </Button>
                    </Form>
                </div>
            </Modal>
            :
            <div className={`${isHomePage ? 'md:h-[410px] w-full md:w-[414px]' : `h-fit ${blogDetail ? 'md:w-[410px]' : 'md:w-[380px]'}`} p-5 border-4 border-primary1-200 md:rounded-lg bg-basic-white shadow-sm transition-all duration-500 ease-in-out md:overflow-y-scroll ${width > 768 && !isHomePage && 'sticky top-40'}`}>

                <Text type="h2" className="text-lg text-gray-900 px-8 font-semibold text-center mb-1">
                    {heading}
                </Text>
                <Text type="p" className="text-sm text-gray-600 px-4 text-center">
                    Share your details & we will find the
                </Text>
                <Text type="p" className={`text-sm text-gray-600 px-4 text-center ${isHomePage ? 'mb-3' : 'mb-4'} `}>
                    best doctor for you
                </Text>

                <Form onSubmit={handleSubmit(onSubmit)} className={`${isHomePage ? 'space-y-0' : 'space-y-3'} ${isHomePage && 'flex flex-col items-center'} `}>
                    <Input
                        type="text"
                        id="fullName"
                        register={register}
                        placeholder="Full Name*"
                        inputDataType={USER_INPUT_DATA_TYPES?.alphabets}
                        mandatory={true}
                        error={errors.fullName}
                        minimumLength={2}
                        maximumLength={256}
                        defaultValue={formData?.name}
                    />

                    <div className={`${isHomePage && 'flex gap-3'}`}>
                        <Input
                            id="mobile"
                            type="number"
                            register={register}
                            placeholder="Mobile Number*"
                            error={errors.mobile}
                            inputDataType={USER_INPUT_DATA_TYPES?.mobile}
                            maximumLength={10}
                            defaultValue={formData?.mobile}
                            disabled={!!formData?.mobile}
                        />
                        
                        <div>
                        <Input
                            placeholder="Pincode*"
                            containerClass="mb-1"
                            id="pincode"
                            register={register}
                            error={errors.pincode}
                            minimumLength={6}
                            maximumLength={6}
                            inputDataType={USER_INPUT_DATA_TYPES?.pincode}
                            defaultValue={formData?.pincode}
                            />
                        {/* {errors?.pincode && <p className="text-xs leading-5 text-semantic-error_base">{errors?.pincode?.message}</p>} */}
                        </div>

                    </div>

                    <Textarea
                        placeholder="Health concerns/symptoms"
                        id="queryPrompt"
                        inputDataType={USER_INPUT_DATA_TYPES?.nonEmojiText}
                        minimumLength={5}
                        error={errors.queryPrompt}
                        register={register}
                        maximumLength={500}
                        textareaRows={1}
                    />
                    <div className='mt-3'>
                        <Checkbox
                            className="mb-2 mt-2"
                            label="Get updates on your WhatsApp number"
                            handlerCheckboxChange={handlerCheckboxChange}
                            checked={isEnableWhatsapp}
                            medCheckbox={true}
                        />
                    </div>


                    <div className="text-gray-500 text-center text-xs">
                        I agree to the &nbsp;
                        <LinkAnchor className="inline-block text-primary1-500" href="/terms-of-service">
                            Terms & Conditions
                        </LinkAnchor>{' '}
                    </div>
                    <Button
                        type="submit"
                        size={`${isHomePage && 'small'}`}
                        disabled={!isEnableWhatsapp}
                        className={`${isHomePage ? 'w-[250px] h-10 !mt-2' : 'w-full p-2'} bg-primary1-500 text-white rounded-md text-center font-medium hover:bg-primary1-600 transition duration-200`}
                    >
                        Book Now
                    </Button>
                </Form>
            </div>
    );
}

export default AppointmentForm;
