import React, { useEffect, useState } from 'react'
import IconClose from 'atoms/SvgIcons/IconClose.svg';
import Modal from 'atoms/Modal';
import BodyForModal from 'molecules/Modals/BodyForModal';
import ThankYouCard from '../ModalConsultDoctor/ThankYouCard';
import { setAuth, getAuth } from "services/identity.service";
import { createOrderLeadAppointment, updateOrderLeadAppointmentStatus } from 'services/my-leads';
import Razorpay from '../PaymentGateways/Rayzorpay';
import { useRouter } from 'next/router';
import AppointmentPayNowModal from './AppointmentPayNowModal';
import { APPOINTMENT_FLOW } from 'constant/myAppointment';
import { getResellerAppointment, updateResellerAppointment } from 'services/reseller.service';
import moment from 'moment';

const AppointmentThankYouModal = ({ isModalOpen, setIsModalOpen, fullName = 'doctor', addPatientAddress, setAddPatientAddress }) => {
    const onClose = () => setIsModalOpen(false);
    const auth = getAuth();
    const router = useRouter();
    const [isLoading, setIsLoading] = useState(false);
    const [openRayzorpay, setOpenRayzorpay] = useState(false);
    const [iserror, setIsError] = useState(null);
    const [rayzorPayOrderIdRef, setRayzorPayOrderIdRef] = useState(null);
    const [leadTypeId, setLeadTypeId] = useState(0);
    const [rayzorPayPaymentId, setRayzorPayPaymentId] = useState(null);
    const [appointmentDetail, setAppointmentDetail] = useState(null)

    const payNowAppointmentButton = async () => {
        setIsLoading(true);
        const apiData = {
            userId: auth?.user?.id,
            orderFrom: 2,
            platform: 2,
            leadId: auth?.user?.leadDetails?.leadId,
            doctorId: auth?.user?.leadDetails?.doctorId
        }
        if((!appointmentDetail?.doctorData?.consultationFee || !appointmentDetail?.isPaid)){
            const response = await updateResellerAppointment(appointmentDetail?.id, {appointmentStatus: 2});
            if (response.status) {
                setIsLoading(false);
                const user = auth;
                user.user['leadDetails'] = { leadId: 0, leadType: 0, doctorId: 0}
                setAuth(user);
                setLeadTypeId(0)
            }else {
                setIsError("Something went wrong")
            }
        }
        else{
            const response = await createOrderLeadAppointment(apiData)
            if (response.status) {
                setIsLoading(false);
                setRayzorPayOrderIdRef(response?.entity?.rayzorpay_order_id)
                setRayzorPayPaymentId(response?.entity?.payment_id)
                setOpenRayzorpay(true);
            } else {
                setIsError("Error while creating payment")
            }
        }
    }
    const afterPayment = async (response) => {
        setIsLoading(true);
        const afterPaymentResponse = await updateOrderLeadAppointmentStatus({ paymentId: rayzorPayPaymentId, razorpayOrderId: response?.razorpay_payment_id, userId: auth?.user?.id, memberPatientId: 0, reseller: false });
        setOpenRayzorpay(false);
        if (afterPaymentResponse?.status) {
            const user = auth;
            user.user['leadDetails'] = { leadId: 0, leadType: 0, doctorId: 0}
            setAuth(user);
            setLeadTypeId(0)
        } else {
            setIsLoading(false);
            setRayzorPayPaymentId(null)
            setIsError("Error while updating payment")
        }
    }

    const closeRazorpayModal = () => {
        setIsLoading(false);
        setRayzorPayOrderIdRef(null)
        setRayzorPayPaymentId(null)
        setOpenRayzorpay(false);
    };
    const getAppointment = async (appointmentId) => {
        if(appointmentId){
            const appointmentResponse = await getResellerAppointment(appointmentId, auth.token);
            if (appointmentResponse.status) {
                setAppointmentDetail(appointmentResponse?.data?.entity)
            }
        }
    }
    
    useEffect(() => {
        getAppointment(auth?.user?.leadDetails?.leadId)
        setLeadTypeId(auth?.user?.leadDetails?.leadType)
    }, [])


    return <Modal
        type="ghost"
        containerClass={`${isModalOpen || 'hidden'}`}
        className={`${leadTypeId == APPOINTMENT_FLOW.PAID ? 'px-5 py-6 xxs:px-8.75 xxs:py-6 max-w-[300px]' : 'max-w-full md:max-w-[690px] w-full mb-0 md:mb-7.5 customScrollbar'}`}
        handlerModalClose={leadTypeId == APPOINTMENT_FLOW.PAID ? false : onClose}
        isModalOpen={isModalOpen}
        containerPadding='px-0'
        containerAlignment='items-end md:items-center'
        dataAutomation='appointment'
    >
        {/* Close button */}
        {

            leadTypeId == APPOINTMENT_FLOW.PAID ?
                <>
                    <AppointmentPayNowModal isModalOpen={isModalOpen} setIsPayNowModalOpen={setIsModalOpen} fullName='doctor' addPatientAddress={addPatientAddress} setAddPatientAddress={setAddPatientAddress} payNowAppointmentButton={payNowAppointmentButton} rayzorPayPaymentId={rayzorPayPaymentId} iserror={iserror} setIsError={setIsError} isLoading={isLoading} appointmentDetail={appointmentDetail} />
                </>
                :
                <>
                    <button
                        className='absolute top-2 right-5 z-100 p-2'
                        onClick={onClose}
                    >
                        <IconClose className={`w-4 md:w-6 stroke-gray-900 hover:stroke-primary1-500`} data-automation='appointment-modal-close' />
                    </button>

                    <div className={`flex flex-col ${leadTypeId == APPOINTMENT_FLOW.PAID ? '' : 'min-h-[400px] h-min max-h-[calc(100vh_-_60px)] md:max-h-[calc(100vh_-_150px)] relative customScrollbar' }`}>

                        <BodyForModal type="bodyType1" className="pt-3 px-7.5 pb-7.5 relative overflow-y-auto flex-grow object-contain items-center justify-center">

                            <ThankYouCard
                                heading={appointmentDetail?.doctorId ?"Confirmed":"Thank You!"}
                                thankYouMsg={appointmentDetail?.doctorId ? `Appointment ID: ${appointmentDetail?.externalId ?? appointmentDetail?.id} for ${moment(new Date(appointmentDetail?.appointmentTime)).format("MMMM DD, YYYY [at] hh:mm A")} with Dr. ${appointmentDetail?.doctor?.fullName}` :'Our team will reach out to you shortly to schedule your appointment with a Verified Doctor.'}
                                onClose={onClose}
                                isCtaRequired={true}
                            />
                        </BodyForModal>
                    </div>
                </>
        }
        {
            <Razorpay openRayzorpay={openRayzorpay} rayzorpayOrderId={rayzorPayOrderIdRef} onPaymentDone={afterPayment} onPaymentCancel={closeRazorpayModal} />

        }
    </Modal>

}

export default AppointmentThankYouModal