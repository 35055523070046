import IconBulkPurchase from "atoms/SvgIcons/IconBuyMedicine.svg";
import IconBuyMedicine from "atoms/SvgIcons/IconBuyMedicine.svg";
import Text from "atoms/Text";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";

export default function BuyMedicineModal({ isOpen, setIsOpen }) {
    const router = useRouter();
    const modalRef = useRef(null);

    const [dragStartY, setDragStartY] = useState(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
            document.addEventListener("touchstart", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        };
    }, [isOpen, setIsOpen]);

    const handleDragStart = (event) => {
        const startY = event.touches ? event.touches[0].clientY : event.clientY;
        setDragStartY(startY);
    };

    const handleDragEnd = (event) => {
        if (dragStartY !== null) {
            const endY = event.changedTouches ? event.changedTouches[0].clientY : event.clientY;
            const distanceDragged = endY - dragStartY;

            if (distanceDragged > 100) {
                setIsOpen(false);
            }
        }
    };

    if (!isOpen) return null;

    return (
        <div
            className="fixed inset-0 bg-gray-300 bg-opacity-60 backdrop-blur-[0.2px] flex justify-center items-end z-50 ]"
            onMouseDown={handleDragStart}
            onMouseUp={handleDragEnd}
            onTouchStart={handleDragStart}
            onTouchEnd={handleDragEnd}
        >
            <div
                ref={modalRef}
                className="rounded-t-3xl bg-basic-white shadow-lg w-full max-w-md pt-3 relative"
            >
                <button
                    className="absolute top-1 right-3 text-gray-900 text-3xl"
                    onClick={() => setIsOpen(false)}
                >
                    &times;
                </button>

                <div className="absolute top-2 left-1/2 transform -translate-x-1/2 w-16 h-1.5 bg-gray-300 rounded-full"></div>

                <Text className="text-center text-lg font-semibold text-gray-900 font-Montserrat mt-5 mb-3">
                    Buy Medicines
                </Text>

                <div className="grid grid-cols-2 gap-7.5 px-6 mb-12">
                    <div onClick={() => router.push("/my-patients?for=orderMedicine")}>
                        <div className="flex flex-col items-center gap-2.5 mb-3 border border-primary1-500 p-4 rounded-lg">
                            <IconBuyMedicine />
                            <Text className="text-primary1-500 text-sm font-semibold font-Open-Sans mb-2">
                                Buy for Patient
                            </Text>
                        </div>
                        <ul className="text-gray-600 text-sm space-y-2.5">
                            <li>
                                ✔ Free shipping to your patients across India, earn Margins when
                                you sell
                            </li>
                            <li>✔ Amazing rewards on order milestones</li>
                        </ul>
                    </div>

                    <div onClick={() => router.push("/medicines")}>
                        <div className="flex flex-col items-center gap-2.5 mb-3 border border-primary1-500 p-4 rounded-lg">
                            <IconBulkPurchase />
                            <Text className="text-primary1-500 text-sm font-semibold font-Open-Sans mb-2">
                                Bulk Purchase
                            </Text>
                        </div>
                        <ul className="text-gray-600 text-sm space-y-2.5">
                            <li>✔ Buy medicines in large quantities at discounted price</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
