import Button from "atoms/Button";
import IconInformation from "atoms/SvgIcons/IconInformation.svg";
import TabStepTitleV3 from "atoms/TabStepTitleV3";
import Text from "atoms/Text";
import ModalRewardsEarned from "molecules/Modals/ModalRewardsEarned";
import ModalVerifyDocumentPopUps from "molecules/Modals/ModalVerifyDocumentPopUps";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { getAuth } from "services/identity.service";
import { getRewardsEarned } from "services/user.service";
import { useUserContext } from "../context/UserContext";
import { USER_TYPES } from "constant";

export default function RewardsEarned() {
    const router = useRouter();
    const auth = getAuth();
    const [rewards, setRewards] = useState({});
    const [usertype, setUsertype] = useState(null);
    const [isVerifiedProfile, setIsVerifiedProfile] = useState();
    const [isShowVerificationModal, setIsShowVerificationModal] = useState(false);
    const { user } = useUserContext();
    const [rewardsData, setRewardsData] = useState([]);
    
    useEffect( async () => {
        const res = await getRewardsEarned(auth?.user?.id);
        if(res?.status){
            setRewards(res?.entity?.infoData);
            setRewardsData(res?.entity?.data);
        } 
            
    },[]);

    useEffect(async () => {
            let user_status = await getAuth();
            setIsVerifiedProfile(user_status?.user?.tbl_doctor_profile?.is_verified);
            setUsertype(user_status?.user?.userType);
        }, [router.pathname]);

    const [showPatientConsultationModal, setShowPatientConsultationModal] = useState(false);

    const handleModalStateChange = () => {
        setShowPatientConsultationModal(false);
    };

    const redirectPopup = () => {
        setIsShowVerificationModal(true)
    }

    const redirectToOrderPage = () => {
        if (isVerifiedProfile === 1 && (usertype === USER_TYPES?.DOCTORS || usertype === USER_TYPES?.DOCTOR_DISTRIBUTOR || usertype === USER_TYPES?.STUDENTS)) {
            router.push('/my-patients?for=orderMedicine');
        } else {
            redirectPopup();
        }
    }

    return (
        <div className="p-3 md:py-4 md:px-5 border border-gray-200 rounded-lg w-[880px] bg-basic-white max-h-min">
            <div className='flex items-start gap-2'>
                <Text className="text-sm md:text-lg text-gray-900 font-semibold mb-2 md:mb-4">Rewards Earned</Text>
                <IconInformation className="w-3.5 md:w-4 mt-1 text-gray-500 hover:text-primary1-500 cursor-pointer" onClick={() => {setShowPatientConsultationModal(true)}}/>
            </div>

            <div className="flex flex-col md:flex-row">

                {rewardsData?.map( (item, index) => (
                    <TabStepTitleV3
                        key={item?.label}
                        onClick={item?.value}
                        isActive={item?.value}
                        divider={index !== rewardsData?.length - 1}
                    >
                        {item?.label}
                    </TabStepTitleV3>
                ))}
                    
            </div>

            <Button
                size='extraSmall'
                className='mx-auto md:mx-0 md:mt-2 mt-1 w-37.5'
                onClick={redirectToOrderPage}
            >
                Place Order
            </Button>
            {showPatientConsultationModal && <ModalRewardsEarned isModalOpen={showPatientConsultationModal} handleModalStateChange={handleModalStateChange} rewards={rewards}/>}

            {isShowVerificationModal && <ModalVerifyDocumentPopUps
                showModalFor={isVerifiedProfile == 4 ? "verifyDocumentsRejected" : isVerifiedProfile == 2 ? "verifyDocumentsUnderReview" : isVerifiedProfile == 0 ? "verifyDocumentsPending" : null}
                isModalOpen={isShowVerificationModal}
                handlerModalClose={() => setIsShowVerificationModal(false)}
                reason={user?.reason}
            />}
        </div>
    );
}
