import { Svg } from "atoms/SvgIcon";

const IconShareProfile = ({ className, hoverColor = "#FF6347", ...rest }) => {
    return (
        <Svg
            width="30"
            height="30"
            className={`text-[#6B6B80] ${className}`}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M15.444 8.30278L16.0718 8.20465V7.56919V3.54036C16.0718 3.54031 16.0718 3.54027 16.0718 3.54022C16.0719 3.47503 16.0887 3.41372 16.1172 3.36332C16.1455 3.31313 16.1825 3.27837 16.2194 3.2575C16.2558 3.23692 16.2929 3.22911 16.3277 3.23108C16.3626 3.23304 16.4004 3.24514 16.4367 3.27127C16.4367 3.27128 16.4367 3.27128 16.4367 3.27129L27.6314 11.3359L27.6317 11.3361C27.6648 11.36 27.6965 11.3955 27.7202 11.4429C27.7439 11.4905 27.7575 11.5462 27.7575 11.6048C27.7575 11.6634 27.7439 11.7192 27.7202 11.7667C27.6965 11.8142 27.6648 11.8497 27.6317 11.8735L27.6314 11.8737L16.4363 19.9387L16.4361 19.9388C16.3998 19.965 16.3621 19.9771 16.3273 19.9791C16.2926 19.981 16.2555 19.9733 16.2192 19.9528C16.1824 19.9319 16.1454 19.8972 16.1171 19.8471C16.0886 19.7967 16.0718 19.7354 16.0718 19.6702V19.6701V16.0767V15.0776L15.1152 15.3657C12.9284 16.0243 10.7413 17.6421 8.86592 19.3243L8.86589 19.3243L8.85997 19.3297C6.60009 21.4013 4.5294 23.6948 2.6752 26.1794L2.67513 26.1794L2.66852 26.1886C2.63125 26.2401 2.58568 26.2696 2.54385 26.2826C2.50278 26.2953 2.45987 26.2941 2.4184 26.2781C2.37608 26.2617 2.33086 26.2279 2.29577 26.1722C2.26033 26.116 2.23988 26.0442 2.24277 25.9677L2.243 25.963L2.24454 25.9328C2.24603 25.9052 2.24851 25.8626 2.25233 25.8061C2.25998 25.693 2.27299 25.5242 2.29422 25.307C2.33671 24.8723 2.412 24.2451 2.54273 23.4843C2.80465 21.9602 3.28678 19.914 4.16532 17.8122C5.92308 13.6069 9.21653 9.27618 15.444 8.30278Z"
                stroke="currentColor"
                strokeWidth="1.485"
            />
        </Svg>
    );
};

export default IconShareProfile;
