import { getDynamicUrl } from "services/dynamic-url.service";

export const generateDeepLink = async ({parameters, title, description, imageUrl, setAlertMsg } = {}) => {
    const meta_title = document.querySelector('title')?.innerText;
    const meta_description = document.querySelector('meta[name="description"]')?.content;
    const meta_image = document.querySelector('meta[property="og:image"]')?.content;

    const pathWithQuery = document.location?.pathname?.substring(1) +  document.location?.search
    const data = {
        parameters: parameters || pathWithQuery,
        title:  title || meta_title || '',
        description: description || meta_description || '',
        imageUrl: imageUrl || meta_image || '' ,
    }

    const dynamicUrlResponse = await getDynamicUrl(data);
    if (dynamicUrlResponse.status && dynamicUrlResponse.data.entity) {
        const dynamicUrl = dynamicUrlResponse.data.entity;
        navigator.clipboard.writeText(dynamicUrl);
    } else {
        const url = `${document.URL}/${parameters}`;
        navigator.clipboard.writeText(url);
    }
    setAlertMsg && setAlertMsg({ message: `Link copied to clipboard`, type: 'success' }) ;

    return dynamicUrlResponse;
};

export const encodeUTF8WithBase64 = (obj) => {
    return (
        Buffer.from(
            Buffer.from(JSON.stringify(obj) , 'utf-8').toString()
        ).toString('base64')
    )
}

export const decodeUTF8WithBase64 = (base64) => {
    try {
        return JSON.parse(Buffer.from(base64, 'base64').toString('utf8'));
        
    } catch (error) {
        console.error(error)
        return { error }
    }
}

export const encodedBufferBase64 = (id) => {
    return Buffer.from(id.toString()).toString("base64");
}

export const decodedBufferBase64 = (base64Id) => {
    return Buffer.from(base64Id, "base64").toString("utf-8");
}

export const isBase64Encoded = (string) => {
    if (!string || typeof string !== 'string') {        
        return false; // Not a string, so it can't be Base64
    }

    // Base64-encoded strings must have a valid length (multiple of 4)
    if (string?.length % 4 !== 0) {
        return false;
    }

    // Base64 can only contain A-Z, a-z, 0-9, +, /, and optional padding with '='
    const base64Regex = /^[A-Za-z0-9+/]+={0,2}$/;

    if (!base64Regex.test(string)) {
        return false;
    }

    try {
        // Attempt to decode the string
        Buffer.from(string, 'base64');

        return true; // If decoding succeeds, it's Base64
    } catch (e) {
        return false; // Decoding failed, so it's not Base64
    }

};
