import React from 'react';
import Container from 'atoms/Container';
import * as moengage from "services/moengage.service";
import { OLYMPIAD_START_DATE, USER_TYPES } from 'constant';
import { PRACTITIONER_COLOR_BOX_SECTION, FAQ_HEADING_HOME_PAGE, TRUSTED_BY_EXPERIENCED_DOCTOR_HEADING_HOME_PAGE } from 'constant/homepage';
import dynamic from 'next/dynamic';
import useWindowSize from 'customHook/useWindowSize'; // Added import for useWindowSize
import ga_homepage_practitioner from "../../assets/mo_engage/homepage_practitioner.json";
import LoadingStatic from 'components/Loader/LoadingStatic';
import PractitionerGetStarted from 'molecules/PractitionerGetStarted';

import { getAuth } from 'services/identity.service';
import { useRouter } from 'next/router';
import { redirectionAfterLogin } from 'core/common/redirectionAfterLogin';
import { captureEvent } from 'services/analytics.service';
import ExploreMore from '@/components/HomePage/ExploreMore';
import AppointmentCount from '@/components/HomePage/AppointmentCount';
import RewardsEarned from '@/components/HomePage/RewardsEarned';
import Text from 'atoms/Text';
import HeaderRibbon from '@/components/HomePage/HeaderRibbon';

const BannerSection = dynamic(() => import("organisms/BannerSection"));
const OlympiadBanner = dynamic(() => import('molecules/OlympiadBanner'));
const mo_engage_olympiad = dynamic(() => import("../../assets/mo_engage/olympiad"));
const DailyDealBanner = dynamic(() => import('organisms/DailyDealBanner'));
const ScaleMetricsListV2 = dynamic(() => import('molecules/ScaleMetricsListV2'));
const IconWithLabelDescriptionUrl = dynamic(() => import('molecules/IconWithLabelDescriptionUrl'));
const ShortStoryCard = dynamic(() => import('molecules/ShortStoryCard'));
const MedicineSectionV2 = dynamic(() => import('organisms/MedicineSectionV2'));
const ShortStoryCore = dynamic(() => import('core/homePage/ShortStoryCore'));
const OnlineCoursesSectionCore = dynamic(() => import('core/homePage/OnlineCoursesSectionCore'));
const FeedSliderCore = dynamic(() => import('@/components/feeds/FeedSliderCore'));
const Faq = dynamic(() => import('organisms/Faq'));
const loading = () => <LoadingStatic className=' h-40' />;
const BrandSectionCore = dynamic(() => import('core/homePage/BrandSectionCore'), { loading });
const PartnerSection = dynamic(() => import('organisms/PartnerSection'), { loading });

const HomePagePractitioner = ({ bannerList, dailyDealBanner, dashboard, userDetails }) => {

    const olympiadEntity = mo_engage_olympiad.entity;
    const [width] = useWindowSize();
    const router = useRouter();
    const isPractitonerHomepage = router?.pathname == '/practitioner';

    const handlerLearnMore = () => {
        moengage.trackEvent(olympiadEntity.Olympiad_banner_homepage.event_name, {})
    }
    const auth = getAuth();
    const { category, entity } = ga_homepage_practitioner
    const events = ga_homepage_practitioner.entity;

  
    return (
        <div className="bg-basic-white">
            {
                (auth?.user?.userType == 2 || auth?.user?.userType == 4) && 
                <>      
                    <HeaderRibbon />
                    {
                        auth?.user?.userType == 2 &&
                        <>
                            <Container type='static' className='px-4 pt-5 md:pt-7'>
                                <Text className='text-base md:text-lg.5 font-Montserrat font-semibold text-gray-900 pb-0.5 md:pb-2'>Your Nirog Partner Progress</Text>
                                <Text className='text-xs md:text-sm text-gray-600 font-Open-Sans leading-[14px]'>Place orders in Nirog Partner Program and earn exciting rewards</Text>
                            </Container>
                            <div className='flex justify-center gap-4.5 md:gap-6 pt-2 px-4'>
                                <RewardsEarned />
                                <AppointmentCount />
                            </div>
                        </>
                    }
                    <ExploreMore />
                </>
            }
            {
                    (isPractitonerHomepage && width < 640) && <ExploreMore />
            }
            <Container type='static' className='px-4'>
                <OlympiadBanner
                    currentDateUpdate="2nd day/30 days"
                    handlerLearnMore={handlerLearnMore}
                    learnMoreRoute="/olympiad"
                    time={OLYMPIAD_START_DATE}
                    className="mb-3 w-full "
                />
            </Container>
            <DailyDealBanner bannerData={dailyDealBanner} />

            <BannerSection sliderList={bannerList}/>
            {
                (auth?.user?.userType == 2 || auth?.user?.userType == 4 || isPractitonerHomepage) && 
                <MedicineSectionV2 heading="1Veda Selection" />
            }

            {![USER_TYPES?.CHEMIST, USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER].includes(auth?.user?.userType) && <Container type='static' className='px-4'>                
                 <ScaleMetricsListV2
                    isVerticalLine={true}
                    heading='Learn and Engage with Ayurveda Experts'
                    className=''
                    list={[
                        { id: 1, count: dashboard?.doctors + '+', title: 'Doctors' },
                        { id: 2, count: dashboard?.practitioner + '+', title: 'Practitioners' },
                        { id: 3, count: dashboard?.medicines + '+', title: 'Medicines' },
                        { id: 4, count: dashboard?.clinics + '+', title: 'Ayurveda Clinics' }]}
                />
            </Container>}

            {(isPractitonerHomepage || [USER_TYPES?.DOCTORS, USER_TYPES?.STUDENTS].includes(auth?.user?.userType)) && dashboard?.engagement_module?.length > 0 && dashboard?.engagement_module.map((item, index) => (
                <ShortStoryCard
                    key={index}
                    containerClass={`${index % 3 === 0 ? 'bg-primary1-100' : index % 3 === 1 ? 'bg-basic-white' : 'bg-gray-100'}`}
                    className='!shadow-none md:px-4'
                    classContent='md:max-w-[480px]'
                    classImage='md:max-w-[550px]'
                    isColReverse={index % 2 != 0}
                    heading={item?.title}
                    description={item?.description}
                    linkName={item?.cta}
                    link={item?.redirectOn}
                    onLinkClick={event => {
                        redirectionAfterLogin({
                            event,
                            auth,
                            router,
                            redirectUrlLoggedIn: '/medicines',
                            redirectUrlLoggedOut: '/login-practitioner'
                        });
                        captureEvent(
                            category,
                            entity.Doctor_Page_Explore_Medicines_Click.event_name,
                            entity.Doctor_Page_Explore_Medicines_Click.event_name,
                            {
                                ...events.attributes,
                                login_state: auth?.user ? true : false
                            }
                        );
                    }}
                    image={`${process.env.NEXT_PUBLIC_PHP_BUCKET_URL}${width > 540 ? item?.webImage : item?.mobileImage}`}
                />
            ))}


            {auth?.user?.userType != 2 && auth?.user?.userType != 4 && !isPractitonerHomepage && <>
                <Container type='static'>
                    <BrandSectionCore />
                </Container>

                <MedicineSectionV2 heading="Top Selling Medicines" link='/medicines/allMedicines' />
            </>}

            {![USER_TYPES?.CHEMIST, USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER].includes(auth?.user?.userType) && <OnlineCoursesSectionCore dashboard={dashboard} heading='Explore our Online Courses & Join Latest Webinars' />}

            <PartnerSection className='pb-6 md:my-7 md:mt-2' heading={TRUSTED_BY_EXPERIENCED_DOCTOR_HEADING_HOME_PAGE} practitionerHomepage={true} list={dashboard?.healing_story?.map(item => ({
                id: item?.author,
                name: item?.author,
                description: item?.details,
                qualification: item?.qualification,
                experience: item?.experience,
                avatar: process.env.NEXT_PUBLIC_PHP_BUCKET_URL + item?.profileImg,
            }))} theme='v2' />

            <Faq className='px-4 py-6 md:py-8 bg-gray-100' heading={FAQ_HEADING_HOME_PAGE} list={dashboard?.faqs?.map(item => ({
                id: item?.question,
                title: item?.question,
                description: item?.answer,
            }))} theme='v2' />

            <div>
                {!auth && <PractitionerGetStarted
                    link='/login-practitioner'
                    heading={dashboard?.join_practitionar?.title}
                    description={dashboard?.join_practitionar?.description}
                />}
            </div>
        </div>
    )
}

export default HomePagePractitioner