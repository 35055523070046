
import Button from '../../atoms/Button'
import Heading from '../../atoms/Heading'
import Modal from '../../atoms/Modal'
import Text from '../../atoms/Text'

const ModalForServicablePincode = ({ isModalOpen, handleModalStateChange, paragraph, changeAddress, isErrorMessage=false }) => {
    return (
        <Modal className='px-5 py-4 xxs:px-8.75 xxs:py-6 max-w-[414px] w-full'
            type="withoutCloseIcon"
            isModalOpen={isModalOpen}
            handlerModalClose={() => handleModalStateChange}
        >
            <Heading type='h3' className='text-center text-gray-900 font-semibold'>Attention</Heading>
            {isErrorMessage && <Text type='body' className="text-center text-gray-500 font-semibold mt-5 mb-8"> {paragraph} </Text>}
            {paragraph && !isErrorMessage && <Text type='body' className="text-center text-gray-500 font-semibold mt-5 mb-8"> {`Hi, we are considering “${paragraph}” as your address. In case you want your order to be delivered to some other address, please choose the right address in the header.`} </Text>}
            <div className='flex justify-between gap-3.5'>
                <Button variant='Ghost' size='medium' className='px-3.5 w-full text-xl max-w-140' onClick={handleModalStateChange} dataAutomation='modal-confirmation-cancel'>Cancel</Button>
                {changeAddress && <Button
                    size='medium'
                    className='px-3.5 w-full text-xl max-w-140'
                    onClick={changeAddress}
                    dataAutomation='modal-confirmation-confirm'
                >Change Address</Button>}
            </div>
        </Modal>
    )
}

export default ModalForServicablePincode
